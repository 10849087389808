/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum TaskJobState {
    Pending = 1,
    Start = 2,
    Started = 3,
    Done = 4,
    Canceled = 5,
    Overdue = 6,
    Escalated = 7,
}
