import {Injectable} from "@angular/core";
import {IssuesBaseService} from "@shared/components/issues/services/issues-base.service";
import {
  CustomPropertyDto,
  CustomSetReferenceDto,
  FilledCustomValueDto, MissionIssueDto,
  MissionIssuePreviewDto, ResourceProfileDto,
  StereotypeUsageDto,
  SubmitMissionIssueDto
} from "@server-models";
import {IssueFormattedDetailData} from "@shared/components/issues/interfaces/issue-formatted-detail-data.interface";
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root',
})
export class IssuesBaseMissionService extends IssuesBaseService {

  prepareFormIssueDto(formGroup: FormGroup, templatePreviewDto: MissionIssuePreviewDto, resourceDto?: ResourceProfileDto): SubmitMissionIssueDto {
    const customPropertyValues = this.prepareCustomValues(formGroup); // to build the customValues
    const issueStereotype: StereotypeUsageDto = templatePreviewDto.issueStereotype!;

    const sortedCustomPropertySets: CustomSetReferenceDto[] = this._sortCustomPropertySets(issueStereotype);
    const stereotypeCustomProperties: CustomPropertyDto[] = this._flattenCustomProperties(sortedCustomPropertySets);
    const mutateCustomPropertyValues: FilledCustomValueDto[] = this._prepareCustomPropertyValues(customPropertyValues, issueStereotype, stereotypeCustomProperties);

    return {
      title: templatePreviewDto.name,
      tenantId: issueStereotype?.tenantId,
      stereotypeId: issueStereotype?.stereotypeId,
      stereotypeRowVersion: issueStereotype?.rowVersion,
      customPropertyValues: mutateCustomPropertyValues,
      resource: resourceDto
    };
  }

  getAdditionalFormattedData(issueDto: MissionIssueDto): Partial<IssueFormattedDetailData> {
    const missionDetail = issueDto.mission;
    return {
      mission: missionDetail
    };
  }
}
