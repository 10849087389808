/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum FilterOperators {
    Equal = '0',
    GreaterThan = 1,
    LessThan = 2,
    NotEqual = 3,
    Contains = 4,
    LessThanOrEqual = 5,
    GreaterThanOrEqual = 6,
    Default = -1,
}
