<ion-list class="ion-no-padding">
  <ion-item class="ion-no-padding">
    <ion-select [formControl]="formControl"
                interface="popover"
                labelPlacement="stacked"
                [label]="isRequired ? label + ' *': label">
      <ion-select-option *ngFor="let option of options" [value]="option.customValueId">{{ option.value }}
      </ion-select-option>
    </ion-select>
  </ion-item>
  <div class="input-error" *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
    <p *ngIf="formControl.errors['required']">{{ 'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.REQUIRED' | translate }}</p>
  </div>
</ion-list>
