/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum TaskState {
    Inactive = '0',
    Active = 1,
    Archived = 2,
    Deleted = 3,
}
