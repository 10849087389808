<ion-list class="ion-no-padding">
  <ion-item class="ion-no-padding">
    <ion-input
      [label]="isRequired ? label + ' *': label"
      labelPlacement="stacked"
      type="hidden"
      [formControl]="formControl"
    ></ion-input>
    <ion-checkbox [formControl]="formControl"
                  labelPlacement="end"
    >
    </ion-checkbox>
  </ion-item>
  <div class="input-error" *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
    <p *ngIf="formControl.errors['required']">{{ 'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.REQUIRED' | translate }}</p>
  </div>
</ion-list>
