import {createFeatureSelector, createSelector} from "@ngrx/store";
import {LoginBaseState} from "./login-base.state";

export const selectLogin = createFeatureSelector<LoginBaseState>('login');

export const selectCurrentApp = createSelector(
  selectLogin,
  (state: LoginBaseState) => state.currentApp
);

export const selectCurrentLogin = createSelector(
  selectLogin,
  (state: LoginBaseState) => state.currentLogin
);

export const selectIsLoading = createSelector(
  selectLogin,
  (state: LoginBaseState) => state.isLoading
);

export const selectIsLoadingId = createSelector(
  selectLogin,
  (state: LoginBaseState) => state.isLoadingId
);

export const selectTenantId = createSelector(
  selectLogin,
  (state: LoginBaseState) => state.currentTenant?.tenantId
);

export const selectTenantSettings = createSelector(
  selectLogin,
  (state: LoginBaseState) => state.currentTenant?.tenantSettings!
);

export const selectTenantDisplayName = createSelector(
  selectLogin,
  (state: LoginBaseState) => state.currentTenant?.tenantDisplayName!
);

export const selectToken = createSelector(
  selectLogin,
  (state: LoginBaseState) => {
    return state.app
  }
);
