import {enableProdMode, importProvidersFrom} from '@angular/core';
import {bootstrapApplication} from '@angular/platform-browser';
import {RouteReuseStrategy, provideRouter} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {environment} from '@env-link/environment';
import {provideStore} from "@ngrx/store";
import {provideEffects} from "@ngrx/effects";
import {provideStoreDevtools} from "@ngrx/store-devtools";
import {IonicStorageModule} from "@ionic/storage-angular";
import {Drivers} from '@ionic/storage';
import {appStore} from "@core/store/app.reducer";
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClient} from "@angular/common/http";
import {linkStore} from "@link/store/link.reducer";
import {LinkApiEffects} from "@link/store/link-api.effect";
import {DefaultDataServiceConfig, EntityDataModule, EntityMetadataMap, provideEntityData} from "@ngrx/data";
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TimeoutInterceptor} from "@shared/interceptors/timeout.interceptor";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {attachmentsStore} from "@shared/components/attachments/store/attachments.reducers";
import {AttachmentsApiEffects} from "@shared/components/attachments/store/attachments-api.effects";
import {linkLoginStore} from "@link/pages/login/store/link-login.reducer";
import {LinkLoginEffects} from "@link/pages/login/store/link-login.effects";
import routes from "@link/routes";
import {LinkAppComponent} from "@link/link-app.component";
import CordovaSQLiteDriver from "localforage-cordovasqlitedriver";
import {linkIssuesStore} from "@link/pages/issues/store/link-issues.reducers";
import {defineCustomElements} from "@ionic/pwa-elements/loader";
import {LinkIssuesApiEffects} from "@link/pages/issues/store/link-issues-api.effects";


if (environment.production) {
  enableProdMode();
}

defineCustomElements(window);

const entityMetadataMap: EntityMetadataMap = {
  Chat: {
    selectId: chat => chat.chatByFormDefinitionId
  }
};

const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: environment.apiUrl.one
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

bootstrapApplication(LinkAppComponent, {
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig},
    {provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true},
    provideStore({
      app: appStore,
      login: linkLoginStore,
      link: linkStore,
      issues: linkIssuesStore,
      attachments: attachmentsStore,
    }),
    provideEffects([
      LinkLoginEffects,
      LinkApiEffects,
      LinkIssuesApiEffects,
      AttachmentsApiEffects
    ]),
    provideStoreDevtools({
      name: 'semco ONE'
    }),
    provideRouter(routes),
    provideEntityData({entityMetadata: entityMetadataMap}),
    importProvidersFrom(
      IonicModule.forRoot({
        spinner: 'dots',
        swipeBackEnabled: false,
      }),
      IonicStorageModule.forRoot({
        name: 'one',
        driverOrder: [
          CordovaSQLiteDriver._driver,
          Drivers.IndexedDB,
          Drivers.LocalStorage
        ]
      }),
      HttpClientModule,
      BrowserAnimationsModule,
      EntityDataModule.forRoot({}),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        }
      })
    ),
  ]
});
