import {Component, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {SafePipe} from "@shared/pipes/safe.pipe";
import {BaseIssuesActions} from "@shared/components/issues/store/base-issues.action-type";
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-base-issue-success',
  templateUrl: './base-issue-success.component.html',
  styleUrls: ['./base-issue-success.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, SafePipe],

})
export class BaseIssueSuccessComponent implements OnInit {

  constructor(
    private _store: Store
  ) {

  }

  ngOnInit() {
  }

  /**
   * @name back
   * @description
   * dispatch an action to navigate to the previous allow page
   * @memberof BaseIssueSuccessComponent
   */
  back(): void {
    this._store.dispatch(BaseIssuesActions.thanksPageNavigationBack())
  }

}
