import {Injectable} from "@angular/core";
import {TeamHttpClientBaseApiService} from "@team/services/http-client-base-api/team-http-client-base-api.service";
import {KnowledgeArticleStartDto, KnowledgePathListDto, KnowledgePathStartDto} from "@server-models";
import {Observable, throwError} from "rxjs";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {catchError, map} from "rxjs/operators";
import {PaginationParams} from "@shared/interfaces/pagination-params.interface";

@Injectable({
  providedIn: 'root'
})
export class TeamKnowledgeService extends TeamHttpClientBaseApiService {

  /**
   * @name getPathItems
   * @description
   * get the knowledge path list
   * @memberof TeamKnowledgeService
   * @param params
   */
  getPathItems(params: PaginationParams): Observable<OrgaResponse<KnowledgePathListDto[]>> {
    const options = {
      params: {
        PageSize: params.pageSize.toString(),
        PageNumber: params.pageNumber.toString(),
        Cols: params.cols,
        Sort: params.sort!.toString()
      }
    }
    return super.get<OrgaResponse<KnowledgePathListDto[]>>(`/orga/v2/knowledgePath`,
      undefined, options).pipe(
      map((res: OrgaResponse<KnowledgePathListDto[]>) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  /**
   * @name getPathItemByIdStart
   * @description
   * get the knowledge path items by id
   * @memberof TeamKnowledgeService
   * @param id
   * @return {Observable<KnowledgePathStartDto>}
   */
  getPathItemByIdStart(id: string): Observable<KnowledgePathStartDto> {
    return super.get<KnowledgePathStartDto>(`/orga/v2/knowledgePath/${id}/start`,
      undefined).pipe(
      map((res: KnowledgePathStartDto) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  /**
   * @name getArticle
   * @description
   * request to v3 orga the knowledge article by id
   * @memberof TeamKnowledgeService
   * @param id
   * @returns {Observable<KnowledgePathStartDto>}
   */
  getArticle(id: number): Observable<KnowledgeArticleStartDto> {
    return super.get<KnowledgeArticleStartDto>(`/orga/v3/knowledgeArticle/${id}/start`, undefined);
  }

  /**
   * @name getBySearch
   * @description
   * make a request to search knowledges by search text
   * @memberof
   * @param searchText
   * @return {Observable<OrgaResponse<KnowledgePathListDto[]>>}
   */
  getBySearch(searchText: string): Observable<OrgaResponse<KnowledgePathListDto[]>> {
    const options = {
      params: {
        q: searchText
      }
    }
    return super.get<OrgaResponse<KnowledgePathListDto[]>>(`/orga/v3/knowledgeArticle/search`, undefined, options);

  }
}
