import {Injectable} from "@angular/core";
import {from, Observable} from "rxjs";
import {ModalController} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class ModalControllerService {
  constructor(private _modalCtrl: ModalController) {
  }

  /**
   * @name showModal
   * @description
   * call the created modal promise as observable
   * @memberof ModalControllerService
   * @param component
   * @param cssClass
   * @param componentProps
   * @returns {Observable<void>}
   */
  showModal(component: any, cssClass: string, componentProps: Object): Observable<void> {
    return from(this._presentModal(component, cssClass, componentProps));
  }

  /**
   * @name closeModal
   * @description
   * close the modal promise as observable
   * @memberof ModalControllerService
   * @returns {Observable<boolean>}
   */
  closeModal(): Observable<boolean> {
    return from(this._checkExistingAndDismiss());
  }

  /**
   * @name _presentModal
   * @description
   * create the modal
   * @memberof ModalControllerService
   * @param component
   * @param cssClass
   * @param componentProps
   * @private
   * @returns {Promise<void>}
   */
  private async _presentModal(component: any, cssClass: string, componentProps: Object): Promise<void> {
    const modal = await this._modalCtrl.create({
      component: component,
      cssClass: cssClass,
      componentProps: componentProps,
      backdropDismiss: false
    });

    await modal.present();
  }

  /**
   * @name _checkExistingAndDismiss
   * @description
   * check if there is an existing modal and close it or dont launch the close if there is not modal
   * @memberof ModalControllerService
   * @private
   * @returns {Promise<boolean>}
   */
  private async _checkExistingAndDismiss(): Promise<boolean> {
    const existingModal = await this._checkExisting();
    if (existingModal) {
      await this._modalCtrl.dismiss();
      return true;
    }
    return false;
  }

  /**
   * @name _checkExisting
   * @description
   * check if there is existing modal
   * @memberof ModalControllerService
   * @private
   * @returns {Promise<HTMLIonModalElement | undefined>}
   */
  private async _checkExisting(): Promise<HTMLIonModalElement | undefined> {
    return await this._modalCtrl.getTop();
  }
}
