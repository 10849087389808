import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {SafePipe} from "@shared/pipes/safe.pipe";
import {UserHtmlComponent} from "@features/user-html/user-html.component";
import {AttachmentsComponent} from "@shared/components/attachments/attachments.component";
import {RouterLink} from "@angular/router";
import {CustomPropertyComponent} from "@shared/components/custom-property/custom-property.component";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {InputTextComponent} from "@shared/components/custom-property/inputs/text/input-text.component";
import {
  MissionIssuePreviewDto
} from "@server-models";
import {
  IssueCustomPropertyFormComponent
} from "@shared/components/issues/components/custom/form/properties/issue-custom-property-form.component";

@Component({
  selector: 'app-base-issue-mission-template-preview',
  templateUrl: './base-issue-mission-template-preview.component.html',
  styleUrls: ['./base-issue-mission-template-preview.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    SafePipe,
    UserHtmlComponent,
    AttachmentsComponent,
    RouterLink,
    InputTextComponent,
    ReactiveFormsModule,
    CustomPropertyComponent,
    IssueCustomPropertyFormComponent
  ]
})
export class BaseIssueMissionTemplatePreviewComponent {

  @Input() missionTemplatePreview!: MissionIssuePreviewDto;
  @Output() formChange = new EventEmitter<FormGroup>();
  parentForm: FormGroup;

  constructor(private _fb: FormBuilder) {
    this.parentForm = this._fb.group({});
  }

  formInnerModified(ev: any) {
    this.parentForm = ev;
    this.formChange.emit(this.parentForm);
  }
}
