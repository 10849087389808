<ion-list class="ion-no-padding">
  <ion-item class="ion-no-padding" lines="none">
    <ion-input
      [label]="isRequired ? label + ' *': label"
      labelPlacement="stacked"
      type="hidden"
      [formControl]="formControl"
    ></ion-input>
  </ion-item>
  <ng-container>
    <ion-item class="ion-no-padding">
      <div #canvasContainer class="signature-pad">
        <canvas class="signature-pad-canvas" #canvas style="touch-action: none;"></canvas>
      </div>
    </ion-item>
    <ion-item *ngIf="!(checkLoading() | async)" class="ion-no-padding" lines="none" style="float: right">
      <ng-container>
        <ion-button [disabled]="isCanvasBlank()"
                    (click)="clear()">{{ "TEAM.ISSUES.COMPONENTS.SIGNATURE.BUTTONS.CLEAR" | translate }}
        </ion-button>
        <ion-button [disabled]="isCanvasBlank()"
                    (click)="undo()">{{ "TEAM.ISSUES.COMPONENTS.SIGNATURE.BUTTONS.UNDO" | translate }}
        </ion-button>
      </ng-container>
    </ion-item>
    <ion-item *ngIf="(checkLoading() | async)" class="ion-no-padding" lines="none">
      <div class="spinner-container">
        <ion-spinner></ion-spinner>
      </div>
    </ion-item>
  </ng-container>
  <div class="input-error" *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
    <p *ngIf="formControl.errors['required']" >{{'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.REQUIRED' | translate}}</p>
  </div>
</ion-list>
