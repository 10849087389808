import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {ChildAppService} from "@core/child-app/child-app.service";
import {Store} from "@ngrx/store";
import {
  selectIsLoading, selectOccurredErrors,
  selectOccurredHints,
  selectOccurredHintsLoading
} from "../../store/link.selector";

import {ClockService} from "@core/services/clock.service";
import {TranslateModule} from "@ngx-translate/core";
import {PropComponent} from "@shared/components/prop/prop.component";
import {LinkLoginSelectors} from "@link/pages/login/store/link-login.selector-type";
import {LinkActions} from "@link/store/link.actions-type";

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, TranslateModule, PropComponent]
})
export class HomePage implements OnInit {
  public isChildApp: boolean;
  public isLoading$ = this.store.select(selectIsLoading);

  public resource$ = this.store.select(LinkLoginSelectors.selectResource);
  public hints$ = this.store.select(selectOccurredHints);
  public errors$ = this.store.select(selectOccurredErrors);
  public hintsIsLoading$ = this.store.select(selectOccurredHintsLoading);

  constructor(
    private childAppService: ChildAppService,
    private store: Store,
    private clock: ClockService
  ) {
    this.isChildApp = this.childAppService.isChildApp('link');
  }

  ngOnInit() {

    this.isLoading$.subscribe(isLoading => {
      if (!isLoading) {
        this.store.dispatch(LinkActions.getProfileDashboard())
      }
    });
  }

  //todo add home screen values
  close = async () => await this.childAppService.close();
  protected readonly location = location;


  parseDatetime(date: string | null) {
    return this.clock.parse(date);
  }

  formatDatetime(date: null | Date, withTime = true) {
    return this.clock.format(date, withTime);
  }

  formatDatetimeTo(date: null | Date) {
    return this.clock.to(date);
  }
}
