import {Component, Input, OnInit} from '@angular/core';
import {Observable, of} from "rxjs";
import {KnowledgeArticleDto} from '@server-models';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {DomSanitizer} from "@angular/platform-browser";
import {UserHtmlComponent} from "@features/user-html/user-html.component";
import {AttachmentsComponent} from "@shared/components/attachments/attachments.component";

@Component({
  selector: 'app-knowledge-detail',
  templateUrl: './knowledge-detail.component.html',
  styleUrls: ['./knowledge-detail.component.scss'],
  standalone: true,
    imports: [CommonModule, IonicModule, UserHtmlComponent, AttachmentsComponent]
})
export class KnowledgeDetailComponent implements OnInit {

  @Input()
  public knowledge$: Observable<KnowledgeArticleDto | undefined> = of();

  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
  }

  sanitize(content: string | null | undefined) {
    return this.sanitizer.bypassSecurityTrustHtml(content || '');
  }
}
