/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SemcoStereotypes {
    Disruption = '0',
    DeliveryAddress = 1,
    InvoiceAddress = 2,
    ServiceProvider = 3,
    Customer = 4,
    Branch = 5,
    Production = 6,
    Employee = 7,
    Technician = 8,
    Material = 9,
    Apparatus = 10,
    Group = 11,
    GroupForLocations = 12,
    District = 13,
    Assignment = 14,
    Contract = 15,
    ArticleStandard = 16,
    MissionReportStandard = 17,
    InspectionReportStandard = 18,
    ControlTag = 19,
}
