import {Component, Input} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";

@Component({
  selector: 'app-prop',
  templateUrl: './prop.component.html',
  styleUrls: ['./prop.component.scss'],
  imports: [
    CommonModule,
    IonicModule
  ],
  standalone: true
})
export class PropComponent {

  @Input() title: string | null | undefined;

}
