/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ResourceTicketSkeletonSelectorType {
    Base = '0',
    All = 1,
    RestrictedByType = 2,
    Whitelisted = 3,
    Fixed = 4,
}
