import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input, OnChanges,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule, NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import SignaturePad, {PointGroup} from 'signature_pad';
import {select, Store} from "@ngrx/store";
import {Observable, of, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {BaseIssuesSelectors} from "@shared/components/issues/store/base-issues.selectors-type";
import {BaseIssuesActions} from "@shared/components/issues/store/base-issues.action-type";

@Component({
  selector: 'app-input-signature',
  templateUrl: './input-signature.component.html',
  styleUrls: ['./input-signature.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule,
    ReactiveFormsModule, TranslateModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputSignatureComponent
    }
  ]
})
export class InputSignatureComponent implements OnChanges, AfterViewInit, ControlValueAccessor {

  @Input() label: string = '';
  @Input() isRequired: boolean;
  @Output() valueChange = new EventEmitter<string>();
  @Input() formControl: FormControl;
  @Input() index: number;

  @ViewChild('canvas', {static: true}) signaturePadElement!: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasContainer', {static: true}) canvasContainer!: ElementRef<HTMLDivElement>;

  value: string = '';
  signaturePad: SignaturePad | any;
  signatureLoading$: Observable<boolean>;
  signatureLoadingId$: Observable<number>;

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  constructor(
    private elementRef: ElementRef,
    private _store: Store
  ) {
    this.formControl = new FormControl();
    this.signatureLoading$ = of(false);
    this.signatureLoadingId$ = of(NaN);
    this.index = NaN;
    this.isRequired = false;
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['formControl']) {
      this.init();
      this.signatureLoading$ = this._store.pipe(select(BaseIssuesSelectors.selectSelectedSignatureIsLoading));
      this.signatureLoadingId$ = this._store.pipe(select(BaseIssuesSelectors.selectSelectedSignatureIsLoadingId));
      this._store.pipe(select(BaseIssuesSelectors.selectSavedSignatureId)).subscribe(
        (signatureId) => {
          if (signatureId) {
            this.formControl.setValue(signatureId)
          }
        });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.init();
  }

  /**
   * @name init
   * @description
   * start the canvas and clear the pad on call
   * @memberof InputSignatureComponent
   */
  init() {
    const canvas: any = this.elementRef.nativeElement.querySelector('canvas');
    const reduceMaxWindows = window.innerWidth * 0.04;
    canvas.width = window.innerWidth - reduceMaxWindows;
    canvas.height = 200;
    this.clear();
  }

  ngAfterViewInit(): void {
    this.init();
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);
    this.signaturePad.clear();
    this.signaturePad.penColor = 'rgb(56,128,255)';
    this.signaturePad.addEventListener('endStroke', () => {
      this.formControl.markAsDirty();
      this.formControl.markAsTouched();
      this.save();

    });
  }

  /**
   * @name save
   * @description
   * save and dispatch the signature draw from data url to blob
   * @memberof InputSignatureComponent
   */
  save(): void {
    const dataUrl = this.signaturePad.toDataURL();
    this._store.dispatch(BaseIssuesActions.convertDateURLToBlob({isLoadingId: this.index, dataUrl}));
  }

  /**
   * @name isCanvasBlank
   * @description
   * check if the canvas is empty
   * @memberof InputSignatureComponent
   */
  isCanvasBlank(): boolean {
    if (this.signaturePad) {
      return this.signaturePad.isEmpty();
    }
    return true;
  }

  /**
   * @name clear
   * @description
   * clear the value of the signature pad
   * @memberof InputSignatureComponent
   */
  clear(): void {
    if (this.signaturePad) {
      this.signaturePad.clear();
      this.formControl.markAsDirty();
      this.formControl.setValue(undefined);
    }
  }

  /**
   * @name undo
   * @description
   * undo the last draw of the signature pad
   * @memberof InputSignatureComponent
   */
  undo(): void {
    const data: PointGroup[] = this.signaturePad.toData();
    if (data) {
      data.pop();
      this.signaturePad.fromData(data);
      if (data.length === 0) {
        this.formControl.markAsDirty();
        this.formControl.setValue(undefined);
      }
    }
  }

  /**
   * @name checkLoading
   * @description
   * check if there is a loading state with a specific index
   * @memberof InputSignatureComponent
   */
  checkLoading(): Observable<boolean> {
    return this.signatureLoadingId$.pipe(
      withLatestFrom(this.signatureLoading$),
      map(([id, isLoading]) => (this.index! === id) && isLoading)
    );
  }
}
