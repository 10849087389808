<ion-header>
  <ion-toolbar>
    <ion-title>{{ name }}</ion-title>
    <ion-buttons slot="end">
      <ion-button color="primary" (click)="exit()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-card-content>
    <app-user-html [html]="htmlContent"></app-user-html>
    <app-attachments [attachmentsInc]="attachmentsInc"></app-attachments>
  </ion-card-content>
</ion-content>
