import {createReducer, on} from "@ngrx/store";
import {AttachmentsState} from "@shared/components/attachments/store/attachments.state";
import {AttachmentsActions} from "@shared/components/attachments/store/attachments.actions-type";

export const initialAttachmentsState: AttachmentsState = {
  name: "",
  path: "",
  isLoading: true,
  isLoadingId: NaN
};

export const attachmentsStore = createReducer(
  initialAttachmentsState,
  on(AttachmentsActions.getFile, (state, args) => ({
    ...state,
    name: args.name,
    path: args.path,
    isLoading: true,
    isLoadingId: args.id
  })),
  on(AttachmentsActions.getFileSuccess, (state, args) => ({
    ...state,
    name: args.name,
    isLoading: false,
    isLoadingId: args.id
  })),
  on(AttachmentsActions.getFileFail, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(AttachmentsActions.openLink, (state, args) => ({
    ...state,
    isLoading: true,
    isLoadingId: args.id
  })),
  on(AttachmentsActions.openLinkSuccess, (state, args) => ({
    ...state,
    isLoading: false,
    isLoadingId: args.id
  })),
  on(AttachmentsActions.openLinkFail, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(AttachmentsActions.getKnowledgeArticle, (state, args) => ({
    ...state,
    isLoading: true,
    isLoadingId: args.id
  })),
  on(AttachmentsActions.getKnowledgeArticleSuccess, (state, args) => ({
    ...state,
    isLoading: false,
    isLoadingId: args.id
  })),
  on(AttachmentsActions.getKnowledgeArticleFail, (state) => ({
    ...state,
    isLoading: false,
  })),
)
