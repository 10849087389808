import {createFeatureSelector, createSelector} from "@ngrx/store";
import {LinkLoginState} from "./link-login.state";
import {type ResourceProfileDto, ResourceProfileOverviewDto} from "@server-models";
import {LinkTabNavigation} from "@link/store/link.state";

export const selectLogin = createFeatureSelector<LinkLoginState>('login');

export const selectLinkId = createSelector(
    selectLogin,
    (state: LinkLoginState) => state.linkId
);

export const selectIsByResource = createSelector(
  selectLogin,
  (state: LinkLoginState) => state.isByResource
);

export const selectIsLogged = createSelector(
  selectLogin,
  (state: LinkLoginState) => state.isLogged
);

export const selectLinkKey = createSelector(
  selectLogin,
  (state: LinkLoginState) => state.linkKey
);

export const selectProfile = createSelector(
  selectLogin,
  (state: LinkLoginState) => state.profile
);

export const selectResource = createSelector(
  selectProfile,
  (state: ResourceProfileOverviewDto) => state?.resource!
);

export const selectProperties = createSelector(
  selectResource,
  (state: ResourceProfileDto) => state?.customPropertyValues ?? []
);

export const selectStereotype = createSelector(
  selectResource,
  (state: ResourceProfileDto) => state?.stereotype
);

export const selectFeatures = createSelector(
  selectProfile,
  (state: ResourceProfileOverviewDto) => state?.features!
);

export const selectTabNavigation = createSelector(
  selectLogin,
  (state: LinkLoginState) => state.tabNavigation
);

export const selectIssueNavigation = createSelector(
  selectTabNavigation,
  (state: LinkTabNavigation) => state.issues
);

export const selectKnowledgeNavigation = createSelector(
  selectTabNavigation,
  (state: LinkTabNavigation) => state.knowledges
);
