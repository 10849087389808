import {Component, Input} from '@angular/core';
import {IonicModule} from "@ionic/angular";
import {NgxExtendedPdfViewerModule, NgxExtendedPdfViewerService, PagesLoadedEvent} from "ngx-extended-pdf-viewer";
import {ModalControllerService} from "@shared/services/modal-controller.service";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-viewer-pdf',
  templateUrl: './viewer-pdf.component.html',
  styleUrls: ['./viewer-pdf.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    NgxExtendedPdfViewerModule,
    TranslateModule
  ],
  standalone: true
})
export class ViewerPdfComponent {

  @Input() file!: Blob;
  @Input() name!: string;

  zoomLevel!: string;
  zoomPercentage: number;
  isSearch: boolean;
  currentPage: number;
  pagesCount: number;
  isSidebarVisible: boolean;

  constructor(
    private _modalControllerService: ModalControllerService,
    private _pdfService: NgxExtendedPdfViewerService,
  ) {
    this.zoomPercentage = 100;
    this.isSearch = false;
    this.currentPage = 1;
    this.pagesCount = 1;
    this.isSidebarVisible = false;
  }

  /**
   * @name exit
   * @description
   * call modal controller service to close the modal
   * @memberof ViewerPdfComponent
   */
  exit(): void {
    this._modalControllerService.closeModal();
  }

  /**
   * @name onSearch
   * @description
   * trigger the on search event to find in the pdf
   * @memberof ViewerPdfComponent
   * @param $event
   */
  onSearch($event: any): void {
    const value = $event.detail.value;
    this.isSearch = !!value;
    this._pdfService.find(value);
  }

  /**
   * @name zoomChange
   * @description
   * calculate the zoom in percentage
   * @memberof ViewerPdfComponent
   * @param $event
   */
  zoomChange($event: any): void {
    this.zoomPercentage = Math.round($event * 100);

    if (!this.zoomLevel) {
      this.zoomLevel = this.zoomPercentage.toString();
    }
  }

  /**
   * @name pagesLoaded
   * @description
   * trigger the pages loaded and saved into variable
   * @memberof ViewerPdfComponent
   * @param event
   */
  pagesLoaded(event: PagesLoadedEvent) {
    this.pagesCount = event.pagesCount;
  }

  /**
   * @name shiftPage
   * @description
   * change the current page
   * @memberof ViewerPdfComponent
   * @param offset
   */
  shiftPage(offset: number) {
    this.currentPage += offset;
    this.currentPage = Math.max(1, Math.min(this.currentPage, this.pagesCount));
  }

  /**
   * @name shiftZoom
   * @description
   * change the zoom
   * @memberof ViewerPdfComponent
   * @param offset
   */
  shiftZoom(offset: number): void {
    const increase = this.zoomPercentage * 0.10 * offset;
    this.zoomLevel = (this.zoomPercentage + increase).toString();
    this.zoomLevel = Math.max(10, Math.min(+this.zoomLevel, 1000)).toString();
  }

  /**
   * @name toggleSidebar
   * @description
   * show/hide the toggle sidebar from the pdf library
   * @memberof
   */
  toggleSidebar(): void {
    this.isSidebarVisible = !this.isSidebarVisible;
  }

  /**
   * @name searchNext
   * @description
   * on search let pdf service change next page
   * @memberof ViewerPdfComponent
   */
  searchNext() {
    this._pdfService.findNext();
  }

  /**
   * @name searchPrev
   * @description
   * on search let pdf service change previous page
   * @memberof ViewerPdfComponent
   */
  searchPrev() {
    this._pdfService.findPrevious();
  }

}
