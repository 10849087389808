/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum MissionState {
    Created = '0',
    Instructed = 1,
    Rejected = 2,
    Canceled = 3,
    Done = 4,
}
