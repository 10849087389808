import {Injectable} from "@angular/core";
import {Actions} from "@ngrx/effects";
import {select, Store} from "@ngrx/store";
import {exhaustMap, mergeMap, Observable, withLatestFrom} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {
  FileDto,
  IssueContentType,
  IssueListDto,
  IssueTemplateListDto,
  IssueType,
  MessageIssueDto, MessageIssuePreviewDto,
  MissionIssueDto, MissionIssuePreviewDto, ResourceListDto,
  StereotypeDto,
  StereotypeListDto, SubmitMessageIssueDto, SubmitMissionIssueDto, SubmitTicketIssueDto,
  TicketIssueDto, TicketIssuePreviewDto,
} from "@server-models";
import {Router} from "@angular/router";
import {ImageService} from "@shared/services/image.service";
import {CameraService} from "@shared/services/camera.service";
import {ModalLoaderService} from "@shared/services/modal-loader.service";
import {TranslateService} from "@ngx-translate/core";
import {LinkIssuesApiService} from "@link/pages/issues/services/link-issues-api.service";
import {LinkFileApiService} from "@link/services/link-file-api.service";
import {LinkIssuesActions} from "@link/pages/issues/store/link-issues.actions-type";
import {LinkLoginSelectors} from "@link/pages/login/store/link-login.selector-type";
import {IssuesBaseService} from "@shared/components/issues/services/issues-base.service";
import {IssuesRequestPagination} from "@shared/components/issues/interfaces/issues-request-pagination.interface";
import {IssueFormattedDetailData} from "@shared/components/issues/interfaces/issue-formatted-detail-data.interface";
import {BaseIssuesApiEffects} from "@shared/components/issues/store/base-issues-api.effects";
import {LinkLoginService} from "@link/pages/login/services/link-login.service";
import {BaseIssuesActions} from "@shared/components/issues/store/base-issues.action-type";
import {
  TemplatePreviewIssueType
} from "@shared/components/issues/store/base-issues.actions";
import {LinkIssuesService} from "@link/pages/issues/services/link-issues.service";
import {IssuesBaseFactory} from "@shared/components/issues/services/issues-base.factory";
import {LinkIssuesSelectors} from "@link/pages/issues/store/link-issues.selector-type";
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class LinkIssuesApiEffects extends BaseIssuesApiEffects {

  constructor(
    actions$: Actions,
    store: Store,
    _cameraService: CameraService,
    _modalLoaderService: ModalLoaderService,
    _translateService: TranslateService,
    _imageService: ImageService,
    _issuesBaseService: IssuesBaseService,
    _issuesBaseFactory: IssuesBaseFactory,
    _router: Router,
    private _linkIssuesApiService: LinkIssuesApiService,
    private _linkIssuesService: LinkIssuesService,
    private _linkFileApiService: LinkFileApiService,
    private _linkLoginService: LinkLoginService
  ) {
    super(actions$, store, _cameraService, _modalLoaderService, _translateService, _imageService, _issuesBaseService, _issuesBaseFactory, _router)
  }

  _requestGetPaginated(action: IssuesRequestPagination): Observable<(OrgaResponse<IssueListDto[]> | any)> {
    return this._linkIssuesApiService.getIssueRecentList(action.params).pipe(
      map((data: OrgaResponse<IssueListDto[]>) =>
        action.refresh
          ? LinkIssuesActions.getItemsPaginatedRefresh({data})
          : LinkIssuesActions.getItemsPaginatedSuccess({data})
      ),
      catchError((error) => [LinkIssuesActions.getItemsPaginatedFail({error})])
    )
  }

  _requestGetTemplate(): Observable<(OrgaResponse<IssueTemplateListDto[]> | any)> {
    return this._linkIssuesApiService.getIssueTemplateList().pipe(
      map((data: OrgaResponse<IssueTemplateListDto[]>) =>
        LinkIssuesActions.getItemsTemplateSuccess({data})
      ),
      catchError((error) => [LinkIssuesActions.getItemsTemplateFail({error})])
    )
  }

  override formatReadDetailDataFinish(action: Observable<{
    detailDataFormatted: IssueFormattedDetailData
  }>): Observable<any> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(LinkLoginSelectors.selectLinkId))),
      tap(([action, linkId]) => {
        this._router.navigate([`link/${linkId!}/issues/${action.detailDataFormatted.issueId}`])
      })
    )
  }

  override sendTemplatePreviewSuccess(action: Observable<any>): Observable<any> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(LinkLoginSelectors.selectLinkId))),
      exhaustMap(([_, linkId]) => {
        this._router.navigate([`link/${linkId!}/issues/success`]);
        return [LinkIssuesActions.thanksPageNavigation()]
      })
    );
  }

  override thanksPageNavigationBack(action: Observable<any>): Observable<any> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(LinkLoginSelectors.selectLinkId))),
      withLatestFrom(this.store.pipe(select(LinkLoginSelectors.selectFeatures))),
      exhaustMap(([[_, linkId], features]) => {
        const issuesNav = this._linkLoginService.checkIssuesNav(features!);
        if (issuesNav != null) {
          this._router.navigate([`link/${linkId!}/${issuesNav}`])
        }
        return [BaseIssuesActions.thanksPageNavigationBackDone()]
      })
    );
  }

  _requestGetStereotypeById(action: { stereotypeId: number, cacheGuid: string }): Observable<{
    stereotypeId: number,
    cacheGuid: string
  } | any> {
    return this._linkIssuesApiService.getStereotypeById(action.stereotypeId).pipe(
      map((data: StereotypeDto) => {
          return LinkIssuesActions.getStereotypeByIdSuccess({stereotype: data, cacheGuid: action.cacheGuid})
        }
      ),
      catchError((error) => [LinkIssuesActions.getStereotypeByIdFail({error})])
    )
  }

  _requestGetStereotypeByEntityType(entityType: string, cacheControl: string): Observable<OrgaResponse<StereotypeListDto> | any> {
    return this._linkIssuesApiService.getStereotypeFilterByEntityType(entityType, cacheControl).pipe(
      map((data: OrgaResponse<StereotypeListDto>) => {
          return LinkIssuesActions.getStereotypeByEntityTypeSuccess({data})
        }
      ),
      catchError((error) => [LinkIssuesActions.getStereotypeByEntityTypeFail({error})])
    )
  }

  override navigateToNewIssue(action: Observable<any>) {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(LinkLoginSelectors.selectLinkId))),
      tap(([_, linkId]) => {
        this._router.navigate([`link/${linkId!}/issues/new`])
      })
    )
  }

  override navigateToNewSpecificTemplate(action: Observable<{ templateId: number }>) {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(LinkLoginSelectors.selectLinkId))),
      tap(([action, linkId]) => {
        this._router.navigate([`link/${linkId!}/issues/new/${action.templateId}`])
      })
    )
  }

  override navigateToIssues(action: Observable<any>) {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(LinkLoginSelectors.selectLinkId))),
      tap(([_, linkId]) => {
        this._router.navigate([`link/${linkId!}/issues`])
      })
    )
  }

  override navigateToTemplatePreview(action: Observable<{
    templateId: number,
    templateIssueType: IssueContentType
  }>): Observable<any> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(LinkLoginSelectors.selectLinkId))),
      tap(([{templateId}, linkId]) => {
        this._router.navigate([`link/${linkId!}/issues/new/${templateId}`])
      })
    );
  }

  override _requestGetTemplatePreviewByIssueType(action: TemplatePreviewIssueType): Observable<(unknown | any)> {
    const apiService = this._linkIssuesService.getApiServiceByIssueContentType(action.issueType);
    return apiService.getIssueTemplatePreviewListById(action.id).pipe(
      map((data) => {
          return LinkIssuesActions.getItemsTemplatePreviewSuccess({data})
        }
      ),
      catchError((error) => [LinkIssuesActions.getItemsTemplatePreviewFail({error})])
    )
  }

  override requestItemsResource(action: any): Observable<any> {
    return this._linkIssuesApiService.requestResourceListByFilter([{}]).pipe(
      map((data: OrgaResponse<ResourceListDto>) =>
        LinkIssuesActions.getItemsResourceSuccess({data})
      ),
      catchError((error) => [LinkIssuesActions.getItemsResourceFail({error})])
    )
  }


  _requestByIdByIssueType(action: { id: number, issueType: IssueType }): Observable<any> {
    const service = this._linkIssuesService.getApiServiceByIssueType(action.issueType);
    return service.getIssueById(action.id).pipe(
      map((data: MessageIssueDto | MissionIssueDto | TicketIssueDto) =>
        LinkIssuesActions.getByIdSuccess({data, cacheGuid: this._issuesBaseService.generateGuid()})
      ),
      catchError((error) => [LinkIssuesActions.getByIdFail({error})])
    )
  }

  override prepareFormsToSendTemplatePreview(action: Observable<{ form: FormGroup, templatePreviewDto: MessageIssuePreviewDto | MissionIssuePreviewDto | TicketIssuePreviewDto }>): Observable<any> {
    return action.pipe(
      withLatestFrom(this.store.pipe((select(LinkIssuesSelectors.selectSelectedTemplateIssueType)))),
      withLatestFrom(this.store.pipe(select(LinkLoginSelectors.selectResource))),
      mergeMap(([[{form, templatePreviewDto}, templateIssueType], resourceDto]) => {
        const service = this._issuesBaseFactory.getServiceByIssueType(templateIssueType);
        const submitReady = service.prepareFormIssueDto(form, templatePreviewDto, resourceDto);
        return [LinkIssuesActions.prepareFormsToSendTemplatePreviewDone({submitReady})]
      })
    );
  }

  override sendTemplatePreview(action: Observable<{issuePrepared: SubmitMessageIssueDto | SubmitMissionIssueDto | SubmitTicketIssueDto
  }>): Observable<void> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(LinkIssuesSelectors.selectSelectedTemplateId))),
      withLatestFrom(this.store.pipe((select(LinkIssuesSelectors.selectSelectedTemplateIssueType)))),
      mergeMap(([[{issuePrepared}, templateId], issueContentType]) => {
        return this._sendTemplatePreviewByIssueType({
          templateId,
          issueContentType,
          issuePrepared,
        });
      })
    );
  }

  override _sendTemplatePreviewByIssueType(action: {
    templateId: number,
    issuePrepared: SubmitMessageIssueDto | SubmitMissionIssueDto | SubmitTicketIssueDto
    issueContentType: IssueContentType
  }): Observable<(unknown | any)> {
    const apiService = this._linkIssuesService.getApiServiceByIssueContentType(action.issueContentType);
    return apiService.sendIssueTemplatePreviewById(action.templateId, action.issuePrepared).pipe(
      map(() => LinkIssuesActions.sendTemplatePreviewSuccess({})),
      catchError(error => [LinkIssuesActions.sendTemplatePreviewFail({error})])
    );
  }

  _saveSignatureRequest(tenantId: number, blob: Blob, fileType: string): Observable<{
    signature: FileDto
  } | any> {
    return this._linkFileApiService.upload(tenantId, blob, fileType).pipe(
      exhaustMap((data: FileDto) => {
          return [LinkIssuesActions.saveSignatureSuccess({signatureId: data.fileId!})]
        }
      ),
      catchError((error) => [LinkIssuesActions.saveSignatureFail({error})])
    )
  }

  _savePictureRequest(tenantId: number, blob: Blob, fileType: string, pictureId: number): Observable<{
    image: FileDto
  } | any> {
    return this._linkFileApiService.upload(tenantId, blob, fileType).pipe(
      exhaustMap((data: FileDto) => {
          return [LinkIssuesActions.savePictureSuccess({fileId: data.fileId!, path: data.path!, pictureId})]
        }
      ),
      catchError((error) => [LinkIssuesActions.savePictureFail({error})])
    )
  }

}
