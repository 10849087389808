import {Injectable} from "@angular/core";
import {EffectsBase} from "@core/base.effects";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Observable} from "rxjs";
import {AttachmentsActions} from "@shared/components/attachments/store/attachments.actions-type";
import {AttachmentsFileAction} from "@shared/components/attachments/interfaces/attachments-file-action.interface";
import {
  AttachmentsFileRequestAction
} from "@shared/components/attachments/interfaces/attachments-file-request-action.interface";
import {OpenOptions} from "@capacitor/browser/dist/esm/definitions";
import {
  AttachmentKnowledgeArticle
} from "@shared/components/attachments/interfaces/attachment-knowledge-article.interface";
import {
  AttachmentKnowledgeArticleResponse
} from "@shared/components/attachments/interfaces/attachment-knowledge-article-response.interface";

@Injectable({
  providedIn: 'root'
})
export abstract class AttachmentsEffectsBase extends EffectsBase {

  protected constructor(store: Store, rawActions$: Actions) {
    super(store, rawActions$);
  }

  abstract getFile(action: Observable<AttachmentsFileRequestAction>): Observable<AttachmentsFileRequestAction | any>;

  abstract getFileSuccess(action: Observable<AttachmentsFileAction>): Observable<AttachmentsFileAction>;

  abstract openLink(action: Observable<{ id: number, options: OpenOptions }>): Observable<{
    id: number,
    options: OpenOptions
  } | any>;

  abstract openLinkSuccess(action: Observable<{ id: number, options: OpenOptions }>): Observable<{
    id: number,
    options: OpenOptions
  }>;

  abstract getKnowledgeArticle(action: Observable<AttachmentKnowledgeArticle>): Observable<AttachmentKnowledgeArticle | any>;

  abstract getKnowledgeArticleSuccess(action: Observable<AttachmentKnowledgeArticleResponse>): Observable<AttachmentKnowledgeArticleResponse>;

  getPath$ = createEffect(() => this.actions().pipe(
    ofType(AttachmentsActions.getFile),
    action => this.getFile(action)
  ));

  getPathSuccess$ = createEffect(() => this.actions().pipe(
    ofType(AttachmentsActions.getFileSuccess),
    action => this.getFileSuccess(action)
  ), {dispatch: false});

  openLink$ = createEffect(() => this.actions().pipe(
    ofType(AttachmentsActions.openLink),
    action => this.openLink(action)
  ));

  openLinkSuccess$ = createEffect(() => this.actions().pipe(
    ofType(AttachmentsActions.openLinkSuccess),
    action => this.openLinkSuccess(action)
  ), {dispatch: false});

  getKnowledgeArticle$ = createEffect(() => this.actions().pipe(
    ofType(AttachmentsActions.getKnowledgeArticle),
    action => this.getKnowledgeArticle(action)
  ));

  getKnowledgeArticleSuccess$ = createEffect(() => this.actions().pipe(
    ofType(AttachmentsActions.getKnowledgeArticleSuccess),
    action => this.getKnowledgeArticleSuccess(action)
  ), {dispatch: false});


}
