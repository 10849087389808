<ion-header [translucent]="true">
  <ion-toolbar color="primary">
    <ion-title>{{ 'LINK.ERROR.TITLE' | translate }}</ion-title>
    <ion-buttons slot="start">
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-item>
    <ion-label class="ion-text-center" [innerText]="'LINK.ERROR.MESSAGE' | translate"></ion-label>
  </ion-item>
</ion-content>
