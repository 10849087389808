/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CustomPropertyType {
    Base = '0',
    Text = 1,
    Multiline = 2,
    Numeric = 3,
    Email = 4,
    Dropdown = 5,
    Checkbox = 6,
    Date = 7,
    TimeOfDay = 8,
    Phone = 9,
    Signature = 10,
    Picture = 11,
    Info = 12,
    MultipleChoice = 13,
}
