import {Injectable} from "@angular/core";
import {IssueContentType, IssueType,} from "@server-models";
import {IssuesBaseMessageService} from "@shared/components/issues/services/issues-base-message.service";
import {IssuesBaseMissionService} from "@shared/components/issues/services/issues-base-mission.service";
import {IssuesBaseTicketService} from "@shared/components/issues/services/issues-base-ticket.service";
import {
  IssuesBaseWinterhalterServiceCallService
} from "@shared/components/issues/services/winterhalter/service-call.service";

@Injectable({
  providedIn: 'root',
})
export class IssuesBaseFactory {

  constructor(
    private _issueBaseMessageService: IssuesBaseMessageService,
    private _issueBaseMissionService: IssuesBaseMissionService,
    private _issueBaseTicketService: IssuesBaseTicketService,
    private _issueBaseWinterhalterServiceCallService: IssuesBaseWinterhalterServiceCallService
  ) {
  }

  getServiceByIssueType(issueType: IssueType | IssueContentType): IssuesBaseMessageService | IssuesBaseMissionService | IssuesBaseTicketService | IssuesBaseWinterhalterServiceCallService {
    switch (issueType) {
      case IssueType.Message:
      case IssueContentType.Message:
        return this._issueBaseMessageService;
      case IssueType.Mission:
      case IssueContentType.Mission:
        return this._issueBaseMissionService;
      case IssueType.Ticket:
      case IssueContentType.Ticket:
        return this._issueBaseTicketService;
      case IssueType.WinterhalterServiceCall:
      case IssueContentType.WinterhalterServiceCall:
        return this._issueBaseWinterhalterServiceCallService
      default:
        throw new Error(`Service, Unsupported issue type: ${issueType}`);
    }
  }
}
