<div class="ion-page" id="main-content">
  <ion-tabs *ngIf="(features$ | async) as features" slot="bottom">
    <ion-tab-bar *ngIf="(currentTabNavigation$ | async) as tabNavigation">
      <ion-tab-button *ngIf="features.isDashboardVisible" tab="home">
        <ion-icon aria-hidden="true" name="home-outline"></ion-icon>
        <ion-label>{{ 'LINK.HOME.TITLE' | translate }}</ion-label>
      </ion-tab-button>

      <ion-tab-button *ngIf="features.isKnowledgeVisible" [tab]="tabNavigation.knowledges">
        <ion-icon aria-hidden="true" name="help-outline"></ion-icon>
        <ion-label>{{ 'LINK.KNOWLEDGE.TITLE' | translate }}</ion-label>
      </ion-tab-button>

      <ion-tab-button *ngIf="features.isShowingProperties" tab="properties">
        <ion-icon aria-hidden="true" name="options-outline"></ion-icon>
        <ion-label>{{ 'LINK.PROPERTIES.TITLE' | translate }}</ion-label>
      </ion-tab-button>

      <ion-tab-button
        *ngIf="features.isIssueHistoryVisible || features.isIssueTemplateVisible"
        [tab]="tabNavigation.issues">
        <ion-icon aria-hidden="true" name="file-tray-full"></ion-icon>
        <ion-label [innerText]="'TEAM.TAB_NAVIGATION.ISSUES' | translate"></ion-label>
      </ion-tab-button>

    </ion-tab-bar>
  </ion-tabs>
</div>
