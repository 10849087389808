import {Component} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-login-message',
  templateUrl: './login-message.component.html',
  styleUrls: ['./login-message.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
  ],
  standalone: true
})
export class LoginMessageComponent {


}
