import {Injectable} from "@angular/core";
import {Observable, Observer} from "rxjs";
import {CameraService} from "@shared/services/camera.service";

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(
    private _cameraService: CameraService
  ) {
  }

  /**
   * @name resizeBlobImageObservable
   * @description
   * resize a blob image to be smaller
   * @memberof ImageService
   * @param cameraImage
   * @param maxWidth
   * @param maxHeight
   * @param contentType
   * @param quality
   */
  resizeBlobImageObservable(
    cameraImage: any,
    maxWidth: number = 1366,
    maxHeight: number = 1366,
    contentType = 'image/jpeg',
    quality = 0.9
  ): Observable<Blob> | null {
    const thisRef = this;
    return new Observable((observer: Observer<Blob>) => {

      const fileLoader = new FileReader();
      const canvas = document.createElement('canvas');
      const image = new Image();
      image.onabort = () => observer.error('ImageAbort');
      image.onerror = () => observer.error('ImageError');
      fileLoader.onabort = () => observer.error('FileAbort');
      fileLoader.onerror = () => observer.error('FileError');
      fileLoader.onload = function () {
        image.src = this.result!.toString();
      };

      const ObservablePhoto = this._cameraService.photoToBlobByWebPath(cameraImage);

      image.onload = function () {
        if (image.width <= 0 || image.height <= 0) {
          observer.error('ImageSizeError');
          return;
        }

        const context = canvas.getContext('2d');
        const newSize = thisRef._calculateAspectRatioFit(image.width, image.height, maxWidth, maxHeight);
        canvas.width = newSize.width;
        canvas.height = newSize.height;

        context!.clearRect(0, 0, canvas.width, canvas.height);
        context!.drawImage(image, 0, 0, image.width, image.height, 0, 0, newSize.width, newSize.height);

        const generatedBlob = thisRef._dataURItoBlob(canvas.toDataURL(contentType, quality));

        canvas.width = 0;
        canvas.height = 0;

        observer.next(generatedBlob);
        observer.complete();
      };

      ObservablePhoto.subscribe((photo) => {

        fileLoader?.readAsDataURL(photo!);
      })

      return () => {
      };
    });
  }

  /**
   * @name convertDataURLToBlobObservable
   * @description
   * return an observable blob by dataURL
   * @memberof ImageService
   * @param dataURL
   */
  convertDataURLToBlobObservable(dataURL: string): Observable<Blob | undefined> {
    return new Observable((observer: Observer<Blob | undefined>) => {
      try {
        const blob: Blob = this._dataURItoBlob(dataURL);
        observer.next(blob);
        observer.complete();
      } catch (error) {
        observer.error(error);
      }
    });
  }

  /**
   * @name _calculateAspectRatioFit
   * @description
   * calculate the aspect
   * @memberof ImageService
   * @param srcWidth
   * @param srcHeight
   * @param maxWidth
   * @param maxHeight
   * @private
   */
  private _calculateAspectRatioFit(srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number): {
    width: number,
    height: number
  } {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return {width: srcWidth * ratio, height: srcHeight * ratio};
  }

  /**
   * @name _dataURItoBlob
   * @description
   * return a blob by dataURI
   * @memberof ImageService
   * @param dataURI
   * @private
   */
  private _dataURItoBlob(dataURI: string): Blob {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = decodeURI(dataURI.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
  }

}
