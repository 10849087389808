import {createAction, props} from "@ngrx/store";
import {
  KnowledgeArticleDto,
  KnowledgeArticleSimpleDto, ResourceProfileErrorDto,
  ResourceProfileHintDto,
} from "@server-models";

export const getProfileDashboard = createAction(
  '[Link] Get Profile Dashboard'
);
export const getProfileDashboardSuccess = createAction(
  '[Link] Get Profile Dashboard Success',
  props<{ hints: ResourceProfileHintDto[], errors: ResourceProfileErrorDto[] }>()
);

export const getKnowledge = createAction(
  '[Link] Get Knowledge'
);

export const getKnowledgeArticle = createAction(
  '[Link] Get KnowledgeArticle',
  props<{ knowledgeArticleId: number }>()
);
export const getKnowledgeArticleSuccess = createAction(
  '[Link] Get KnowledgeArticle Success',
  props<{ knowledgeArticle: KnowledgeArticleDto }>()
);

export const getKnowledgeSuccess = createAction(
  '[Link] Get Knowledge Success',
  props<{ knowledgeArticles: Array<KnowledgeArticleSimpleDto> }>()
);
