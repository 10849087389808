/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum NotificationReceiverStatus {
    Unknown = '0',
    Delivered = 2,
    Blocked = 3,
    Bounced = 4,
    Dropped = 5,
}
