import {Injectable} from "@angular/core";
import {Actions} from "@ngrx/effects";
import {select, Store} from "@ngrx/store";
import {mergeMap, Observable, of, withLatestFrom} from "rxjs";
import {AttachmentsEffectsBase} from "@shared/components/attachments/store/attachments.effects";
import {FileService} from "@shared/services/file.service";
import {ViewerPdfComponent} from "@shared/components/viewers/viewer-pdf/viewer-pdf.component";
import {ModalControllerService} from "@shared/services/modal-controller.service";
import {selectAttachments} from "@shared/components/attachments/store/attachments.selectors";
import {catchError, map, tap} from "rxjs/operators";
import {AttachmentsActions} from "@shared/components/attachments/store/attachments.actions-type";
import {ViewerImageComponent} from "@shared/components/viewers/viewer-image/viewer-image.component";
import {AttachmentsFileAction} from "@shared/components/attachments/interfaces/attachments-file-action.interface";
import {
  AttachmentsFileRequestAction
} from "@shared/components/attachments/interfaces/attachments-file-request-action.interface";
import {CapacitorBrowserService} from "@shared/services/capacitor/browser/capacitor-browser.service";
import {OpenOptions} from "@capacitor/browser/dist/esm/definitions";
import {TeamKnowledgeService} from "@team/pages/knowledge/services/team-knowledge.service";
import {
  ViewerKnowledgeArticleComponent
} from "@shared/components/viewers/viewer-knowledge-article/viewer-knowledge-article.component";
import {
  AttachmentKnowledgeArticle
} from "@shared/components/attachments/interfaces/attachment-knowledge-article.interface";
import {
  AttachmentKnowledgeArticleResponse
} from "@shared/components/attachments/interfaces/attachment-knowledge-article-response.interface";

@Injectable({
  providedIn: 'root'
})
export class AttachmentsApiEffects extends AttachmentsEffectsBase {

  constructor(
    actions$: Actions,
    store: Store,
    private _fileService: FileService,
    private _modalControllerService: ModalControllerService,
    private _cBrowser: CapacitorBrowserService,
    private _teamKnowledgeService: TeamKnowledgeService
  ) {
    super(store, actions$);
  }

  /**
   * @name getFile
   * @description
   * trigger get file action and forward to requestFile
   * @memberof AttachmentsApiEffects
   * @param action
   * @returns {Observable<AttachmentsFileRequestAction>}
   */
  getFile(action: Observable<AttachmentsFileRequestAction>): Observable<AttachmentsFileRequestAction> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(selectAttachments))),
      mergeMap(([action]) => this._requestFile(action))
    );
  }

  /**
   * @name _requestFile
   * @description
   * Request to the file service the file
   * @memberof AttachmentsApiEffects
   * @param action
   * @private
   * @returns {Observable<AttachmentsFileRequestAction | any>}
   */
  private _requestFile(action: AttachmentsFileRequestAction): Observable<AttachmentsFileRequestAction | any> {
    return this._fileService.get(action.path).pipe(
      map((data) => AttachmentsActions.getFileSuccess({
        file: data,
        name: action.name,
        id: action.id,
        mineType: action.mineType
      })),
      catchError((error) => of(AttachmentsActions.getFileFail({error}))
      )
    );
  }

  /**
   * @name getFileSuccess
   * @description
   * trigger file success action and check the file type to open the correct modal
   * @memberof AttachmentsApiEffects
   * @param action
   * @returns {Observable<AttachmentsFileAction>}
   */
  getFileSuccess(action: Observable<AttachmentsFileAction>): Observable<AttachmentsFileAction> {
    return action.pipe(
      tap((action) => {
        const finalType = action.mineType ? action.mineType : action.file.type;

        switch (finalType) {
          case 'application/pdf':
            this._openDocumentViewer(ViewerPdfComponent, action.file, action.name)
            break;
          case 'image/png':
          case 'image/jpg':
          case 'image/jpeg':
            this._openDocumentViewer(ViewerImageComponent, action.file, action.name)
            break;
        }
      })
    )
  }

  /**
   * @name _openDocumentViewer
   * @description
   * Call the modal controller service to open the modal
   * @memberof AttachmentsApiEffects
   * @param component
   * @param blobFile
   * @param name
   * @private
   */
  private _openDocumentViewer(component: any, blobFile: Blob, name: string): void {
    this._modalControllerService.showModal(component, 'modal-fullscreen', {
      file: blobFile,
      name
    });
  }

  /**
   * @name openLink
   * @description
   * trigger the open link action and forward to success or fail action
   * @memberof AttachmentsApiEffects
   * @param action
   * @returns {Observable<{id: number, options: OpenOptions} | any>}
   */
  openLink(action: Observable<{ id: number, options: OpenOptions }>): Observable<{
    id: number,
    options: OpenOptions
  } | any> {
    return action.pipe(
      map(action => AttachmentsActions.openLinkSuccess(action)),
      catchError((error) => of(AttachmentsActions.openLinkFail({error})))
    );
  }

  /**
   * @name openLinkSuccess
   * @description
   * trigger the open link success action and launch the open browser action
   * @memberof AttachmentsApiEffects
   * @param action
   * @returns {Observable<OpenOptions>}
   */
  openLinkSuccess(action: Observable<{ id: number, options: OpenOptions }>): Observable<{
    id: number,
    options: OpenOptions
  }> {
    return action.pipe(
      tap((action) => {
        this._cBrowser.open(action.options)
      })
    )
  }

  /**
   * @name getKnowledgeArticle
   * @description
   * trigger get KnowledgeArticle action and forward to requestKnowledgeArticle
   * @memberof
   * @param action
   * @returns {Observable<AttachmentKnowledgeArticle>}
   */
  getKnowledgeArticle(action: Observable<AttachmentKnowledgeArticle>): Observable<AttachmentKnowledgeArticle> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(selectAttachments))),
      mergeMap(([action]) => this._requestKnowledgeArticle(action))
    );
  }

  /**
   * @name _requestKnowledgeArticle
   * @description
   * Request to the teamKnowledge service the Article
   * @memberof
   * @param action
   * @private
   * @returns {Observable<AttachmentKnowledgeArticle>}
   */
  private _requestKnowledgeArticle(action: AttachmentKnowledgeArticle): Observable<AttachmentKnowledgeArticle | any> {
    return this._teamKnowledgeService.getArticle(action.referenceId!).pipe(
      map((data) => AttachmentsActions.getKnowledgeArticleSuccess({id: action.id, data: data})
      ),
      catchError((error) => of(AttachmentsActions.getKnowledgeArticleFail({error}))
      )
    );
  }

  /**
   * @name getKnowledgeArticleSuccess
   * @description
   * trigger knowledge article success action to open the modal
   * @memberof
   * @param action
   * @returns {Observable<AttachmentKnowledgeArticleResponse>}
   */
  getKnowledgeArticleSuccess(action: Observable<AttachmentKnowledgeArticleResponse>): Observable<AttachmentKnowledgeArticleResponse> {
    return action.pipe(
      tap((action) => {
        this._modalControllerService.showModal(ViewerKnowledgeArticleComponent, 'modal-fullscreen', {
          name: action.data.title,
          htmlContent: action.data.content,
          attachmentsInc: action.data.attachments
        });
      })
    );
  }
}
