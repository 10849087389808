/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ChatMemberType {
    Base = '0',
    Location = 1,
    Contact = 2,
    Function = 3,
    External = 4,
    Ticket = 5,
}
