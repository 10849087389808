import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule, NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule,
    ReactiveFormsModule, TranslateModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: InputDateComponent
    }
  ]
})
export class InputDateComponent implements OnChanges, ControlValueAccessor {

  @Input() label: string = '';
  @Input() isRequired: boolean;
  @Output() valueChange = new EventEmitter<string>();
  @Input() formControl: FormControl;
  @Input() idDate: number;
  @Input() minDate!: string;
  @Input() maxDate!: string;

  value: string;
  min?: string;
  max?: string;

  onChange: any = () => {};
  onTouch: any = () => {};

  constructor() {
    this.formControl = new FormControl();
    this.value = "";
    this.isRequired = false;
    this.idDate = NaN;
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnChanges() {
    this.min = this.minDate;
    this.max = this.maxDate;
  }

  getValue() {
    return this.formControl.value
  }
}
