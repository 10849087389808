import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from "@ngx-translate/core";
import {CustomPropertyType} from "@server-models";
import {ReadInputTextComponent} from "@shared/components/custom-property-read/inputs/text/read-input-text.component";
import {ReadInputNumericComponent} from "@shared/components/custom-property-read/inputs/number/read-input-numeric.component";
import {ReadInputPictureComponent} from "@shared/components/custom-property-read/inputs/picture/read-input-picture.component";
import {ReadInputSignatureComponent} from "@shared/components/custom-property-read/inputs/signature/read-input-signature.component";
import {ReadInputTimeOfDayComponent} from "@shared/components/custom-property-read/inputs/timeOfDay/read-input-time-of-day.component";
import {ReadInputDateComponent} from "@shared/components/custom-property-read/inputs/date/read-input-date.component";
import {ReadInputInfoComponent} from "@shared/components/custom-property-read/inputs/info/read-input-info.component";
import {
  ReadInputCheckboxComponent
} from "@shared/components/custom-property-read/inputs/checkbox/read-input-checkbox.component";
import {IssueFormatted} from "@shared/components/issues/interfaces/issue-formatted.interface";

@Component({
  selector: 'app-custom-property-read',
  templateUrl: './custom-property-read.component.html',
  styleUrls: ['./custom-property-read.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    ReadInputTextComponent,
    ReadInputNumericComponent,
    ReadInputPictureComponent,
    ReadInputSignatureComponent,
    ReadInputTimeOfDayComponent,
    ReadInputDateComponent,
    ReadInputInfoComponent,
    ReadInputCheckboxComponent,
  ]
})
export class CustomPropertyReadComponent implements OnInit {

  @Input() inputProperty!: IssueFormatted;


  protected readonly CustomPropertyType = CustomPropertyType;

  constructor() {

  }

  ngOnInit() {
  }


}
