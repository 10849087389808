/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum UserTypes {
    Device = 1,
    Contact = 4,
    Resource = 8,
    Guest = 16,
}
