/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum NoteType {
    Text = '0',
    Mail = 1,
    Caller = 2,
    ChangedStatus = 4,
    EscalatedByEvent = 5,
    EscalatedByUser = 6,
    CanceledEscalation = 7,
    ChangedStatusOfMission = 8,
    ChatMessage = 9,
    ChangedResource = 10,
    FinalizedMission = 11,
    Base = -1,
}
