import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {SafePipe} from "@shared/pipes/safe.pipe";
import {UserHtmlComponent} from "@features/user-html/user-html.component";
import {AttachmentsComponent} from "@shared/components/attachments/attachments.component";
import {RouterLink} from "@angular/router";
import {CustomPropertyComponent} from "@shared/components/custom-property/custom-property.component";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {InputTextComponent} from "@shared/components/custom-property/inputs/text/input-text.component";
import {
  CustomPropertyDto,
  CustomSetReferenceDto,
} from "@server-models";
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {IssuesBaseService} from "@shared/components/issues/services/issues-base.service";

@Component({
  selector: 'app-issue-custom-form-property',
  templateUrl: './issue-custom-property-form.component.html',
  styleUrls: ['./issue-custom-property-form.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    SafePipe,
    UserHtmlComponent,
    AttachmentsComponent,
    RouterLink,
    InputTextComponent,
    ReactiveFormsModule,
    CustomPropertyComponent
  ]
})
export class IssueCustomPropertyFormComponent {
  @Input() customPropertySets: CustomSetReferenceDto[]
  @Output() inputChange = new EventEmitter<FormGroup>();
  inputsProperties: CustomPropertyDto[];
  propertyForm: FormGroup;
  isLoading$!: Observable<boolean>;

  constructor(
    protected _store: Store,
    protected _fb: FormBuilder,
    protected _issuesBaseService: IssuesBaseService,
    protected _translate: TranslateService
  ) {
    this.customPropertySets = [];
    this.propertyForm = this._fb.group({});
    this.inputsProperties = [];
  }

  getProperty(groupIndex: number, controlName: string): CustomPropertyDto {
    const propertySet = this.customPropertySets[groupIndex];
    return propertySet!.customPropertySet?.properties!.find(
      (prop: any) => prop.technicalName! + prop.customPropertyId! === controlName
    )!;
  }

  getPropertyName(groupIndex: number): string {
    let propertySet = this.customPropertySets[groupIndex];

    return propertySet!.customPropertySet?.name!;

  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['customPropertySets']) {
      this.propertyForm = this._issuesBaseService.buildFormGroupsCustomProperties(this.customPropertySets);

      this.formModified();
    }
  }

  getGroupNames(): string[] {
    return Object.keys(this.propertyForm.controls);
  }

  getControlNames(groupName: string): string[] {
    const group = this.propertyForm.get(groupName) as FormGroup;
    return Object.keys(group.controls);
  }

  getControlFromGroup(groupName: string, controlName: string): FormControl {
    const group = this.propertyForm.get(groupName) as FormGroup;
    return group.get(controlName) as FormControl;
  }

  formModified(): void {
    this.inputChange.emit(this.propertyForm);
  }
}
