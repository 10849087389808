/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ResourceType {
    Device = '0',
    VirtualGroup = 1,
    Virtual = 2,
    Group = 3,
}
