<ion-list class="ion-no-padding">
  <ion-item class="ion-no-padding">
    <ion-input
      [label]="isRequired ? label + ' *': label"
      labelPlacement="stacked"
      [pattern]="phonePattern"
      type="text"
      [formControl]="formControl"
    ></ion-input>
  </ion-item>
  <div class="input-error" *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
    <p *ngIf="formControl.errors['required']">{{ 'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.REQUIRED' | translate }}</p>
    <p *ngIf="formControl.errors['pattern']"> {{ 'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.PATTERN.PHONE' | translate }}</p>
  </div>
</ion-list>
