import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Router, UrlTree} from "@angular/router";
import {Observable, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {LinkLoginSelectors} from "@link/pages/login/store/link-login.selector-type";

@Injectable({
  providedIn: 'root'
})
export class LinkPropertiesGuard {

  constructor(private _store: Store, private _router: Router) {
  }

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    return this._store.pipe(
      withLatestFrom(this._store.select(LinkLoginSelectors.selectFeatures)),
      map(([_, features]) => {
        if (features.isShowingProperties) {
          return true
        } else {
          return this._router.createUrlTree([`link/error`]);
        }
      })
    );
  }
}
