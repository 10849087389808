import {createAction, props} from "@ngrx/store";
import {
  IssueListDto,
  IssueTemplateListDto,
  MessageIssueDto,
  MissionIssueDto,
  StereotypeDto,
  StereotypeListDto, TicketIssueDto
} from "@server-models";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";

export const getById = createAction(
  '[Issues-Link] Get By Id Start',
  props<{ id: number }>()
);

export const getByIdSuccess = createAction(
  '[Issues-Link] Get By Id Success',
  props<{data: MessageIssueDto | MissionIssueDto | TicketIssueDto}>()
);
export const getByIdFail = createAction(
  '[Issues-Link] Get By Id Fail',
  props<{ error: Error }>()
);

export const getItemsPaginatedRefresh = createAction(
  '[Issues-Link] Get Paginated Refresh',
  props<{data: OrgaResponse<IssueListDto[]>}>()
);

export const getItemsPaginatedCancel = createAction(
  '[Issues-Link] Get Paginated Cancel'
);

export const getItemsPaginatedFail = createAction(
  '[Issues-Link] Get Paginated Fail',
  props<{ error: Error }>()
);

export const getItemsTemplate = createAction(
  '[Issues-Link] Get Template Start'
);

export const getItemsTemplateSuccess = createAction(
  '[Issues-Link] Get Template Success',
  props<{ data: OrgaResponse<IssueTemplateListDto[]> }>()
);
export const getItemsTemplateFail = createAction(
  '[Issues-Link] Get Template Fail',
  props<{ error: Error }>()
);

export const sendTemplatePreview = createAction(
  '[Issues-Link] Send Template Preview Start',
  props<any>()
);

export const sendTemplatePreviewSuccess = createAction(
  '[Issues-Link] Send Template Preview Success',
  props<any>()
);

export const sendTemplatePreviewFail = createAction(
  '[Issues-Link] Send Template Preview Fail',
  props<{ error: Error }>()
);

export const getStereotypeById = createAction(
  '[Issues-Link] Get Stereotype By Id Start',
  props<{ stereotypeId: number, issueId: number, cacheGuid: string }>()
);

export const getStereotypeByIdSuccess = createAction(
  '[Issues-Link] Get Stereotype By Id Success',
  props<{stereotype: StereotypeDto, cacheGuid: string}>()
);

export const getStereotypeByIdFail = createAction(
  '[Issues-Link] Get Stereotype By Id Fail',
  props<{ error: Error }>()
);

export const getStereotypeByEntityType = createAction(
  '[Issues-Link] Get Stereotype By EntityType Start',
  props<{ entityType: any, cacheControl: string }>()
);

export const getStereotypeByEntityTypeSuccess = createAction(
  '[Issues-Link] Get Stereotype By EntityType Success',
  props<{ data: OrgaResponse<StereotypeListDto> }>()
);

export const getStereotypeByEntityTypeFail = createAction(
  '[Issues-Link] Get Stereotype By EntityType Fail',
  props<{ error: Error }>()
);

export const navigateToNewIssue = createAction(
  '[Issues-Link] Navigate to new'
);

export const navigateToIssues = createAction(
  '[Issues-Link] Navigate to Issues'
);

export const saveSignature = createAction(
  '[Issues-Link] Save signature',
  props<{blob: Blob}>()
);

export const saveSignatureSuccess = createAction(
  '[Issues-Link] Save signature Success',
  props<{signatureId: number}>()
);

export const saveSignatureFail = createAction(
  '[Issues-Link] Save signature Fail',
  props<{error: Error}>()
);


