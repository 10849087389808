import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from "@ngx-translate/core";
import {ClockService} from "@core/services/clock.service";

@Component({
  selector: 'app-read-input-date',
  templateUrl: './read-input-date.component.html',
  styleUrls: ['./read-input-date.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule]
})
export class ReadInputDateComponent implements OnInit {

  @Input() title: string;
  @Input() value: string;

  formattedDate: string;

  constructor(private _clockService: ClockService) {
    this.title = "";
    this.value = "";
    this.formattedDate = "";
  }

  ngOnInit() {
    this.formattedDate = this.value ? this._clockService.format(this._clockService.parse(this.value), false) : "";
  }
}
