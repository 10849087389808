import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {Router, UrlTree} from "@angular/router";
import {Observable, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {LinkIssuesSelectors} from "@link/pages/issues/store/link-issues.selector-type";
import {LinkLoginSelectors} from "@link/pages/login/store/link-login.selector-type";

@Injectable({
  providedIn: 'root'
})
export class LinkIssueReadGuard {

  constructor(private _store: Store, private _router: Router) {
  }

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._store.pipe(
      withLatestFrom(this._store.select(LinkIssuesSelectors.selectDetailDataFormatted)),
      withLatestFrom(this._store.pipe(select(LinkLoginSelectors.selectLinkId))),
      map(([[_, formattedData], linkId]) => {

        if (Object.keys(formattedData).length === 0) {
          return this._router.createUrlTree([`link/${linkId}/issues`]);
        } else {
          return true;
        }
      })
    );
  }
}
