import {Injectable} from "@angular/core";
import {Browser, OpenOptions} from '@capacitor/browser';
import {from, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CapacitorBrowserService {

  constructor() {
  }

  open(options: OpenOptions): Observable<void> {
    return from(this._open(options));
  }

  private async _open(options: OpenOptions): Promise<void> {
    await Browser.open(options);
  }

  close(): Observable<void> {
    return from(this._close());
  }

  private async _close(): Promise<any> {
    await Browser.close()
  }

}
