/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum MailRelationTypes {
    From = 1,
    To = 2,
    Cc = 3,
    Bcc = 4,
}
