<ion-header [translucent]="true">
  <ion-toolbar color="primary">
    <div class="container">
      <ion-buttons>
        <ion-button (click)="back()">
          <ion-icon slot="start" name="arrow-back-sharp"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="thank-you-container">
    <ion-icon color="success" name="checkmark-circle-outline" class="thank-you-icon"></ion-icon>
    <ion-text>
      <h2>{{ 'SUCCESS_PAGE.BASE_ISSUES.THANKS.TITLE' | translate}}</h2>
      <p>{{ 'SUCCESS_PAGE.BASE_ISSUES.THANKS.SUCCESS_MESSAGE' | translate}}</p>
    </ion-text>
  </div>
</ion-content>
