import {Actions} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export abstract class EffectsBase {
  protected actions(): Actions {
    return this.rawActions$;
  }

  protected getStore() {
    return this.store;
  }

  protected constructor(
    protected store: Store,
    protected rawActions$: Actions,
  ) {
  }
}
