/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AppEntityType {
    None = '0',
    Ticket = 1,
    Resource = 3,
    Address = 4,
    Company = 5,
    Contact = 6,
    Function = 7,
    Location = 8,
    Stereotype = 9,
    Role = 10,
    LocationGroup = 11,
    Setting = 12,
    User = 14,
    Info = 15,
    KnowledgeArticle = 16,
    Form = 17,
    Task = 18,
    TaskJob = 19,
    Mission = 20,
    Contract = 21,
    Article = 22,
    MissionReport = 23,
    ArticleKind = 24,
    MissionInspectionReport = 25,
    ControlTag = 26,
    FilledForm = 27,
    DocumentTemplate = 28,
    AppOverview = 29,
    InventoryNumberPattern = 30,
    PrioritySet = 31,
    State = 32,
    StateMachine = 33,
    Template = 34,
    FormHistory = 35,
    Dashboard = 36,
    FolderAccess = 37,
    Device = 38,
    ChatByFormDefinition = 39,
    TicketByProcessDefinition = 40,
    TimelineSetting = 41,
    KnowledgePath = 42,
    IssueTemplate = 43,
    Issue = 44,
    KnowledgeExam = 45,
}
