import {Injectable} from "@angular/core";
import {Actions} from "@ngrx/effects";
import {select, Store} from "@ngrx/store";
import {exhaustMap, mergeMap, Observable, withLatestFrom} from "rxjs";
import {catchError} from "rxjs/operators";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {Pagination} from "@shared/interfaces/pagination.interface";
import {
  FileDto,
  IssueContentType,
  IssueListDto,
  IssueTemplateListDto,
  IssueType,
  MessageIssueDto,
  MessageIssuePreviewDto,
  MissionIssueDto,
  MissionIssuePreviewDto,
  StereotypeDto,
  StereotypeListDto, SubmitMessageIssueDto, SubmitMissionIssueDto, SubmitTicketIssueDto,
  TicketIssueDto,
  TicketIssuePreviewDto
} from "@server-models";
import {Router} from "@angular/router";
import {ImageService} from "@shared/services/image.service";
import {CameraService} from "@shared/services/camera.service";
import {LoginBaseSelectors} from "@shared/stores/login-base/store/login-base.selector-type";
import {Photo} from "@capacitor/camera";
import {TypedAction} from "@ngrx/store/src/models";
import {ModalLoaderService} from "@shared/services/modal-loader.service";
import {TranslateService} from "@ngx-translate/core";
import {IssuesRequestPagination} from "@shared/components/issues/interfaces/issues-request-pagination.interface";
import {IssueFormattedDetailData} from "@shared/components/issues/interfaces/issue-formatted-detail-data.interface";
import {IssuesBaseService} from "@shared/components/issues/services/issues-base.service";
import {BaseIssuesEffectsBase} from "@shared/components/issues/store/base-issues.effets";
import {BaseIssuesActions} from "@shared/components/issues/store/base-issues.action-type";
import {BaseIssuesSelectors} from "@shared/components/issues/store/base-issues.selectors-type";
import {
  GetIssueType,
  TemplatePreviewIssueType
} from "@shared/components/issues/store/base-issues.actions";

import {IssuesBaseFactory} from "@shared/components/issues/services/issues-base.factory";
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export abstract class BaseIssuesApiEffects extends BaseIssuesEffectsBase {

  protected constructor(
    actions$: Actions,
    store: Store,
    protected _cameraService: CameraService,
    protected _modalLoaderService: ModalLoaderService,
    protected _translateService: TranslateService,
    protected _imageService: ImageService,
    protected _issuesBaseService: IssuesBaseService,
    protected _issuesBaseFactory: IssuesBaseFactory,
    protected _router: Router
  ) {
    super(store, actions$);
  }

  /**
   * @name _requestGetPaginated
   * @description
   * request to issue service the list of issues
   * @memberof BaseIssuesApiEffects
   * @param action
   * @protected
   * @returns {Observable<(OrgaResponse<IssueListDto[]> | any)>}
   */
  abstract _requestGetPaginated(action: IssuesRequestPagination): Observable<(OrgaResponse<IssueListDto[]> | any)>;

  /**
   * @name _requestGetTemplate
   * @description
   * request to get list of template
   * @memberof BaseIssuesApiEffects
   * @protected
   * @returns {Observable<(OrgaResponse<IssueTemplateListDto[]> | any)>}
   */
  abstract _requestGetTemplate(): Observable<(OrgaResponse<IssueTemplateListDto[]> | any)>;

  abstract _requestGetTemplatePreviewByIssueType(action: TemplatePreviewIssueType): Observable<(unknown | any)>;

  abstract _requestByIdByIssueType(action: GetIssueType): Observable<MessageIssueDto | MissionIssueDto | TicketIssueDto>

  abstract requestItemsResource(action: any): Observable<any>;

  abstract _sendTemplatePreviewByIssueType(action: {
    templateId: number,
    issuePrepared: SubmitMessageIssueDto | SubmitMissionIssueDto | SubmitTicketIssueDto
    issueContentType: IssueContentType
  }): Observable<any>;

  abstract _requestGetStereotypeById(action: { stereotypeId: number, cacheGuid: string }): Observable<{
    stereotypeId: number,
    cacheGuid: string
  } | any>;

  abstract _requestGetStereotypeByEntityType(entityType: string, cacheControl: string): Observable<OrgaResponse<StereotypeListDto> | any>;

  /**
   * @name _savePictureRequest
   * @description
   * make request throw file api service to upload picture
   * @memberof BaseIssuesApiEffects
   * @param tenantId
   * @param blob
   * @param fileType
   * @param pictureId
   * @protected
   */
  abstract _savePictureRequest(tenantId: number, blob: Blob, fileType: string, pictureId: number): Observable<{
    image: FileDto
  } | any>;

  /**
   * @name _saveSignatureRequest
   * @description
   * make request throw file api service to upload signature
   * @memberof BaseIssuesApiEffects
   * @param tenantId
   * @param blob
   * @param fileType
   * @protected
   */
  abstract _saveSignatureRequest(tenantId: number, blob: Blob, fileType: string): Observable<{
    signature: FileDto
  } | any>;

  protected _checkPaginationState(action: IssuesRequestPagination, state: Pagination): boolean {
    return !state || action.params.pageNumber <= state.totalPages
  }

  getPaginated(action: Observable<IssuesRequestPagination>): Observable<(OrgaResponse<IssueListDto[]> | any)> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(BaseIssuesSelectors.selectPagePagination))),
      mergeMap(([action, paginationState]) => {

          if (action.refresh) {
            return this._requestGetPaginated(action);
          } else if (this._checkPaginationState(action, paginationState)) {
            return this._requestGetPaginated(action);
          } else {
            return [BaseIssuesActions.getItemsPaginatedCancel()];
          }
        }
      )
    );
  }


  getTemplate(action: Observable<TypedAction<string>>): Observable<(OrgaResponse<IssueTemplateListDto[]> | any)> {
    return action.pipe(
      mergeMap(() =>
        this._requestGetTemplate())
    );
  }

  getItemsTemplatePreviewByIssueType(action: Observable<TemplatePreviewIssueType>): Observable<(unknown | any)> {
    return action.pipe(
      mergeMap((action) => {
          return this._requestGetTemplatePreviewByIssueType(action)
        }
      )
    );
  }

  getItemsResource(action: Observable<any>): Observable<any> {
    return action.pipe(
      mergeMap((action) => {
        return this.requestItemsResource(action)
      })
    )
  }

  getItemsResourceSuccess(action: any): Observable<any> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(BaseIssuesSelectors.selectSelectedTemplateId))),
      withLatestFrom(this.store.pipe(select(BaseIssuesSelectors.selectSelectedTemplateIssueType))),
      exhaustMap(([[_, issueTemplateId], templateIssueContentType]) => {
          return [BaseIssuesActions.navigateToTemplatePreview({
            templateId: issueTemplateId!,
            templateIssueType: templateIssueContentType!
          })]
        }
      )
    );
  }

  getItemsTemplatePreviewSuccess(action: Observable<{
    data: MessageIssuePreviewDto | MissionIssuePreviewDto | TicketIssuePreviewDto
  }>): Observable<(unknown | any)> {
    return action.pipe(
      exhaustMap((action) => {
          return [BaseIssuesActions.getItemsResource({})]
        }
      )
    );
  }

  getById(action: Observable<GetIssueType>): Observable<(unknown | any)> {
    return action.pipe(
      mergeMap((action) =>
        this._requestByIdByIssueType(action))
    );
  }

  getByIdSuccess(action: Observable<{
    data: MessageIssueDto | MissionIssueDto | TicketIssueDto
  }>): Observable<TypedAction<string>> {
    return action.pipe(
      mergeMap(({data}) => {
        return [BaseIssuesActions.getStereotypeById({
          issueId: data.issueId!,
          stereotypeId: data.stereotypeId!
        })]


      })
    )
  }

  formatReadDetailData(action: Observable<TypedAction<string>>): Observable<{
    detailDataFormatted: IssueFormattedDetailData
  }> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(BaseIssuesSelectors.selectSelectedIssueItem))),
      withLatestFrom(this.store.pipe(select(BaseIssuesSelectors.selectSelectedStereotype))),
      withLatestFrom(this.store.pipe(select(BaseIssuesSelectors.selectSelectedIssueIssueType))),
      exhaustMap(([[[_, issueDto], stereotypeDto], issueType]) => {
        const service = this._issuesBaseFactory.getServiceByIssueType(issueType);
        const detailDataFormatted = service.formatReadDetailData(issueDto, stereotypeDto, issueType);
        return [BaseIssuesActions.formatReadDetailDataFinish({detailDataFormatted})]
      })
    )
  }

  formatReadDetailDataFinish(action: Observable<{ detailDataFormatted: IssueFormattedDetailData }>): Observable<any> {
    return action;
  }

  prepareFormsToSendTemplatePreview(action: Observable<{ form: FormGroup, templatePreviewDto: MessageIssuePreviewDto | MissionIssuePreviewDto | TicketIssuePreviewDto }>): Observable<any> {
    return action.pipe(
      withLatestFrom(this.store.pipe((select(BaseIssuesSelectors.selectSelectedTemplateIssueType)))),
      mergeMap(([{form, templatePreviewDto}, templateIssueType]) => {
        const service = this._issuesBaseFactory.getServiceByIssueType(templateIssueType);
        const submitReady = service.prepareFormIssueDto(form, templatePreviewDto);
        return [BaseIssuesActions.prepareFormsToSendTemplatePreviewDone({submitReady})]
      })
    );
  }

  prepareFormsToSendTemplatePreviewDone(action: Observable<{ submitReady: SubmitMessageIssueDto | SubmitMissionIssueDto | SubmitTicketIssueDto }>): Observable<any> {
    return action.pipe(
      withLatestFrom(this.store.pipe((select(BaseIssuesSelectors.selectSelectedTemplateIssueType)))),
      mergeMap(([{submitReady}, templateIssueType]) => {
        return [BaseIssuesActions.sendTemplatePreview({issuePrepared: submitReady})]
      })
    );
  }

  sendTemplatePreview(action: Observable<{ issuePrepared: SubmitMessageIssueDto | SubmitMissionIssueDto | SubmitTicketIssueDto }>): Observable<void> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(BaseIssuesSelectors.selectSelectedTemplateId))),
      withLatestFrom(this.store.pipe((select(BaseIssuesSelectors.selectSelectedTemplateIssueType)))),
      mergeMap(([[{issuePrepared}, templateId], issueContentType]) => {
        return this._sendTemplatePreviewByIssueType({
          templateId,
          issuePrepared,
          issueContentType
        });
      })
    );
  }

  sendTemplatePreviewSuccess(action: Observable<any>): Observable<any> {
    return action;
  }

  thanksPageNavigation(action: Observable<any>): Observable<any> {
    return action.pipe(
      exhaustMap(() => {
        return [BaseIssuesActions.thanksPageNavigationDone()];
      })
    );
  }

  thanksPageNavigationBack(action: Observable<any>): Observable<any> {
    return action.pipe(
      exhaustMap(() => {
        return [BaseIssuesActions.thanksPageNavigationDone()];
      })
    );
  }

  getStereotypeById(action: Observable<{ stereotypeId: number, issueId: number, cacheGuid: string }>): Observable<{
    stereotypeId: number,
    cacheGuid: string
  } | any> {
    return action.pipe(
      mergeMap((stereotype) =>
        this._requestGetStereotypeById(stereotype)
      )
    );
  }

  getStereotypeByIdSuccess(action: Observable<{
    stereotype: StereotypeDto,
    cacheGuid: string
  }>): Observable<OrgaResponse<StereotypeListDto> | any> {
    return action.pipe(
      mergeMap((action) => {
        return [BaseIssuesActions.getStereotypeByEntityType({
          entityType: action.stereotype.entityType,
          cacheControl: action.cacheGuid
        })]
      })
    );
  }

  getStereotypeByEntityType(action: Observable<{
    entityType: string,
    cacheControl: string
  }>): Observable<OrgaResponse<StereotypeListDto> | any> {
    return action.pipe(
      mergeMap((data) => {
          return this._requestGetStereotypeByEntityType(data.entityType, data.cacheControl)

        }
      )
    );
  }

  getStereotypeByEntityTypeSuccess(action: Observable<{ data: OrgaResponse<StereotypeListDto> }>): Observable<any> {
    return action.pipe(
      mergeMap(() => {
        return [BaseIssuesActions.formatReadDetailData()]
      })
    );
  }


  navigateToDetail(action: Observable<{
    issueId: number,
    issueType: IssueType,
    cacheGuid: string
  }>): Observable<(MessageIssueDto | MissionIssueDto | TicketIssueDto) | any> {
    return action.pipe(
      exhaustMap(data => {
        return [BaseIssuesActions.getIssueByIdByIssueType({id: data.issueId, issueType: data.issueType})]
      })
    );
  }

  getIssueByIdByIssueType(action: Observable<any>): Observable<any> {
    return action.pipe(
      mergeMap((action) => this._requestByIdByIssueType(action))
    );
  }

  askPictureInput(action: Observable<{ isLoadingId: number, pictureId: number }>): Observable<{
    picture: Photo
  } | any> {
    return action.pipe(
      exhaustMap((action) => {
        return this._cameraService.getPhoto().pipe(
          mergeMap((photo) => {
            return [BaseIssuesActions.askPictureInputSuccess({picture: photo!, pictureId: action.pictureId})]
          }),
          catchError((error) => [BaseIssuesActions.askPictureInputFail({error})])
        )

      })
    );
  }

  askPictureInputSuccess(action: Observable<{ picture: Photo, pictureId: number }>): Observable<{
    picture: Photo
  } | any> {
    return action.pipe(
      exhaustMap((data) => {
        return [BaseIssuesActions.reducePicture({picture: data.picture, pictureId: data.pictureId})]
      })
    )
  }

  reducePicture(action: Observable<{ picture: Photo, pictureId: number }>): Observable<{ blob: Blob } | any> {
    return action.pipe(
      mergeMap((data) => {
        return this._imageService.resizeBlobImageObservable(data.picture)!.pipe(
          mergeMap((newData) => {
            return [BaseIssuesActions.reducePictureSuccess({blob: newData, pictureId: data.pictureId})]
          }),
          catchError((error) => [BaseIssuesActions.reducePictureError({error})])
        )
      })
    )
  }

  reducePictureSuccess(action: Observable<{ blob: Blob, pictureId: number }>): Observable<{
    blob: Blob,
    fileType: string
  }> {
    return action.pipe(
      exhaustMap((data) => {
        return [BaseIssuesActions.savePicture({blob: data.blob, fileType: data.blob.type, pictureId: data.pictureId})]
      })
    )
  }

  savePicture(action: Observable<{ blob: Blob, fileType: string, pictureId: number }>): Observable<{
    picture: FileDto
  } | any> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(BaseIssuesSelectors.selectSelectedPictureAsBlob))),
      withLatestFrom(this.store.pipe(select(LoginBaseSelectors.selectTenantId))),
      exhaustMap(([[action, selectedBlob], tenantId]) => {
        return this._savePictureRequest(tenantId!, selectedBlob, selectedBlob.type, action.pictureId)
      })
    );
  }

  convertDateURLToBlob(action: Observable<{ dataUrl: string }>): Observable<any> {
    return action.pipe(
      mergeMap((data) => {
        return this._imageService.convertDataURLToBlobObservable(data.dataUrl).pipe(
          mergeMap((blob) => {
            return [BaseIssuesActions.convertDateURLToBlobSuccess({blob: blob!})]
          }),
          catchError((error) => [BaseIssuesActions.convertDateURLToBlobFail({error})])
        )
      })
    );
  }

  convertDateURLToBlobSuccess(action: Observable<{ blob: Blob }>): Observable<any> {
    return action.pipe(
      mergeMap((data) => [BaseIssuesActions.saveSignature({blob: data.blob})])
    )
  }

  saveSignature(action: Observable<{ blob: Blob }>): Observable<{ image: FileDto } | any> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(BaseIssuesSelectors.selectSelectedSignatureAsBlob))),
      withLatestFrom(this.store.pipe(select(LoginBaseSelectors.selectTenantId))),
      exhaustMap(([[_, selectedBlob], tenantId]) => {
        return this._saveSignatureRequest(tenantId!, selectedBlob, selectedBlob.type)
      })
    );
  }

  navigateToNewIssue(action: Observable<any>): Observable<any> {
    return action;
  }

  navigateToIssues(action: Observable<any>): Observable<any> {
    return action;
  }

}
