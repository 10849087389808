import {Injectable} from "@angular/core";
import {
  IssueType,
  ResourceProfileDto,
  StereotypeDto,
} from "@server-models";
import {IssueFormattedDetailData} from "@shared/components/issues/interfaces/issue-formatted-detail-data.interface";
import {FormBuilder, FormGroup} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {DateHelperService} from "@shared/services/date-helper.service";
import {IssuesPropertyService} from "@shared/components/issues/services/issues-property.service";
import {IssueDtoType} from "@shared/components/issues/types/issue-dto.type";
import {SubmitIssueDtoType} from "@shared/components/issues/types/submit-issue-dto.type";

@Injectable({
  providedIn: 'root',
})
export abstract class IssuesBaseService {

  constructor(
    protected _fb: FormBuilder,
    protected _translate: TranslateService,
    protected _dateHelperService: DateHelperService,
    protected _issuesPropertyService: IssuesPropertyService
  ) {
  }

  abstract formatReadDetailData(issueDto: IssueDtoType, issueType: IssueType, stereotypeDto?: StereotypeDto): IssueFormattedDetailData;

  abstract prepareFormIssueDto(
    form: FormGroup,
    templatePreviewDto: unknown,
    resourceDto?: ResourceProfileDto,
    tenantId?: number
  ): SubmitIssueDtoType;

  /**
   * @name getAdditionalFormattedData
   * @description
   * abstract method to delegate the task of add additional data to children
   * this method is use to add additional data into the formatted object
   * @memberof IssuesBaseService
   * @param issueDto
   */
  abstract getAdditionalFormattedData(issueDto: IssueDtoType): Partial<IssueFormattedDetailData>;

  /**
   * @name generateGuid
   * @description
   * generate a Guid for cache
   * @memberof IssuesBaseService
   * @returns {string}
   */
  generateGuid(): string {
    return Math.random().toString(36).substring(2)
  }
}
