<ion-list class="ion-no-padding">
  <ion-item [lines]="checkLines(propertyId)" class="ion-no-padding">
    <ion-input
      [label]="isRequired ? label + ' *': label"
      labelPlacement="stacked"
      type="hidden"
      [formControl]="formControl"
    ></ion-input>
    <ion-button *ngIf="!checkButtonState(propertyId)" slot="end" (click)="pictureTakeDispatch()">
      <ion-icon name="camera-outline"></ion-icon>
    </ion-button>
  </ion-item>
  <ng-container *ngFor="let photoValue of photoValues;let photoIndex = index">
    <ion-item class="ion-no-padding" *ngIf="photoValue?.pictureId === propertyId && photoValue?.state">
      <ng-container *ngIf="(checkLoading() | async)">
        <div class="spinner-container">
          <ion-spinner></ion-spinner>
        </div>
      </ng-container>

      <ng-container *ngIf="!(checkLoading() | async)">
        <div style="width: 100%; padding-top: 0.5em">
          <img *ngIf="photoValue?.picture?.webPath" alt="image-attached" style="padding-right: 0"
               [src]="photoValue?.picture?.webPath | safe: 'url'"/>

          <div style="text-align: right">
            <ion-button *ngIf="photoValue?.picture?.webPath" style="--padding-end: 0" fill="clear"
                        (click)="clear(propertyId)">
              {{ "TEAM.ISSUES.COMPONENTS.PICTURE.BUTTONS.REMOVE" | translate }}
            </ion-button>
          </div>
        </div>
      </ng-container>

    </ion-item>
  </ng-container>

  <div class="input-error" *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
    <p *ngIf="formControl.errors['required']">{{ 'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.REQUIRED' | translate }}</p>
  </div>
</ion-list>
