import {Injectable} from "@angular/core";
import {FileDto} from "@server-models";
import {Observable} from "rxjs";
import {LinkHttpClientBaseApiService} from "@link/services/link-http-client-base-api/link-http-client-base-api.service";

@Injectable({
  providedIn: 'root'
})
export class LinkFileApiService extends LinkHttpClientBaseApiService {

  /**
   * @name upload
   * @description
   * make a post to orga to upload a blob file using tenantId
   * It must end the file name as .jpeg
   * max blob size 20971520
   * @memberof LinkFileApiService
   * @param tenantId
   * @param blob
   * @param fileType
   */
  upload(tenantId: number, blob: Blob, fileType: string): Observable<FileDto> {
    let body = new FormData();
    body.append('file', blob, "file." + this._splitFileType(fileType));
    return super.post<FileDto>(`/orga/file/tenant/${tenantId}`, undefined, body);
  }

  /**
   * @name download
   * @description
   * download a file by fileId from orga
   * @memberof LinkFileApiService
   * @param fileId
   */
  download(fileId: number): Observable<FileDto> {
    return super.get<FileDto>(`/orga/file/${fileId}`, undefined)
  }

  /**
   * @name _splitFileType
   * @description
   * split the blob.type sended by param ex'image/jpeg'
   * and return the 2 position (jpeg) or first position if is not longer than 1
   * @memberof LinkFileApiService
   * @param fileType
   * @private
   */
  private _splitFileType(fileType: string): string {
    const splitString = fileType.split('/');
    if (splitString.length > 0) {
      return splitString[1]
    } else {
      return splitString[0]
    }

  }
}
