import {Component, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {CustomPropertyReadComponent} from "@shared/components/custom-property-read/custom-property-read.component";
import {IssueFormattedDetailData} from "@shared/components/issues/interfaces/issue-formatted-detail-data.interface";
import {BaseIssuesActions} from "@shared/components/issues/store/base-issues.action-type";
import {BaseIssuesSelectors} from "@shared/components/issues/store/base-issues.selectors-type";
import {BaseIssuesState} from "@shared/components/issues/store/base-issues.state";
import {IssueType} from "@server-models";
import {
  BaseIssueReadTicketComponent
} from "@shared/components/issues/components/base-issue-read/components/base-issue-read-ticket/base-issue-read-ticket.component";
import {
  BaseIssueReadMissionComponent
} from "@shared/components/issues/components/base-issue-read/components/base-issue-read-mission/base-issue-read-mission.component";
import {
  BaseIssueReadMessageComponent
} from "@shared/components/issues/components/base-issue-read/components/base-issue-read-message/base-issue-read-message.component";
import {
  BaseIssueReadWinterhalterServiceCallComponent
} from "@shared/components/issues/components/base-issue-read/components/winterhalter/read-service-call/read-service-call.component";

@Component({
  selector: 'app-base-issue-read',
  templateUrl: './base-issue-read.component.html',
  styleUrls: ['./base-issue-read.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    CustomPropertyReadComponent,
    BaseIssueReadTicketComponent,
    BaseIssueReadMissionComponent,
    BaseIssueReadMessageComponent,
    BaseIssueReadWinterhalterServiceCallComponent
  ],

})
export class BaseIssueReadComponent implements OnInit {
  id: number;
  isLoading$!: Observable<boolean>;
  detailDataFormatted$!: Observable<IssueFormattedDetailData>;
  protected readonly IssueType = IssueType;

  constructor(
    private _store: Store<BaseIssuesState>,
  ) {
    this.id = NaN;
  }

  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(BaseIssuesSelectors.selectIsLoading));
    this.detailDataFormatted$ = this._store.pipe(select(BaseIssuesSelectors.selectDetailDataFormatted));
  }

  /**
   * @name back
   * @description
   * navigates to previous page issues
   * @memberof BaseIssueReadComponent
   */
  back(): void {
    this._store.dispatch(BaseIssuesActions.navigateToIssues());
  }
}
