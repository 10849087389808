import {Injectable} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {combineLatestWith, Observable} from 'rxjs';
import {exhaustMap, map} from 'rxjs/operators';
import {Store} from "@ngrx/store";
import {LinkEffectsBase} from "./link.effect";
import {KnowledgeArticleService} from "../services/knowledgearticle.service";
import {KnowledgeService} from "@link/services/knowledge.service";
import {HintService} from "@link/services/hints.service";
import {LinkLoginSelectors} from "@link/pages/login/store/link-login.selector-type";
import {LinkActions} from "@link/store/link.actions-type";

@Injectable({
  providedIn: 'root'
})
export class LinkApiEffects extends LinkEffectsBase {


  constructor(
    actions$: Actions,
    store: Store,
    private knowledgeArticle: KnowledgeArticleService,
    private knowledge: KnowledgeService,
    private hint: HintService,
  ) {
    super(store, actions$);
  }

  getKnowledgeArticle(action: Observable<{ knowledgeArticleId: number }>) {
    return action.pipe(
      exhaustMap(action => this.knowledgeArticle.getKnowledgeArticle(action.knowledgeArticleId).pipe(
        map(response => LinkActions.getKnowledgeArticleSuccess({
          knowledgeArticle: response
        }))
      ))
    );
  }

  getOccurredHints(action: Observable<{ resourceId: number }>) {
    return action.pipe(
      combineLatestWith(this.store.select(LinkLoginSelectors.selectResource)),
      exhaustMap(([_, resource]) => {
          return this.hint.getResourceDashboard(resource!.resourceId!).pipe(
            map(response => LinkActions.getProfileDashboardSuccess({
              hints: response.hints ?? [],
              errors: response.errors ?? [],
            }))
          )
        }
      )
    );
  }

  getKnowledge(action: Observable<{}>) {
    return action.pipe(
      combineLatestWith(this.store.select(LinkLoginSelectors.selectResource)),
      exhaustMap(([_, resource]) => {
          return this.knowledge.getResourceKnowledge(resource!.resourceId!).pipe(
            map(response => LinkActions.getKnowledgeSuccess({
              knowledgeArticles: response
            }))
          )
        }
      )
    );
  }

}
