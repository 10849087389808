import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {SafePipe} from "@shared/pipes/safe.pipe";
import {UserHtmlComponent} from "@features/user-html/user-html.component";
import {AttachmentsComponent} from "@shared/components/attachments/attachments.component";
import {CustomPropertyReadComponent} from "@shared/components/custom-property-read/custom-property-read.component";
import {ReadInputTextComponent} from "@shared/components/custom-property-read/inputs/text/read-input-text.component";
import {
  IssueCustomPropertySetFormatted
} from "@shared/components/issues/interfaces/issue-custom-property-set-formatted.interface";

@Component({
  selector: 'app-issue-custom-read-property-sets',
  templateUrl: './issue-custom-property-sets-read.component.html',
  styleUrls: ['./issue-custom-property-sets-read.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, SafePipe, UserHtmlComponent, AttachmentsComponent, CustomPropertyReadComponent, ReadInputTextComponent],

})
export class IssueCustomPropertySetsReadComponent implements OnInit {
  @Input() propertySetsDetail: IssueCustomPropertySetFormatted[];

  constructor() {
    this.propertySetsDetail = [];
  }

  ngOnInit() {
  }

}
