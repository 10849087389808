import {Component, Input, OnInit} from '@angular/core';
import {IssueTemplateDto, IssueTemplateListDto} from "@server-models";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {SafePipe} from "@shared/pipes/safe.pipe";
import {UserHtmlComponent} from "@features/user-html/user-html.component";
import {AttachmentsComponent} from "@shared/components/attachments/attachments.component";
import {RouterLink} from "@angular/router";
import {Store} from "@ngrx/store";
import {BaseIssuesActions} from "@shared/components/issues/store/base-issues.action-type";

@Component({
  selector: 'app-base-issue-template-card',
  templateUrl: './base-issue-template-card.component.html',
  styleUrls: ['./base-issue-template-card.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, SafePipe, UserHtmlComponent, AttachmentsComponent, RouterLink],

})
export class BaseIssueTemplateCardComponent implements OnInit {

  @Input() template: IssueTemplateListDto;

  constructor(
    private _store: Store
  ) {
    this.template = {};
  }

  ngOnInit() {
  }

  navigateToTemplatePreview(selectedTemplate: IssueTemplateDto) {
    this._store.dispatch(BaseIssuesActions.getItemsTemplatePreviewByIssueType({id: selectedTemplate.issueTemplateId!, issueType: selectedTemplate.contentType!}))
  }


}
