/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ContextKind {
    Global = 1,
    Location = 2,
    Tenant = 3,
    LocationGroup = 4,
    User = 8,
}
