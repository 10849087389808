<ion-list class="ion-no-padding">
  <ion-item class="ion-no-padding">
    <ion-input
      [label]="isRequired ? label + ' *': label"
      labelPlacement="stacked"
      type="number"
      [min]="minNumber"
      [max]="maxNumber"
      [formControl]="formControl"
    ></ion-input>
  </ion-item>
  <ng-content></ng-content>
  <div class="input-error" *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
    <p *ngIf="formControl.errors['required']">{{ 'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.REQUIRED' | translate }}</p>
    <p
      *ngIf="formControl.errors['min']"> {{ 'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.MIN_NUMBER' | translate }} {{ minNumber }}</p>
    <p
      *ngIf="formControl.errors['max']"> {{ 'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.MAX_NUMBER' | translate }} {{ maxNumber }}</p>
  </div>
</ion-list>
