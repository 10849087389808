import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from "@angular/router";
import {select, Store} from "@ngrx/store";
import {inject} from "@angular/core";
import {filter, take, withLatestFrom} from "rxjs";
import {LinkLoginSelectors} from "@link/pages/login/store/link-login.selector-type";
import {map} from "rxjs/operators";

export const linkKnowledgeResolver: ResolveFn<{ isBackButtonEnable: boolean }> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const store: Store = inject(Store);
    let isBackButtonEnable: boolean = route.data['isBackButtonEnable'];

    return store.pipe(
      withLatestFrom(store.pipe(select(LinkLoginSelectors.selectIsLoading))),
      withLatestFrom(store.pipe(select(LinkLoginSelectors.selectFeatures))),
      filter(([[_, loading], features]) => !!features && !loading),
      take(1),
      map(([_, features]) => {
        isBackButtonEnable = features?.isKnowledgeVisible!
        return ({ isBackButtonEnable })
      })
    );
  };
