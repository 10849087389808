/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum HistoryTypes {
    Base = '0',
    FormRow = 1,
    Form = 2,
    FormField = 3,
}
