/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum Clients {
    Portal = '0',
    Tech = 1,
    Orga = 2,
    Team = 3,
    Link = 4,
}
