/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum LinkedElementType {
    Base = '0',
    Ticket = 1,
    Resource = 2,
    Mission = 3,
    Contract = 4,
    Contact = 5,
}
