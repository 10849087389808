import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl} from '@angular/platform-browser';

@Pipe({
    name: 'safe',
    standalone: true
})
export class SafePipe implements PipeTransform {

    constructor(protected sanitizer: DomSanitizer) {
    }

    public transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        let sanitizeValue: any;
        switch (type) {
            case 'html':
                sanitizeValue = this.sanitizer.bypassSecurityTrustHtml(value);
                break;
            case 'style':
                sanitizeValue = this.sanitizer.bypassSecurityTrustStyle(value);
                break;
            case 'script':
                sanitizeValue = this.sanitizer.bypassSecurityTrustScript(value);
                break;
            case 'url':
                sanitizeValue = this.sanitizer.bypassSecurityTrustUrl(value);
                break;
            case 'resourceUrl':
                sanitizeValue = this.sanitizer.bypassSecurityTrustResourceUrl(value);
                break;
            default:
                throw new Error(`Invalid safe type specified: ${type}`);
        }

        return sanitizeValue;
    }
}
