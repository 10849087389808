/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum RecordStatus {
    Untouched = '0',
    New = 1,
    Updated = 2,
    Removed = 3,
    Failed = 4,
}
