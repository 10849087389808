/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AuditPropertyType {
    None = '0',
    DateTime = 1,
    TimeSpan = 2,
    Double = 3,
}
