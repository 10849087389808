<ion-header [translucent]="true">
  <ion-toolbar color="primary">
    <ion-title>{{ 'LINK.KNOWLEDGE.TITLE' | translate }}</ion-title>
    <ion-buttons slot="start">
      <ion-button (click)="close()" *ngIf="isChildApp">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="ion-text-center" *ngIf="isLoading$ | async">
    <ion-spinner></ion-spinner>
  </div>

  <app-knowledge-list *ngIf="(knowledge$ | async)?.length! > 0; else noKnowledge"
                      [knowledge$]="knowledge$"
                      (open)="openDetail($event.id)"
  ></app-knowledge-list>
</ion-content>

<ng-template #noKnowledge>
  <ion-card class="card-container">
    <ion-item>
      <ion-label class="ion-text-center" [innerText]="'LINK.KNOWLEDGE.EMPTY' | translate"></ion-label>
    </ion-item>
  </ion-card>
</ng-template>
