import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, timeout} from "rxjs";

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // 100s = 100000ms
    const timeoutNumber: number = 100000;
    return next.handle(req).pipe(timeout(timeoutNumber));
  }
}
