<form [formGroup]="priorityForm">
  <ion-card>
    <ion-card-header>
      {{ "COMPONENTS.ISSUES.SERVICES.TICKET.GROUP_NAME" | translate }}
    </ion-card-header>
    <ion-card-content class="space-reduce">
      <ion-list class="ion-no-padding">
        <ion-item class="ion-no-padding">
          <ion-select
            (ionChange)="onPriorityChange($event)"
            [formControl]="getControl()"
                      interface="popover"
                      labelPlacement="stacked"
                      [label]="('COMPONENTS.ISSUES.SERVICES.TICKET.PROPERTY_NAME' | translate) + ' *'">
            <ion-select-option *ngFor="let option of priorityList" [value]="option.priorityId">{{ option.name }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <div class="input-error" *ngIf="getControl()?.errors && (getControl()?.dirty || getControl()?.touched)">
          <p *ngIf="getControl()?.errors?.['required']">{{ 'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.REQUIRED' | translate }}</p>
        </div>
      </ion-list>
    </ion-card-content>
  </ion-card>
</form>

