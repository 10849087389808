/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum NotificationTrigger {
    TicketChangedResource = 10,
    TicketChangedState = 11,
    TicketChangedEditor = 12,
    MissionChangedResource = 20,
    MissionChangedState = 21,
    MissionChangedEditor = 22,
    MissionChangedCompletion = 23,
    ContractChangedState = 30,
    ActivatedInfo = 40,
}
