import {Injectable} from "@angular/core";
import {catchError, map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Observable, throwError} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class FileService {

    constructor(
        private _http: HttpClient,
    ) {
    }

  /**
   * @name get
   * @description
   * request the blob file from param
   * @memberof FileService
   * @param path
   */
  get(path: string): Observable<Blob> {
        return this._http.get(path, { responseType: "blob" }).pipe(
            map(blob => blob),
            catchError((error) => throwError(() => error))
        )
    }
}
