import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from "@ngx-translate/core";
import {UserHtmlComponent} from "@features/user-html/user-html.component";
import {CustomInfoKinds} from "@server-models";

@Component({
  selector: 'app-read-input-info',
  templateUrl: './read-input-info.component.html',
  styleUrls: ['./read-input-info.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, UserHtmlComponent]
})
export class ReadInputInfoComponent implements OnInit {

  @Input() title: string = '';
  @Input() infoData!: {
    userContent: string,
    kind: CustomInfoKinds
  };



  constructor() {
  }

  ngOnInit() {

  }
}
