import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule, NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import {SafePipe} from "@shared/pipes/safe.pipe";
import {Observable, of, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {BaseIssuesState} from "@shared/components/issues/store/base-issues.state";
import {BaseIssuesSelectors} from "@shared/components/issues/store/base-issues.selectors-type";
import {BaseIssuesActions} from "@shared/components/issues/store/base-issues.action-type";
import {PhotoValueInterface} from "@shared/components/custom-property/inputs/picture/interfaces/photo-value.interface";


@Component({
  selector: 'app-input-picture',
  templateUrl: './input-picture.component.html',
  styleUrls: ['./input-picture.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule,
    ReactiveFormsModule, TranslateModule, SafePipe],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputPictureComponent
    }
  ]
})
export class InputPictureComponent implements OnChanges, ControlValueAccessor {

  @Input() label: string = '';
  @Input() isRequired: boolean;
  @Output() valueChange = new EventEmitter<string>();
  @Input() formControl: FormControl;
  @Input() propertyId: number;
  @Input() index: number;

  value: any;
  useCamera: boolean;
  useCameraOverlay: boolean;
  useUpload: boolean;
  photoValues: PhotoValueInterface[]
  pictureLoading$: Observable<boolean>;
  pictureLoadingId$: Observable<number>;


  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  constructor(
    private _store: Store<BaseIssuesState>,
  ) {
    this.formControl = new FormControl();
    this.useCamera = true;
    this.useCameraOverlay = false;
    this.useUpload = false;
    this.pictureLoading$ = of(false);
    this.pictureLoadingId$ = of(NaN);
    this.index = NaN;
    this.isRequired = false;
    this.photoValues = [];
    this.propertyId = NaN;
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['formControl']) {
      this.photoValues.push({state: false, pictureId: this.propertyId});
      this.pictureLoading$ = this._store.pipe(select(BaseIssuesSelectors.selectSelectedPictureIsLoading));
      this.pictureLoadingId$ = this._store.pipe(select(BaseIssuesSelectors.selectSelectedPictureIsLoadingId));

      if (changes['formControl'].currentValue?.value === null) {
        this.clear(this.propertyId);
      }

      this._store.pipe(select(BaseIssuesSelectors.selectSelectedPicture)).subscribe(
        (value) => {
          if (Object.keys(value).length > 0) {
            const {pictureId} = value;
            const index = this.photoValues.findIndex((photo: PhotoValueInterface) => photo.pictureId === pictureId);
            if (index !== -1) {
              this.photoValues.splice(index, 1, {...value, id: this.propertyId, state: true});
            }
          }
        });
      this._store.pipe(
        select(BaseIssuesSelectors.selectSavedFile),
        map((savedFile) => {
          if (savedFile.pictureId === this.propertyId) {
            this.formControl.setValue(savedFile.fileId);
          }
        })
      ).subscribe()
    }
  }

  /**
   * @name checkButtonState
   * @description
   * check the state of the photoValues to hide or show the camera button
   * @memberof InputPictureComponent
   * @param propertyId
   */
  checkButtonState(propertyId: number): boolean {
    const index = this.photoValues.findIndex((photo: PhotoValueInterface) => photo.pictureId === propertyId);
    return this.photoValues[index].state;
  }

  /**
   * @name pictureTakeDispatch
   * @description
   * dispatch the action to ask for picture
   * @memberof InputPictureComponent
   */
  pictureTakeDispatch() {
    this.formControl.markAsDirty();
    this.formControl.markAsTouched();
    this._store.dispatch(BaseIssuesActions.askPictureInput({isLoadingId: this.index, pictureId: this.propertyId}))

  }

  /**
   * @name clear
   * @description
   * clear the webpath and the formcontrol value setting as undefined
   * @memberof InputPictureComponent
   * @param propertyId
   */
  clear(propertyId: number) {
    const index = this.photoValues.findIndex((photo: PhotoValueInterface) => photo.pictureId === propertyId);
    this.photoValues[index].state = false;
    this.photoValues[index].picture = undefined;
    this.formControl.setValue(null);
  }

  /**
   * @name checkLoading
   * @description
   * check if there is a loading state with a specific index
   * @memberof InputPictureComponent
   */
  checkLoading(): Observable<boolean> {
    return this.pictureLoadingId$.pipe(
      withLatestFrom(this.pictureLoading$),
      map(([id, isLoading]) => (this.index! === id) && isLoading)
    );
  }

  /**
   * @name checkLines
   * @description
   * check if existing webPath to add or remove bottom line
   * @memberof InputPictureComponent
   * @param propertyId
   */
  checkLines(propertyId: number): string {
    const index = this.photoValues.findIndex((photo: PhotoValueInterface) => photo.pictureId === propertyId);
    return this.photoValues[index].picture?.webPath ? "none" : "";
  }
}
