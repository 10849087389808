import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {KnowledgeArticleDto} from "@server-models";
import {LinkHttpClientBaseApiService} from "@link/services/link-http-client-base-api/link-http-client-base-api.service";

@Injectable({
  providedIn: 'root',
})
export class KnowledgeArticleService extends LinkHttpClientBaseApiService {

  getKnowledgeArticle(knowledgeArticleId: number): Observable<KnowledgeArticleDto> {
    return super.get<KnowledgeArticleDto>(`/orga/v3/knowledgearticle/${knowledgeArticleId}/start`);
  }
}
