/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum Brands {
    Nexnox = '0',
    Semco = 1,
    Pilona = 2,
    Winterhalter = 3,
}
