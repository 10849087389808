import {Component, Input, ViewEncapsulation} from '@angular/core';
import {SafePipe} from "@shared/pipes/safe.pipe";

@Component({
  selector: 'app-user-html',
  templateUrl: './user-html.component.html',
  styleUrls: ['./user-html.component.scss'],
  imports: [
    SafePipe
  ],
  standalone: true,
  encapsulation: ViewEncapsulation.None
})
export class UserHtmlComponent {

  @Input() html: string | null | undefined;

}
