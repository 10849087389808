/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AppShortcutType {
    Base = '0',
    Url = 1,
    Route = 2,
    CreateChat = 3,
    CreateChatByProcess = 4,
    CreateChatByDefinition = 5,
    OpenKnowledgeArticle = 6,
    ShowDocumentFolder = 7,
    ShowTaskArchive = 8,
}
