import {Storage} from "@ionic/storage-angular";
import {from, mergeMap, Observable} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export abstract class StorageServiceBase<T> {
  protected abstract key: string;

  protected constructor(
    private storage: Storage
  ) {
  }

  public set(data: T): Observable<T> {
    return from(this.storage.set(this.key, data));
  }

  public remove(): Observable<T> {
    return from(this.storage.remove(this.key));
  }

  public getSt(): Observable<T> {
    return from(this.storage.get(this.key));
  }

  public setCustomKey(key: string, data: T): Observable<T> {
    return from(this.storage.set(key, data));
  }

  public getCustomKey(key: string): Observable<T> {
    return from(this.storage.get(key));
  }

  public clear(): Observable<void> {
    return from(this.storage.clear());
  }

  public merge(newData: T): Observable<T> {
    return from(this.storage.get(this.key)).pipe(
      mergeMap((currentData: T | null) => {
        const updatedData = currentData ? { ...currentData, ...newData } : { ...newData };
        return from(this.storage.set(this.key, updatedData)).pipe(
          mergeMap(() => [updatedData])
        );
      })
    );
  }
}
