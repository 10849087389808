import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, of} from "rxjs";
import {CommonModule} from "@angular/common";
import {KnowledgeArticleListDto} from '@server-models';
import {IonicModule} from "@ionic/angular";

@Component({
  selector: 'app-knowledge-list',
  templateUrl: './knowledge-list.component.html',
  styleUrls: ['./knowledge-list.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule]
})
export class KnowledgeListComponent implements OnInit {

  @Input()
  public knowledge$: Observable<KnowledgeArticleListDto[]> = of([]);

  @Output()
  public next = new EventEmitter();

  @Output()
  public open = new EventEmitter<{ id: number }>();

  constructor() {
  }

  ngOnInit() {
    this.next.emit();
  }

  click(item: KnowledgeArticleListDto) {
    this.open.emit({id: item.knowledgeArticleId!});
  }
}
