import {createFeatureSelector, createSelector} from "@ngrx/store";
import {BaseIssuesState} from "@shared/components/issues/store/base-issues.state";
import {IssueContentType, IssueType, ResourceProfileIssueTemplateShortCutDto} from "@server-models";

export const selectBaseIssuesStore = createFeatureSelector<BaseIssuesState>('issues');


export const selectIsLoading = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.isLoading
);

export const selectPage = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.page
);

export const selectPageList = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.items
);

export const selectPagePagination = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.paging
);

export const selectPageLoading = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.isLoading
);

export const selectPageLoadingId = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.isLoadingId
);

export const selectPageTemplate = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.itemsTemplate
);

// export const selectPageTemplatePreview = createSelector(
//   selectBaseIssuesStore,
//   (state: BaseIssuesState) => state.itemsTemplatePreview
// );

export const selectSelectedTemplate = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.selectedTemplate
);

export const selectSelectedTemplateId = createSelector(
  selectSelectedTemplate,
  (state: { id: number, templateIssueType: IssueContentType }) => state.id
);

export const selectSelectedTemplateIssueType = createSelector(
  selectSelectedTemplate,
  (state: { id: number, templateIssueType: IssueContentType }) => state.templateIssueType
);


export const selectSelectedIssue = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.selectedIssueItem
);

export const selectSelectedIssueId = createSelector(
  selectSelectedIssue,
  (state: { id: number, issueType: IssueType }) => state.id
);

export const selectSelectedIssueIssueType = createSelector(
  selectSelectedIssue,
  (state: { id: number, issueType: IssueType }) => state.issueType
);

export const selectShortCutTemplate = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.shortCutTemplate
);

export const selectShortCutTemplateId = createSelector(
  selectShortCutTemplate,
  (state: ResourceProfileIssueTemplateShortCutDto) => state.issueTemplateId
);

export const selectShortCutTemplateType = createSelector(
  selectShortCutTemplate,
  (state: ResourceProfileIssueTemplateShortCutDto) => state.contentType
);

export const selectSelectedIssueItem = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.issueItem
);

export const selectSelectedStereotype = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.selectedStereotype
);

export const selectSelectedStereotypeEntityType = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.selectedStereotypeEntityType
);

export const selectDetailDataFormatted = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.detailDataFormatted
);

export const selectSelectedPicture = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.selectedPicture
);

export const selectSelectedPictureIsLoading = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.selectedPictureIsLoading
);

export const selectSelectedPictureIsLoadingId = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.selectedPictureIsLoadingId
);

export const selectSelectedPictureAsBlob = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.selectedPictureAsBlob
);

export const selectSelectedSignatureAsBlob = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.selectedSignatureAsBlob
);

export const selectSelectedSignatureIsLoading = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.selectedSignatureIsLoading
);

export const selectSelectedSignatureIsLoadingId = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.selectedSignatureIsLoadingId
);

export const selectSavedFile = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.savedFile
);

export const selectSavedSignatureId = createSelector(
  selectBaseIssuesStore,
  (state: BaseIssuesState) => state.savedSignatureId
);
