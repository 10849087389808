/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum FormFieldTypes {
    Base = '0',
    Info = 1,
    Text = 2,
    Multiline = 3,
    Number = 4,
    Date = 5,
    Time = 6,
    Picture = 7,
    Signature = 8,
    Checkbox = 9,
    Dropdown = 10,
    Attachment = 11,
    SearchList = 12,
}
