import {Injectable} from "@angular/core";
import {IssueContentType, IssueType} from "@server-models";
import {LinkIssuesMessageApiService} from "@link/pages/issues/services/link-issues-message-api.service";
import {LinkIssuesMissionApiService} from "@link/pages/issues/services/link-issues-mission-api.service";
import {LinkIssuesTicketApiService} from "@link/pages/issues/services/link-issues-ticket-api.service";

@Injectable({
  providedIn: 'root',
})
export class LinkIssuesService {
  constructor(
    private _linkIssuesMessageApiService: LinkIssuesMessageApiService,
    private _linkIssuesMissionApiService: LinkIssuesMissionApiService,
    private _linkIssuesTicketApiService: LinkIssuesTicketApiService
  ) {

  }

  getApiServiceByIssueType(issueType: IssueType): LinkIssuesMessageApiService | LinkIssuesMissionApiService | LinkIssuesTicketApiService {
    switch (issueType) {
      case IssueType.Message:
        return this._linkIssuesMessageApiService;
      case IssueType.Mission:
        return this._linkIssuesMissionApiService;
      case IssueType.Ticket:
        return this._linkIssuesTicketApiService;
      default:
        throw new Error(`Api service, Unsupported issue type: ${issueType}`);
    }
  }

  getApiServiceByIssueContentType(issueContentType: IssueContentType): LinkIssuesMessageApiService | LinkIssuesMissionApiService | LinkIssuesTicketApiService {
    switch (issueContentType) {
      case IssueContentType.Message:
        return this._linkIssuesMessageApiService;
      case IssueContentType.Mission:
        return this._linkIssuesMissionApiService;
      case IssueContentType.Ticket:
        return this._linkIssuesTicketApiService;
      default:
        throw new Error(`Api service, Unsupported issue content type: ${issueContentType}`);
    }
  }
}
