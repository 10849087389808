import {Injectable} from "@angular/core";
import {AlertControllerCreate} from "@shared/interfaces/alert-controller-create.interface";
import {ErrorHandlerBaseService} from "@core/services/error-handler-base/error-handler-base.service";

@Injectable({
  providedIn: 'root'
})
export class LinkErrorHandlerLoginService extends ErrorHandlerBaseService {
  override _formatAlertFor422(status: number, name: string, message: string): AlertControllerCreate {
    const errorMessage = this._filterErrorStatus(status);
    const changePasswordAlert = this._formatChangePasswordAlert(status, name, message);

    return {
      header: `${this._errorText}: ${status.toString()}`,
      message: errorMessage,
      buttons: [
        {
          text: this._translateService.instant("GENERAL.ALERT.BUTTONS.DETAILS"),
          handler: () => {
            this._alertControllerService.observableAlert(changePasswordAlert);
          }
        },
        {
          text: this._translateService.instant("GENERAL.ALERT.BUTTONS.CHANGE_PASSWORD"),
          handler: () => {
            this._router.navigateByUrl("link/login/change-password");
          }
        }
      ]
    };
  }

  override _formatAlertFor400(status: number, name: string, message: any): AlertControllerCreate {
    let errorMessage = this._filterErrorStatus(status);
    if(!!message[0]?.Code) {
      errorMessage = this._getAllErrors(message);
    }

    return {
      header: this._translateService.instant("LOGIN.LINK.ERRORS.400"),
      subHeader: '',
      message: errorMessage,
      buttons: [
        {text: 'OK'}
      ]
    };
  }

  private _formatChangePasswordAlert(status: number, name: string, message: string): AlertControllerCreate {
    return {
      header: `${this._errorText}: ${status.toString()}`,
      subHeader: name,
      message: message,
      buttons: [
        {text: 'OK'}
      ],
    };
  }
}
