import {Component, Input, OnInit} from '@angular/core';
import {IonicModule} from "@ionic/angular";
import {ModalControllerService} from "@shared/services/modal-controller.service";
import {AttachmentsComponent} from "@shared/components/attachments/attachments.component";
import {UserHtmlComponent} from "@features/user-html/user-html.component";
import {FileOrgaAttachmentDto, OrgaAttachmentListDto} from "@server-models";

@Component({
  selector: 'app-viewer-knowledge-article',
  templateUrl: './viewer-knowledge-article.component.html',
  styleUrls: ['./viewer-knowledge-article.component.scss'],
  imports: [
    IonicModule,
    AttachmentsComponent,
    UserHtmlComponent
  ],
  standalone: true
})
export class ViewerKnowledgeArticleComponent implements OnInit {

  @Input() name!: string;
  @Input() htmlContent: any;
  @Input() attachmentsInc: OrgaAttachmentListDto[] | FileOrgaAttachmentDto[] | null | any;

  constructor(
    private _modalCtrlService: ModalControllerService,
  ) { }

  ngOnInit() {
  }

  exit(): void {
    this._modalCtrlService.closeModal();
  }

}
