import {createAction, props} from "@ngrx/store";
import {
  ResourceProfileOverviewDto,
  TokenDto
} from "@server-models";
import {AppType} from "@shared/models/AppType";
import {TenantInfoSettings} from "@shared/interfaces/tenant-info-settings.interface";
import {LinkTabNavigation} from "@link/store/link.state";

export const init = createAction(
  '[Login-Link] Init'
);

export const initSuccess = createAction(
  '[Login-Link] Init Success',
  props<{ app: AppType, tokenDto?: TokenDto, currentTenant?: TenantInfoSettings, lang?: string }>()
);

export const initFail = createAction(
  '[Login-Link] Init Fail',
  props<{ error: any }>()
);

export const byResource = createAction(
  '[Login-Link] By Resource',
  props<{ resourceId: number, key: string, lang?: string }>()
);

export const byResourceSuccess = createAction(
  '[Login-Link] By Resource Success',
  props<{tokenDto: TokenDto, linkId: number, lang?: string}>()
);

export const byResourceFail = createAction(
  '[Login-Link] By Resource Fail',
  props<{error: any }>()
);

export const linkSuccess = createAction(
  '[Login-Link] Link Success',
  props<{ linkId: number }>()
);

export const successLink = createAction(
  '[Login-Link] Link App Success',
  props<{ app: AppType, token?: TokenDto, currentTenant: TenantInfoSettings }>()
);

export const loadStorage = createAction(
  '[Login-Link] Load Storage Start'
);

export const loadStorageSuccess = createAction(
  '[Login-Link] Load Storage Success',
  props<{
    tenantSettings: any,
    tenantId: number,
    tenantLanguage: string,
    tenantDisplayName: string,
    tokenDto: TokenDto,
    app: AppType,
    linkId: number,
    linkKey: string,
    profile: ResourceProfileOverviewDto
  }>()
);

export const loadStorageFail = createAction(
  '[Login-Link] Load Storage Fail',
  props<{ error: any, isByResource?: boolean }>()
);

export const fetchTenantSettings = createAction(
  '[Login-Link] Fetch Tenant Settings Start'
);

export const fetchTenantSettingsSuccess = createAction(
  '[Login-Link] Fetch Tenant Settings Success',
  props<{ currentTenant: TenantInfoSettings }>()
);

export const fetchTenantSettingsFail = createAction(
  '[Login-Link] Fetch Tenant Settings Fail',
  props<{ error: any }>()
);

export const isLoginByResourceNavigation = createAction(
  '[Login-Link] Navigation to linkId page Start'
);

export const isLoginByResourceNavigationDone = createAction(
  '[Login-Link] Navigation to linkId page Done'
);

export const isLoginByResourceNavigationCanceled = createAction(
  '[Login-Link] Navigation to linkId page Canceled'
);

export const getProfile = createAction(
  '[Login-Link] Profile Home',
  props<{ resourceId: number }>()
);

export const getProfileSuccess = createAction(
  '[Login-Link] Get Profile Success',
  props<{ profile: ResourceProfileOverviewDto }>()
);

export const getProfileFail = createAction(
  '[Login-Link] Get Profile Error',
  props<{ error: any }>()
);

export const checkFeatures = createAction(
  '[Login-Link] Check Features Start'
);

export const checkFeaturesSuccess = createAction(
  '[Login-Link] Check Features Success',
  props<{tabNavigation: LinkTabNavigation}>()
);

export const checkFeaturesFail = createAction(
  '[Login-Link] Check Features Fail'
);

export const navigationDependsFeature = createAction(
  '[Login-Link] Navigation feature',
  props<{linkId: number}>()
);

export const navigationDependsFeatureDone = createAction(
  '[Login-Link] Navigation feature Done'
);
