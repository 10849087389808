/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum NotificationTypes {
    TaskState = 1,
    ChatMessage = 2,
    DeviceAction = 3,
    PushedInfo = 4,
    PushedStartedMission = 5,
}
