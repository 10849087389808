/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SettingValueType {
    Base = '0',
    Text = 1,
    HexColor = 2,
    Picture = 3,
    Culture = 4,
    Stereotype = 5,
    Priority = 6,
    Resource = 7,
    TicketState = 8,
    ContactLoginMethod = 9,
}
