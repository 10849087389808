import {Injectable} from "@angular/core";
import {
  type CustomDefaultCompleteDto,
  CustomPropertyCompleteDto,
  CustomPropertyDto,
  CustomPropertyType, CustomSetReferenceDto,
  CustomValueCompleteDto, FilledCustomValueDto,
  IssueType,
  MessageIssueDto,
  MessageIssuePreviewDto,
  MissionIssueDto, ResourceProfileDto,
  StereotypeDto, StereotypeUsageDto, SubmitMessageIssueDto, SubmitMissionIssueDto, SubmitTicketIssueDto,
  TicketIssueDto
} from "@server-models";
import {IssueFormattedDetailData} from "@shared/components/issues/interfaces/issue-formatted-detail-data.interface";
import {
  IssueCustomPropertySetFormatted
} from "@shared/components/issues/interfaces/issue-custom-property-set-formatted.interface";
import {IssueFormatted} from "@shared/components/issues/interfaces/issue-formatted.interface";
import {FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {DateHelperService} from "@shared/services/date-helper.service";
import {IssuesPropertyService} from "@shared/components/issues/services/issues-property.service";

@Injectable({
  providedIn: 'root',
})
export abstract class IssuesBaseService {

  constructor(
    private _fb: FormBuilder,
    private _translate: TranslateService,
    private _dateHelperService: DateHelperService,
    private _issuesPropertyService: IssuesPropertyService
  ) {
  }

  abstract prepareFormIssueDto(
    form: FormGroup,
    templatePreviewDto: MessageIssuePreviewDto,
    resourceDto?: ResourceProfileDto
  ): SubmitMessageIssueDto | SubmitMissionIssueDto | SubmitTicketIssueDto;

  /**
   * @name getAdditionalFormattedData
   * @description
   * abstract method to delegate the task of add additional data to children
   * this method is use to add additional data into the formatted object
   * @memberof IssuesBaseService
   * @param issueDto
   */
  abstract getAdditionalFormattedData(issueDto: MessageIssueDto | MissionIssueDto | TicketIssueDto): Partial<IssueFormattedDetailData>;

  /**
   * @name generateGuid
   * @description
   * generate a Guid for cache
   * @memberof IssuesBaseService
   * @returns {string}
   */
  generateGuid(): string {
    return Math.random().toString(36).substring(2)
  }

  prepareCustomValues(constructedForm: FormGroup): CustomValueCompleteDto[] {
    const customPropertyValues: FilledCustomValueDto[] = [];

    Object.keys(constructedForm.controls).forEach(groupName => {
      const group = constructedForm.get(groupName) as FormGroup;
      const customValuesComplete: CustomValueCompleteDto[] = [];

      Object.keys(group.controls).forEach(key => {
        const customValue: CustomValueCompleteDto = {
          type: this._issuesPropertyService.getTypeProperty(key),
          ownValue: group.get(key)!.value,
        };

        if (customValue.type === CustomPropertyType.TimeOfDay) {
          if (customValue.ownValue === undefined) {
            customValue.ownValue = null;
          } else {
            customValue.ownValue = this._dateHelperService.convertISOToMinutes(customValue.ownValue)
          }
        }

        customValuesComplete.push(customValue);
      });

      customPropertyValues.push({
        customValues: customValuesComplete
      });
    });

    this._issuesPropertyService.resetCustomTypes();
    return customPropertyValues.flatMap(value => value.customValues!);
  }

  /**
   * @name formatReadDetailData
   * @description
   * returns the Issue formatted data detail
   * @memberof IssuesBaseService
   * @param issueDto
   * @param stereotypeDto
   * @param issueType
   * @returns {IssueFormattedDetailData}
   */
  formatReadDetailData(issueDto: MessageIssueDto | MissionIssueDto | TicketIssueDto, stereotypeDto: StereotypeDto, issueType: IssueType): IssueFormattedDetailData {
    let customPropertySetsFormatted: IssueCustomPropertySetFormatted[] = [];

    for (const customPropertySet of stereotypeDto.customPropertySets!) {
      const customPropertySetName = customPropertySet.customPropertySet?.name;
      const customPropertySetId = customPropertySet.customPropertySet?.customPropertySetId;
      const customPropertySetPosition = customPropertySet.position;
      const issuesFormatted: IssueFormatted[] = [];

      for (const customPropertyValue of issueDto.customPropertyValues!) {
        const propertyId: number | undefined = customPropertyValue.propertyId;

        const properties: CustomPropertyDto[] = customPropertySet.customPropertySet!.properties!;
        const correspondingProperty: CustomPropertyCompleteDto | undefined = properties.find((property: CustomPropertyDto) => property.customPropertyId === propertyId);
        const customValue: CustomValueCompleteDto = customPropertyValue!.customValues![0].customValue!;

        if (correspondingProperty) {
          switch (correspondingProperty.type) {
            case CustomPropertyType.Info: {
              const formattedValueInfo: IssueFormatted = {
                title: correspondingProperty.name!,
                value: correspondingProperty.content,
                type: correspondingProperty.type!,
                position: correspondingProperty.position!,
                kind: correspondingProperty.infoKind
              }
              issuesFormatted.push(formattedValueInfo);
              break;
            }
            case CustomPropertyType.Dropdown: {
              const formattedValueInfo: IssueFormatted = {
                title: correspondingProperty.name!,
                value: this._getDropdownContent(customValue.ownValue, correspondingProperty.defaultValues!),
                type: correspondingProperty.type!,
                position: correspondingProperty.position!,
              }
              issuesFormatted.push(formattedValueInfo);
              break;
            }
            case CustomPropertyType.Signature:
            case CustomPropertyType.Picture: {
              const formattedValueInfo: IssueFormatted = {
                title: correspondingProperty.name!,
                fileValue: (customValue as any).ownValueFile,
                value: customValue.ownValue,
                type: correspondingProperty.type!,
                position: correspondingProperty.position!,
              }
              issuesFormatted.push(formattedValueInfo);
              break;
            }
            default: {
              const formattedValue: IssueFormatted = {
                title: correspondingProperty.name!,
                value: customValue.ownValue,
                position: correspondingProperty.position!,
                type: correspondingProperty.type!,
              };
              issuesFormatted.push(formattedValue);
            }
          }
        }
      }

      issuesFormatted.sort((a, b) => a.position - b.position);

      customPropertySetsFormatted.push({
        name: customPropertySetName!,
        propertySetId: customPropertySetId!,
        position: customPropertySetPosition!,
        issuesFormatted: issuesFormatted
      });

      customPropertySetsFormatted.sort((a, b) => a.position - b.position);
    }

    const baseResult: IssueFormattedDetailData = {
      issueId: issueDto.issueId!,
      headerTitle: issueDto.title!,
      issueType,
      customPropertySets: customPropertySetsFormatted
    };

    return {
      ...baseResult,
      ...this.getAdditionalFormattedData(issueDto)
    };
  }

  /**
   * @name _getDropdownContent
   * @description
   * find the dropdown value
   * @memberof IssuesBaseService
   * @param selectedId
   * @param options
   * @private
   */
  protected _getDropdownContent(selectedId: number, options: CustomDefaultCompleteDto[]): string {
    const result = options.find((props: any) => props.customValueId === selectedId)!;
    return result?.value;
  }

  buildFormGroupsCustomProperties(customPropertySets: CustomSetReferenceDto[]): FormGroup {
    const parentForm = this._fb.group({});

    customPropertySets.forEach((propertySet: any) => {
      const properties = propertySet.customPropertySet?.properties || [];

      properties.sort((a: any, b: any) => a.position! - b.position!);

      const group = this._fb.group({});

      properties.forEach((value: CustomPropertyDto) => {
        const propertyId = value.customPropertyId;
        const controlName = value.technicalName! + propertyId!;
        const validators = this.checkValidators(value);
        const defaultValue: CustomDefaultCompleteDto = value.defaultValues![0];

        if (value.type === CustomPropertyType.Checkbox) {
          if (value.isRequired && !defaultValue.value) {
            defaultValue.value = null;
          }
        }
        group.addControl(controlName, new FormControl(defaultValue?.value, validators));
        this._issuesPropertyService.addTypeProperty(controlName, value.type!);
      });

      parentForm.addControl(propertySet.customPropertySet?.name!, group);
    });

    return parentForm;
  }

  /**
   * @name checkValidators
   * @description
   * check validators for building form with validators
   * @memberof IssuesBaseService
   * @param properties
   */
  checkValidators(properties: CustomPropertyCompleteDto): ValidatorFn[] {
    let validators: ValidatorFn[] = [];

    if (properties?.maxLength) {
      validators.push(Validators.maxLength(properties?.maxLength));
    }
    if (properties?.minLength) {
      validators.push(Validators.minLength(properties?.minLength));
    }
    if (properties?.isRequired) {
      validators.push(Validators.required);
    }
    if (properties?.type === CustomPropertyType.Email) {
      validators.push(Validators.email);
    }
    if (properties?.maxValue) {
      validators.push(Validators.max(properties.maxValue));
    }
    if (properties?.minValue) {
      validators.push(Validators.min(properties.minValue));
    }

    return validators;
  }

  /**
   * @name _sortCustomPropertySets
   * @description
   * sort customPropertySets
   * @memberof IssuesBaseService
   * @param issueStereotype
   * @protected
   */
  protected _sortCustomPropertySets(issueStereotype: StereotypeUsageDto): CustomSetReferenceDto[] {
    const customPropertySets: CustomSetReferenceDto[] = issueStereotype?.customPropertySets!;
    return customPropertySets.map(customPropertySet => {
      customPropertySet.customPropertySet?.properties?.sort((a, b) => a.position! - b.position!);
      return customPropertySet;
    });
  }

  /**
   * @name _flattenCustomProperties
   * @description
   * flat custom property sets
   * @memberof IssuesBaseService
   * @param sortedCustomPropertySets
   * @protected
   */
  protected _flattenCustomProperties(sortedCustomPropertySets: CustomSetReferenceDto[]): CustomPropertyDto[] {
    return sortedCustomPropertySets.flatMap(propertySet => propertySet.customPropertySet?.properties || []);
  }

  /**
   * @name _prepareCustomPropertyValues
   * @description
   * format custom values
   * @memberof IssuesBaseService
   * @param customValues
   * @param issueStereotype
   * @param stereotypeCustomProperties
   * @protected
   */
  protected _prepareCustomPropertyValues(customValues: any[], issueStereotype: StereotypeUsageDto, stereotypeCustomProperties: CustomPropertyDto[]): FilledCustomValueDto[] {
    return customValues.map((customValue, index) => ({
      customValues: [{customValue}],
      tenantId: issueStereotype?.tenantId,
      propertyId: stereotypeCustomProperties[index].customPropertyId
    }));
  }
}
