/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum EditorRequestState {
    Untouched = '0',
    Accepted = 1,
    Declined = 2,
    Missed = 3,
}
