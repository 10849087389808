import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {KnowledgeArticleSimpleDto, ResourceProfileKnowledgeDto} from "@server-models";
import {map} from "rxjs/operators";
import {LinkHttpClientBaseApiService} from "@link/services/link-http-client-base-api/link-http-client-base-api.service";

@Injectable({
  providedIn: 'root',
})
export class KnowledgeService extends LinkHttpClientBaseApiService {

  getResourceKnowledge(resourceId: number): Observable<Array<KnowledgeArticleSimpleDto>> {
    return super.get<ResourceProfileKnowledgeDto>(`/tech/resource/${resourceId}/profile/knowledge`).pipe(map(
      x => x.knowledgeArticles || []
    ));
  }
}
