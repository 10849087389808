/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ImportType {
    User = '0',
    Receipt = 1,
    Resource = 2,
    ResourceManufacturer = 3,
}
