/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum TimeGraphKind {
    Year = 1,
    Month = 2,
    Week = 3,
    Day = 4,
}
