import {Injectable} from "@angular/core";
import {LoginBaseStorageService} from "@shared/stores/login-base/services/login-base-storage.service";

@Injectable({
  providedIn: 'root'
})
export class LinkLoginStorageService extends LoginBaseStorageService {
  linkId?: number;
  linkKey?: string;
}
