import {Injectable} from "@angular/core";
import {Clients} from "@server-models";
import {HttpClientBaseApiService} from "@core/services/http-client-base-api/http-client-base-api.service";
import {environment} from "@env-team/environment";

@Injectable({
  providedIn: 'root'
})
export abstract class TeamHttpClientBaseApiService extends HttpClientBaseApiService {
  override clientType = Clients.Team;
  override _apiUrlOne = environment.apiUrl.one;
}
