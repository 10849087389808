import {Component, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {Observable, of} from "rxjs";
import {
  IssueContentType,
  ResourceProfileIssueTemplateShortCutDto
} from "@server-models";
import {ActivatedRoute} from "@angular/router";
import {FormGroup, FormsModule} from "@angular/forms";
import {BaseIssuesSelectors} from "@shared/components/issues/store/base-issues.selectors-type";
import {BaseIssuesActions} from "@shared/components/issues/store/base-issues.action-type";
import {
  BaseIssueMessageTemplatePreviewComponent
} from "@shared/components/issues/components/base-issue-template-preview/components/base-issue-message-template-preview/base-issue-message-template-preview.component";
import {
  BaseIssueMissionTemplatePreviewComponent
} from "@shared/components/issues/components/base-issue-template-preview/components/base-issue-mission-template-preview/base-issue-mission-template-preview.component";
import {
  BaseIssueTicketTemplatePreviewComponent
} from "@shared/components/issues/components/base-issue-template-preview/components/base-issue-ticket-template-preview/base-issue-ticket-template-preview.component";
import {
  BaseIssueWinterhalterServiceCallTemplatePreviewComponent
} from "@shared/components/issues/components/base-issue-template-preview/components/winterhalter/service-call-template-preview/service-call-template-preview.component";
import {TemplatePreviewDtoType} from "@shared/components/issues/types/template-preview-dto.type";


@Component({
  selector: 'app-base-issues-template-preview',
  templateUrl: './base-issues-template-preview.component.html',
  styleUrls: ['./base-issues-template-preview.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    FormsModule,
    BaseIssueMessageTemplatePreviewComponent,
    BaseIssueMissionTemplatePreviewComponent,
    BaseIssueTicketTemplatePreviewComponent,
    BaseIssueWinterhalterServiceCallTemplatePreviewComponent
  ],
  standalone: true
})
export class BaseIssuesTemplatePreviewComponent implements OnInit {
  protected readonly IssueContentType = IssueContentType;
  isLoading$!: Observable<boolean>;
  shortCutTemplate$: Observable<ResourceProfileIssueTemplateShortCutDto>;
  shortCutTemplateId: number;
  templateId!: string;
  formEmitted: FormGroup;
  isBackButtonEnable: boolean;
  selectedTemplatePreview$: Observable<{
    id: number,
    templateIssueType: IssueContentType,
    data: TemplatePreviewDtoType
  }>;

  currentIssueContentType: IssueContentType;

  currentTemplatePreview: TemplatePreviewDtoType;

  constructor(
    private _store: Store,
    private _activatedRoute: ActivatedRoute
  ) {
    this.formEmitted = new FormGroup<any>({});
    this.isBackButtonEnable = true;
    this.shortCutTemplate$ = of<ResourceProfileIssueTemplateShortCutDto>({});
    this.shortCutTemplateId = NaN;
    this.selectedTemplatePreview$ = of(<{
      id: number,
      templateIssueType: IssueContentType,
      data: TemplatePreviewDtoType
    }>{});

    this.currentIssueContentType = <IssueContentType>{}

    this.currentTemplatePreview = {};
  }


  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(BaseIssuesSelectors.selectIsLoading));
    this.shortCutTemplate$ = this._store.pipe(select(BaseIssuesSelectors.selectShortCutTemplate));
    this.shortCutTemplate$.subscribe((shortCut) => {
      this.shortCutTemplateId = shortCut.issueTemplateId!;
    })

    this.selectedTemplatePreview$ = this._store.pipe(select(BaseIssuesSelectors.selectSelectedTemplate));
    this.selectedTemplatePreview$.subscribe((templatePreview) => {
      this.currentTemplatePreview = templatePreview.data;
    })
  }

  ionViewWillEnter(): void {
    this.init();
  }

  init() {
    const activatedRouterData = this._activatedRoute.snapshot.data['templatePreview'];

    if (activatedRouterData && 'isBackButtonEnable' in activatedRouterData) {
      this.isBackButtonEnable = activatedRouterData?.isBackButtonEnable;
    }
  }

  /**
   * @name onChangeForm
   * @description
   * event controlled on change form event from template preview card component to control the values here
   * @memberof BaseIssuesTemplatePreviewComponent
   */
  onChangeForm(ev: FormGroup): void {
    Object.keys(ev.controls).forEach(key => {
      const newGroup = ev.get(key) as FormGroup;

      if (!this.formEmitted.contains(key)) {
        this.formEmitted.addControl(key, newGroup);
      } else {
        this.formEmitted.setControl(key, newGroup);
      }
      this.formEmitted.updateValueAndValidity();
    });
  }

  /**
   * @name submitForm
   * @description
   * submit form to dispatch the action to send template preview with the data filled
   * @memberof BaseIssuesTemplatePreviewComponent
   */
  submitForm() {
    this._store.dispatch(BaseIssuesActions.prepareFormsToSendTemplatePreview({form: this.formEmitted, templatePreviewDto: this.currentTemplatePreview }))
  }

  /**
   * @name loadTemplatesPreview
   * @description
   * dispatch the action to request template preview
   * @memberof BaseIssuesTemplatePreviewComponent
   */
  loadTemplatesPreview(id: string): void {
    this._store.dispatch(BaseIssuesActions.getItemsTemplatePreview({id}));
  }

  /**
   * @name back
   * @description
   * navigate to previous page issues new
   * @memberof BaseIssuesTemplatePreviewComponent
   */
  back(): void {
    if (this.shortCutTemplateId) {
      this._store.dispatch(BaseIssuesActions.navigateToIssues())
    } else {
      this._store.dispatch(BaseIssuesActions.navigateToNewIssue());
    }
  }
}
