import {Component, Input, OnChanges} from '@angular/core';
import {
  AttachmentTypes,
  FileOrgaAttachmentDto,
  KnowledgeArticleOrgaAttachmentDto,
  OrgaAttachmentListDto
} from "@server-models";
import {TranslateModule} from "@ngx-translate/core";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";
import {Observable, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {OrgaAttachmentDtoExtend} from "@shared/components/attachments/interfaces/orga-attachment-dto-extend.interface";
import {select, Store} from "@ngrx/store";
import {AttachmentsActions} from "@shared/components/attachments/store/attachments.actions-type";
import {
  selectAttachmentsIsLoading,
  selectAttachmentsIsLoadingId
} from "@shared/components/attachments/store/attachments.selectors";
import {AttachmentInternalDto} from "@shared/components/attachments/interfaces/attachment-internal-dto.interface";

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule
  ],
  standalone: true
})
export class AttachmentsComponent implements OnChanges {

  @Input() attachmentsInc: OrgaAttachmentListDto[] | FileOrgaAttachmentDto[] | null | any;
  @Input() showHeader: boolean;

  mappedAttachments: AttachmentInternalDto[];
  attachmentType: typeof AttachmentTypes = AttachmentTypes;

  isLoading$: Observable<boolean>;
  isLoadingId$: Observable<number>;

  constructor(
    private _store: Store
  ) {
    this.isLoading$ = this._store.pipe(select(selectAttachmentsIsLoading));
    this.isLoadingId$ = this._store.pipe(select(selectAttachmentsIsLoadingId));
    this.mappedAttachments = [];
    this.showHeader = true;
  }

  ngOnChanges() {
    this.mappedAttachments = this._mapToAttachmentInternalDto(this.attachmentsInc);
  }

  /**
   * @name _mapToAttachmentInternalDto
   * @description
   * map the incoming attachments to AttachmentInternalDto[]
   * @memberof AttachmentsComponent
   * @param attachments
   * @private
   * @returns {AttachmentInternalDto[]}
   */
  _mapToAttachmentInternalDto(attachments: OrgaAttachmentListDto[] | FileOrgaAttachmentDto[] | null | any): AttachmentInternalDto[] {
    if (!attachments || attachments.length === 0) {
      return [];
    }

    return attachments.map((attachment: OrgaAttachmentListDto | FileOrgaAttachmentDto | KnowledgeArticleOrgaAttachmentDto) => {
      let attachmentInternal: AttachmentInternalDto = <AttachmentInternalDto>{};

      if (this._isKnowledgeArticle(attachment)) {
        attachmentInternal = {
          attachmentId: attachment.attachmentId!,
          name: attachment.knowledgeArticle?.title!,
          url: attachment.knowledgeArticle?.externalId!,
          type: attachment.type!,
          referenceId: attachment.knowledgeArticle?.knowledgeArticleId!
        }
      } else if (this._isOrgaAttachmentListDto(attachment)) {
        attachmentInternal = {
          attachmentId: attachment.attachmentId!,
          name: attachment?.name!,
          url: attachment?.url! || attachment?.link!,
          type: attachment.type!,
          referenceId: attachment.referenceId!
        }
      } else if (this._isFileOrgaAttachmentDto(attachment)) {
        attachmentInternal = {
          attachmentId: attachment.attachmentId! || attachment.file?.fileId!,
          name: attachment.file?.name!,
          url: attachment.file?.uri!,
          type: attachment.type!,
          mineType: attachment?.file?.mimeType!
        }
      }

      return attachmentInternal;
    });
  }

  private _isKnowledgeArticle(object: any): object is KnowledgeArticleOrgaAttachmentDto {
    return object.knowledgeArticle !== undefined;
  }

  /**
   * @name _isOrgaAttachmentListDto
   * @description
   * Check if the incoming object is OrgaAttachmentListDto
   * @memberof
   * @param object
   * @returns {object is OrgaAttachmentListDto}
   */
  private _isOrgaAttachmentListDto(object: any): object is OrgaAttachmentListDto {
    return object.file === undefined;
  }

  /**
   * @name _isFileOrgaAttachmentDto
   * @description
   * Check if the incoming object is FileOrgaAttachmentDto
   * @memberof
   * @param object
   * @returns {object is FileOrgaAttachmentDto}
   */
  private _isFileOrgaAttachmentDto(object: any): object is FileOrgaAttachmentDto {
    return object.file !== undefined;
  }

  /**
   * @name dispatchAction
   * @description
   * dispatch the action checking the attachment types
   * @memberof AttachmentsComponent
   * @param attachment
   */
  dispatchAction(attachment: OrgaAttachmentDtoExtend): void {
    switch (attachment.type) {
      case AttachmentTypes.File:
        this._store.dispatch(AttachmentsActions.getFile(
          {path: attachment.url!, name: attachment.name!, id: attachment.attachmentId!, mineType: attachment.mineType}
        ));
        break;
      case AttachmentTypes.Link:
        this._store.dispatch(AttachmentsActions.openLink({
          id: attachment.attachmentId!,
          options: {url: attachment.url!}
        }))
        break;
      case AttachmentTypes.Knowledge:
        this._store.dispatch(AttachmentsActions.getKnowledgeArticle({
          id: attachment.attachmentId!,
          referenceId: attachment.referenceId!
        }));
        break;
    }
  }

  /**
   * @name checkLoadingButton
   * @description
   * Check if it is loading base on an id
   * @memberof AttachmentsComponent
   * @param attachmentId
   * @returns {Observable<boolean>}
   */
  checkLoadingButton(attachmentId: number | undefined): Observable<boolean> {
    return this.isLoadingId$.pipe(
      withLatestFrom(this.isLoading$),
      map(([id, isLoading]) => (attachmentId! === id) && isLoading)
    );
  }

}
