<ion-list class="ion-no-padding">
  <ion-item class="ion-no-padding">
    <ion-grid>
      <ion-row class="ion-align-items-center">
        <ion-col>
          <ion-input
            [label]="isRequired ? label + ' *': label"
            labelPlacement="stacked"
            [formControl]="formControl"
            type="hidden"
          ></ion-input>
        </ion-col>
        <ion-col>
          <ion-datetime-button class="ion-float-right" [datetime]="'timeOfDay' + idTimeOfDay"></ion-datetime-button>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-modal [keepContentsMounted]="true">
      <ng-template>
        <ion-datetime
          (ionChange)="onIonChange($event)"
          [id]="'timeOfDay' + idTimeOfDay"
          presentation="time"
          [showDefaultButtons]="true"
          [min]="min"
          [max]="max"
          [value]="currentDate"
          [formControl]="formControl"
        ></ion-datetime>
      </ng-template>
    </ion-modal>
  </ion-item>
  <div class="input-error" *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
    <p *ngIf="formControl.errors['required']">{{ 'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.REQUIRED' | translate }}</p>
  </div>
</ion-list>
