/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ImpactTypes {
    Base = '0',
    Sanction = 1,
    Alert = 2,
    Status = 3,
}
