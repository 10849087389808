import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {SafePipe} from "@shared/pipes/safe.pipe";
import {UserHtmlComponent} from "@features/user-html/user-html.component";
import {AttachmentsComponent} from "@shared/components/attachments/attachments.component";
import {CustomPropertyReadComponent} from "@shared/components/custom-property-read/custom-property-read.component";
import {
  MissionIssueDetailsDto,
  MissionState
} from "@server-models";
import {ReadInputTextComponent} from "@shared/components/custom-property-read/inputs/text/read-input-text.component";

@Component({
  selector: 'app-issue-custom-read-mission',
  templateUrl: './issue-custom-mission-read.component.html',
  styleUrls: ['./issue-custom-mission-read.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, SafePipe, UserHtmlComponent, AttachmentsComponent, CustomPropertyReadComponent, ReadInputTextComponent],

})
export class IssueCustomMissionReadComponent implements OnInit {
  @Input() missionDetail: MissionIssueDetailsDto;


  constructor(
    private _translate: TranslateService
  ) {
    this.missionDetail = {};
  }

  ngOnInit() {
  }

  /**
   * @name checkMissionState
   * @description
   * to display a name of the state, it is with special case because the type value MissionState have a '0' as string value
   * @memberof IssueCustomMissionReadComponent
   * @param state
   */
  checkMissionState(state: MissionState | any): string {
    if (state == 0) {
      return this._translate.instant("COMPONENTS.ISSUES.COMPONENTS.READ.MISSION_STATE.CREATED");
    } else {
      switch (state) {
        case MissionState.Instructed:
          return this._translate.instant("COMPONENTS.ISSUES.COMPONENTS.READ.MISSION_STATE.INSTRUCTED");
        case MissionState.Rejected:
          return this._translate.instant("COMPONENTS.ISSUES.COMPONENTS.READ.MISSION_STATE.REJECTED");
        case MissionState.Canceled:
          return this._translate.instant("COMPONENTS.ISSUES.COMPONENTS.READ.MISSION_STATE.CANCELED");
        case MissionState.Done:
          return this._translate.instant("COMPONENTS.ISSUES.COMPONENTS.READ.MISSION_STATE.DONE");
        default:
          return this._translate.instant("COMPONENTS.ISSUES.COMPONENTS.READ.MISSION_STATE.UNKNOWN");
      }
    }
  }

}
