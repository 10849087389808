/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ContentType {
    AAB = '0',
    AAC = 1,
    AAM = 2,
    AAS = 3,
    ABW = 4,
    AC = 5,
    ACC = 6,
    ACE = 7,
    ACU = 8,
    ACUTC = 9,
    ADP = 10,
    AEP = 11,
    AFM = 12,
    AFP = 13,
    AHEAD = 14,
    AI = 15,
    AIF = 16,
    AIFC = 17,
    AIFF = 18,
    AIR = 19,
    AIT = 20,
    AMI = 21,
    APK = 22,
    APPCACHE = 23,
    APPLICATION = 24,
    APR = 25,
    ARC = 26,
    ASC = 27,
    ASF = 28,
    ASM = 29,
    ASO = 30,
    ASX = 31,
    ATC = 32,
    ATOM = 33,
    ATOMCAT = 34,
    ATOMSVC = 35,
    ATX = 36,
    AU = 37,
    AVI = 38,
    AW = 39,
    AZF = 40,
    AZS = 41,
    AZW = 42,
    BAT = 43,
    BCPIO = 44,
    BDF = 45,
    BDM = 46,
    BED = 47,
    BH2 = 48,
    BIN = 49,
    BLB = 50,
    BLORB = 51,
    BMI = 52,
    BMP = 53,
    BOOK = 54,
    BOX = 55,
    BOZ = 56,
    BPK = 57,
    BTIF = 58,
    BZ = 59,
    BZ2 = 60,
    C = 61,
    C11AMC = 62,
    C11AMZ = 63,
    C4D = 64,
    C4F = 65,
    C4G = 66,
    C4P = 67,
    C4U = 68,
    CAB = 69,
    CAF = 70,
    CAP = 71,
    CAR = 72,
    CAT = 73,
    CB7 = 74,
    CBA = 75,
    CBR = 76,
    CBT = 77,
    CBZ = 78,
    CC = 79,
    CCT = 80,
    CCXML = 81,
    CDBCMSG = 82,
    CDF = 83,
    CDKEY = 84,
    CDMIA = 85,
    CDMIC = 86,
    CDMID = 87,
    CDMIO = 88,
    CDMIQ = 89,
    CDX = 90,
    CDXML = 91,
    CDY = 92,
    CER = 93,
    CFS = 94,
    CGM = 95,
    CHAT = 96,
    CHM = 97,
    CHRT = 98,
    CIF = 99,
    CII = 100,
    CIL = 101,
    CLA = 102,
    CLASS = 103,
    CLKK = 104,
    CLKP = 105,
    CLKT = 106,
    CLKW = 107,
    CLKX = 108,
    CLP = 109,
    CMC = 110,
    CMDF = 111,
    CML = 112,
    CMP = 113,
    CMX = 114,
    COD = 115,
    COM = 116,
    CONF = 117,
    CPIO = 118,
    CPP = 119,
    CPT = 120,
    CRD = 121,
    CRL = 122,
    CRT = 123,
    CRYPTONOTE = 124,
    CSH = 125,
    CSML = 126,
    CSP = 127,
    CSS = 128,
    CST = 129,
    CSV = 130,
    CU = 131,
    CURL = 132,
    CWW = 133,
    CXT = 134,
    CXX = 135,
    DAE = 136,
    DAF = 137,
    DART = 138,
    DATALESS = 139,
    DAVMOUNT = 140,
    DBK = 141,
    DCR = 142,
    DCURL = 143,
    DD2 = 144,
    DDD = 145,
    DEB = 146,
    DEF = 147,
    DEPLOY = 148,
    DER = 149,
    DFAC = 150,
    DGC = 151,
    DIC = 152,
    DIF = 153,
    DIR = 154,
    DIS = 155,
    DIST = 156,
    DISTZ = 157,
    DJV = 158,
    DJVU = 159,
    DLL = 160,
    DMG = 161,
    DMP = 162,
    DMS = 163,
    DNA = 164,
    DOC = 165,
    DOCM = 166,
    DOCX = 167,
    DOT = 168,
    DOTM = 169,
    DOTX = 170,
    DP = 171,
    DPG = 172,
    DRA = 173,
    DSC = 174,
    DSSC = 175,
    DTB = 176,
    DTD = 177,
    DTS = 178,
    DTSHD = 179,
    DUMP = 180,
    DV = 181,
    DVB = 182,
    DVI = 183,
    DWF = 184,
    DWG = 185,
    DXF = 186,
    DXP = 187,
    DXR = 188,
    ECELP4800 = 189,
    ECELP7470 = 190,
    ECELP9600 = 191,
    ECMA = 192,
    EDM = 193,
    EDX = 194,
    EFIF = 195,
    EI6 = 196,
    ELC = 197,
    EMF = 198,
    EML = 199,
    EMMA = 200,
    EMZ = 201,
    EOL = 202,
    EOT = 203,
    EPS = 204,
    EPUB = 205,
    ES3 = 206,
    ESA = 207,
    ESF = 208,
    ET3 = 209,
    ETX = 210,
    EVA = 211,
    EVY = 212,
    EXE = 213,
    EXI = 214,
    EXT = 215,
    EXTENSIONS = 216,
    EZ = 217,
    EZ2 = 218,
    EZ3 = 219,
    F = 220,
    F4V = 221,
    F77 = 222,
    F90 = 223,
    FBS = 224,
    FCDT = 225,
    FCS = 226,
    FDF = 227,
    FE_LAUNCH = 228,
    FG5 = 229,
    FGD = 230,
    FH = 231,
    FH4 = 232,
    FH5 = 233,
    FH7 = 234,
    FHC = 235,
    FIG = 236,
    FLAC = 237,
    FLI = 238,
    FLO = 239,
    FLV = 240,
    FLW = 241,
    FLX = 242,
    FLY = 243,
    FM = 244,
    FNC = 245,
    FOR = 246,
    FPX = 247,
    FRAME = 248,
    FSC = 249,
    FST = 250,
    FTC = 251,
    FTI = 252,
    FVT = 253,
    FXP = 254,
    FXPL = 255,
    FZS = 256,
    G2W = 257,
    G3 = 258,
    G3W = 259,
    GAC = 260,
    GAM = 261,
    GBR = 262,
    GCA = 263,
    GDL = 264,
    GEO = 265,
    GEX = 266,
    GGB = 267,
    GGT = 268,
    GHF = 269,
    GIF = 270,
    GIM = 271,
    GML = 272,
    GMX = 273,
    GNUMERIC = 274,
    GPH = 275,
    GPX = 276,
    GQF = 277,
    GQS = 278,
    GRAM = 279,
    GRAMPS = 280,
    GRE = 281,
    GRV = 282,
    GRXML = 283,
    GSF = 284,
    GTAR = 285,
    GTM = 286,
    GTW = 287,
    GV = 288,
    GXF = 289,
    GXT = 290,
    H = 291,
    H261 = 292,
    H263 = 293,
    H264 = 294,
    HAL = 295,
    HBCI = 296,
    HDF = 297,
    HH = 298,
    HLP = 299,
    HPGL = 300,
    HPID = 301,
    HPS = 302,
    HQX = 303,
    HTKE = 304,
    HTM = 305,
    HTML = 306,
    HVD = 307,
    HVP = 308,
    HVS = 309,
    I2G = 310,
    IC = 311,
    ICC = 312,
    ICE = 313,
    ICM = 314,
    ICO = 315,
    ICS = 316,
    IEF = 317,
    IFB = 318,
    IFM = 319,
    IGES = 320,
    IGL = 321,
    IGM = 322,
    IGS = 323,
    IGX = 324,
    IIF = 325,
    IMP = 326,
    IMS = 327,
    IN = 328,
    INK = 329,
    INKML = 330,
    INSTALL = 331,
    IOTA = 332,
    IPFIX = 333,
    IPK = 334,
    IRM = 335,
    IRP = 336,
    ISO = 337,
    ITP = 338,
    IVP = 339,
    IVU = 340,
    JAD = 341,
    JAM = 342,
    JAR = 343,
    JAVA = 344,
    JISP = 345,
    JLT = 346,
    JNLP = 347,
    JODA = 348,
    JP2 = 349,
    JPEG = 350,
    JPG = 351,
    JPGM = 352,
    JPGV = 353,
    JPM = 354,
    JS = 355,
    JSON = 356,
    JSONML = 357,
    KAR = 358,
    KARBON = 359,
    KFO = 360,
    KIA = 361,
    KML = 362,
    KMZ = 363,
    KNE = 364,
    KNP = 365,
    KON = 366,
    KPR = 367,
    KPT = 368,
    KPXX = 369,
    KSP = 370,
    KTR = 371,
    KTX = 372,
    KTZ = 373,
    KWD = 374,
    KWT = 375,
    LASXML = 376,
    LATEX = 377,
    LBD = 378,
    LBE = 379,
    LES = 380,
    LHA = 381,
    LINK66 = 382,
    LIST = 383,
    LIST3820 = 384,
    LISTAFP = 385,
    LNK = 386,
    LOG = 387,
    LOSTXML = 388,
    LRF = 389,
    LRM = 390,
    LTF = 391,
    LVP = 392,
    LWP = 393,
    LZH = 394,
    M13 = 395,
    M14 = 396,
    M1V = 397,
    M21 = 398,
    M2A = 399,
    M2V = 400,
    M3A = 401,
    M3U = 402,
    M3U8 = 403,
    M4A = 404,
    M4B = 405,
    M4P = 406,
    M4U = 407,
    M4V = 408,
    MA = 409,
    MAC = 410,
    MADS = 411,
    MAG = 412,
    MAKER = 413,
    MAN = 414,
    MAR = 415,
    MATHML = 416,
    MB = 417,
    MBK = 418,
    MBOX = 419,
    MC1 = 420,
    MCD = 421,
    MCURL = 422,
    MDB = 423,
    MDI = 424,
    ME = 425,
    MESH = 426,
    META4 = 427,
    METALINK = 428,
    METS = 429,
    MFM = 430,
    MFT = 431,
    MGP = 432,
    MGZ = 433,
    MID = 434,
    MIDI = 435,
    MIE = 436,
    MIF = 437,
    MIME = 438,
    MJ2 = 439,
    MJP2 = 440,
    MK3D = 441,
    MKA = 442,
    MKS = 443,
    MKV = 444,
    MLP = 445,
    MMD = 446,
    MMF = 447,
    MMR = 448,
    MNG = 449,
    MNY = 450,
    MOBI = 451,
    MODS = 452,
    MOV = 453,
    MOVIE = 454,
    MP2 = 455,
    MP21 = 456,
    MP2A = 457,
    MP3 = 458,
    MP4 = 459,
    MP4A = 460,
    MP4S = 461,
    MP4V = 462,
    MPC = 463,
    MPE = 464,
    MPEG = 465,
    MPG = 466,
    MPG4 = 467,
    MPGA = 468,
    MPKG = 469,
    MPM = 470,
    MPN = 471,
    MPP = 472,
    MPT = 473,
    MPY = 474,
    MQY = 475,
    MRC = 476,
    MRCX = 477,
    MS = 478,
    MSCML = 479,
    MSEED = 480,
    MSEQ = 481,
    MSF = 482,
    MSH = 483,
    MSI = 484,
    MSL = 485,
    MSTY = 486,
    MTS = 487,
    MUS = 488,
    MUSICXML = 489,
    MVB = 490,
    MWF = 491,
    MXF = 492,
    MXL = 493,
    MXML = 494,
    MXS = 495,
    MXU = 496,
    N3 = 497,
    NB = 498,
    NBP = 499,
    NC = 500,
    NCX = 501,
    NFO = 502,
    NGDAT = 503,
    NITF = 504,
    NLU = 505,
    NML = 506,
    NND = 507,
    NNS = 508,
    NNW = 509,
    NPX = 510,
    NSC = 511,
    NSF = 512,
    NTF = 513,
    NZB = 514,
    OA2 = 515,
    OA3 = 516,
    OAS = 517,
    OBD = 518,
    OBJ = 519,
    ODA = 520,
    ODB = 521,
    ODC = 522,
    ODF = 523,
    ODFT = 524,
    ODG = 525,
    ODI = 526,
    ODM = 527,
    ODP = 528,
    ODS = 529,
    ODT = 530,
    OGA = 531,
    OGG = 532,
    OGV = 533,
    OGX = 534,
    OMDOC = 535,
    ONEPKG = 536,
    ONETMP = 537,
    ONETOC = 538,
    ONETOC2 = 539,
    OPF = 540,
    OPML = 541,
    OPRC = 542,
    ORG = 543,
    OSF = 544,
    OSFPVG = 545,
    OTC = 546,
    OTF = 547,
    OTG = 548,
    OTH = 549,
    OTI = 550,
    OTP = 551,
    OTS = 552,
    OTT = 553,
    OXPS = 554,
    OXT = 555,
    P = 556,
    P10 = 557,
    P12 = 558,
    P7B = 559,
    P7C = 560,
    P7M = 561,
    P7R = 562,
    P7S = 563,
    P8 = 564,
    PAS = 565,
    PAW = 566,
    PBD = 567,
    PBM = 568,
    PCAP = 569,
    PCF = 570,
    PCL = 571,
    PCLXL = 572,
    PCT = 573,
    PCURL = 574,
    PCX = 575,
    PDB = 576,
    PDF = 577,
    PFA = 578,
    PFB = 579,
    PFM = 580,
    PFR = 581,
    PFX = 582,
    PGM = 583,
    PGN = 584,
    PGP = 585,
    PIC = 586,
    PICT = 587,
    PKG = 588,
    PKI = 589,
    PKIPATH = 590,
    PLB = 591,
    PLC = 592,
    PLF = 593,
    PLS = 594,
    PML = 595,
    PNG = 596,
    PNM = 597,
    PNT = 598,
    PNTG = 599,
    PORTPKG = 600,
    POT = 601,
    POTM = 602,
    POTX = 603,
    PPAM = 604,
    PPD = 605,
    PPM = 606,
    PPS = 607,
    PPSM = 608,
    PPSX = 609,
    PPT = 610,
    PPTM = 611,
    PPTX = 612,
    PQA = 613,
    PRC = 614,
    PRE = 615,
    PRF = 616,
    PS = 617,
    PSB = 618,
    PSD = 619,
    PSF = 620,
    PSKCXML = 621,
    PTID = 622,
    PUB = 623,
    PVB = 624,
    PWN = 625,
    PYA = 626,
    PYV = 627,
    QAM = 628,
    QBO = 629,
    QFX = 630,
    QPS = 631,
    QT = 632,
    QTI = 633,
    QTIF = 634,
    QWD = 635,
    QWT = 636,
    QXB = 637,
    QXD = 638,
    QXL = 639,
    QXT = 640,
    RA = 641,
    RAM = 642,
    RAR = 643,
    RAS = 644,
    RCPROFILE = 645,
    RDF = 646,
    RDZ = 647,
    REP = 648,
    RES = 649,
    RGB = 650,
    RIF = 651,
    RIP = 652,
    RIS = 653,
    RL = 654,
    RLC = 655,
    RLD = 656,
    RM = 657,
    RMI = 658,
    RMP = 659,
    RMS = 660,
    RMVB = 661,
    RNC = 662,
    ROA = 663,
    ROFF = 664,
    RP9 = 665,
    RPSS = 666,
    RPST = 667,
    RQ = 668,
    RS = 669,
    RSD = 670,
    RSS = 671,
    RTF = 672,
    RTX = 673,
    S = 674,
    S3M = 675,
    SAF = 676,
    SBML = 677,
    SC = 678,
    SCD = 679,
    SCM = 680,
    SCQ = 681,
    SCS = 682,
    SCURL = 683,
    SDA = 684,
    SDC = 685,
    SDD = 686,
    SDKD = 687,
    SDKM = 688,
    SDP = 689,
    SDW = 690,
    SEE = 691,
    SEED = 692,
    SEMA = 693,
    SEMD = 694,
    SEMF = 695,
    SER = 696,
    SETPAY = 697,
    SETREG = 698,
    SFS = 699,
    SFV = 700,
    SGI = 701,
    SGL = 702,
    SGM = 703,
    SGML = 704,
    SH = 705,
    SHAR = 706,
    SHF = 707,
    SID = 708,
    SIG = 709,
    SIL = 710,
    SILO = 711,
    SIS = 712,
    SISX = 713,
    SIT = 714,
    SITX = 715,
    SKD = 716,
    SKM = 717,
    SKP = 718,
    SKT = 719,
    SLDM = 720,
    SLDX = 721,
    SLT = 722,
    SM = 723,
    SMF = 724,
    SMI = 725,
    SMIL = 726,
    SMV = 727,
    SMZIP = 728,
    SND = 729,
    SNF = 730,
    SO = 731,
    SPC = 732,
    SPF = 733,
    SPL = 734,
    SPOT = 735,
    SPP = 736,
    SPQ = 737,
    SPX = 738,
    SQL = 739,
    SRC = 740,
    SRT = 741,
    SRU = 742,
    SRX = 743,
    SSDL = 744,
    SSE = 745,
    SSF = 746,
    SSML = 747,
    ST = 748,
    STC = 749,
    STD = 750,
    STF = 751,
    STI = 752,
    STK = 753,
    STL = 754,
    STR = 755,
    STW = 756,
    SUB = 757,
    SUS = 758,
    SUSP = 759,
    SV4CPIO = 760,
    SV4CRC = 761,
    SVC = 762,
    SVD = 763,
    SVG = 764,
    SVGZ = 765,
    SWA = 766,
    SWF = 767,
    SWI = 768,
    SXC = 769,
    SXD = 770,
    SXG = 771,
    SXI = 772,
    SXM = 773,
    SXW = 774,
    T = 775,
    T3 = 776,
    TAGLET = 777,
    TAO = 778,
    TAR = 779,
    TCAP = 780,
    TCL = 781,
    TEACHER = 782,
    TEI = 783,
    TEICORPUS = 784,
    TEX = 785,
    TEXI = 786,
    TEXINFO = 787,
    TEXT = 788,
    TFI = 789,
    TFM = 790,
    TGA = 791,
    THMX = 792,
    TIF = 793,
    TIFF = 794,
    TMO = 795,
    TORRENT = 796,
    TPL = 797,
    TPT = 798,
    TR = 799,
    TRA = 800,
    TRM = 801,
    TSD = 802,
    TSV = 803,
    TTC = 804,
    TTF = 805,
    TTL = 806,
    TWD = 807,
    TWDS = 808,
    TXD = 809,
    TXF = 810,
    TXT = 811,
    U32 = 812,
    UDEB = 813,
    UFD = 814,
    UFDL = 815,
    ULX = 816,
    UMJ = 817,
    UNITYWEB = 818,
    UOML = 819,
    URI = 820,
    URIS = 821,
    URLS = 822,
    USTAR = 823,
    UTZ = 824,
    UU = 825,
    UVA = 826,
    UVD = 827,
    UVF = 828,
    UVG = 829,
    UVH = 830,
    UVI = 831,
    UVM = 832,
    UVP = 833,
    UVS = 834,
    UVT = 835,
    UVU = 836,
    UVV = 837,
    UVVA = 838,
    UVVD = 839,
    UVVF = 840,
    UVVG = 841,
    UVVH = 842,
    UVVI = 843,
    UVVM = 844,
    UVVP = 845,
    UVVS = 846,
    UVVT = 847,
    UVVU = 848,
    UVVV = 849,
    UVVX = 850,
    UVVZ = 851,
    UVX = 852,
    UVZ = 853,
    VCARD = 854,
    VCD = 855,
    VCF = 856,
    VCG = 857,
    VCS = 858,
    VCX = 859,
    VIS = 860,
    VIV = 861,
    VOB = 862,
    VOR = 863,
    VOX = 864,
    VRML = 865,
    VSD = 866,
    VSF = 867,
    VSS = 868,
    VST = 869,
    VSW = 870,
    VTU = 871,
    VXML = 872,
    W3D = 873,
    WAD = 874,
    WAV = 875,
    WAX = 876,
    WBMP = 877,
    WBMXL = 878,
    WBS = 879,
    WBXML = 880,
    WCM = 881,
    WDB = 882,
    WDP = 883,
    WEBA = 884,
    WEBM = 885,
    WEBP = 886,
    WG = 887,
    WGT = 888,
    WKS = 889,
    WM = 890,
    WMA = 891,
    WMD = 892,
    WMF = 893,
    WML = 894,
    WMLC = 895,
    WMLS = 896,
    WMLSC = 897,
    WMV = 898,
    WMX = 899,
    WMZ = 900,
    WOFF = 901,
    WPD = 902,
    WPL = 903,
    WPS = 904,
    WQD = 905,
    WRI = 906,
    WRL = 907,
    WSDL = 908,
    WSPOLICY = 909,
    WTB = 910,
    WVX = 911,
    X32 = 912,
    X3D = 913,
    X3DB = 914,
    X3DBZ = 915,
    X3DV = 916,
    X3DVZ = 917,
    X3DZ = 918,
    XAML = 919,
    XAP = 920,
    XAR = 921,
    XBAP = 922,
    XBD = 923,
    XBM = 924,
    XDF = 925,
    XDM = 926,
    XDP = 927,
    XDSSC = 928,
    XDW = 929,
    XENC = 930,
    XER = 931,
    XFDF = 932,
    XFDL = 933,
    XHT = 934,
    XHTML = 935,
    XHVML = 936,
    XIF = 937,
    XLA = 938,
    XLAM = 939,
    XLC = 940,
    XLF = 941,
    XLM = 942,
    XLS = 943,
    XLSB = 944,
    XLSM = 945,
    XLSX = 946,
    XLT = 947,
    XLTM = 948,
    XLTX = 949,
    XLW = 950,
    XM = 951,
    XML = 952,
    XO = 953,
    XOP = 954,
    XPI = 955,
    XPL = 956,
    XPM = 957,
    XPR = 958,
    XPS = 959,
    XPW = 960,
    XPX = 961,
    XSL = 962,
    XSLT = 963,
    XSM = 964,
    XSPF = 965,
    XUL = 966,
    XVM = 967,
    XVML = 968,
    XWD = 969,
    XYZ = 970,
    XZ = 971,
    YANG = 972,
    YIN = 973,
    Z1 = 974,
    Z2 = 975,
    Z3 = 976,
    Z4 = 977,
    Z5 = 978,
    Z6 = 979,
    Z7 = 980,
    Z8 = 981,
    ZAZ = 982,
    ZIP = 983,
    ZIR = 984,
    ZIRZ = 985,
    ZMM = 986,
    DEFAULT = 987,
}
