/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum TaskJobStateTerm {
    Undefined = '0',
    Pending = 1,
    Open = 2,
    Closed = 4,
}
