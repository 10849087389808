/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AttachmentTypes {
    Base = '0',
    File = 1,
    FilledForm = 2,
    Knowledge = 3,
    Folder = 4,
    Link = 5,
}
