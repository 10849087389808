/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum EditorQuestState {
    Suggested = 1,
    InProcess = 2,
    Completed = 3,
    Failed = 4,
    Canceled = 5,
}
