import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from "@ngx-translate/core";
import {DateHelperService} from "@shared/services/date-helper.service";

@Component({
  selector: 'app-read-input-time-of-day',
  templateUrl: './read-input-time-of-day.component.html',
  styleUrls: ['./read-input-time-of-day.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule]
})
export class ReadInputTimeOfDayComponent implements OnInit {

  @Input() title: string;
  @Input() value: number;

  timeOfDayISO: string;

  constructor(private _dateHelper: DateHelperService) {
    this.title = "";
    this.value = NaN;
    this.timeOfDayISO = "";
  }
  ngOnInit() {
    this.timeOfDayISO = this._dateHelper.convertMinutesToISO(this.value)!;

  }
}
