/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum TemplateContextType {
    MissionByTicketDescription = 1,
    MissionByTaskDescription = 2,
    NoteForTicket = 3,
    NoteForMissionByTicket = 4,
    NoteForMissionByTask = 5,
    MissionDescription = 6,
    MissionByInspectionDescription = 7,
    NoteForMission = 9,
    NoteForMissionByInspection = 10,
    NoteBase = 11,
    MissionNotificationContent = 12,
    TicketNotificationContent = 13,
    InfoNotificationContent = 14,
    MissionNotificationSubject = 15,
    TicketNotificationSubject = 16,
    InfoNotificationSubject = 17,
    IssueEmailSubject = 18,
    IssueEmailContent = 19,
}
