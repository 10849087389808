/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ReportGroup {
    None = '0',
    ControlPoint = 1,
    Tag = 2,
    Resource = 3,
}
