<ion-header>
  <ion-toolbar>
    <ion-title>{{ name }}</ion-title>
    <ion-buttons slot="start">
      <ion-button color="primary" (click)="toggleSidebar()">
        <ion-icon name="grid-outline" *ngIf="!isSidebarVisible"></ion-icon>
        <ion-icon name="grid" *ngIf="isSidebarVisible"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button color="primary" (click)="exit()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-searchbar
    [placeholder]=" 'COMPONENTS.ATTACHMENTS.SEARCH.PLACEHOLDER' | translate"
    [showClearButton]="isSearch"
    debounce="100"
    (ionChange)="onSearch($event)"
  >
  </ion-searchbar>
</ion-header>
<ion-content scroll-y="false">
  <ngx-extended-pdf-viewer
    [src]="file"
    [height]="'auto'"
    [textLayer]="true"
    [showToolbar]="false"
    [handTool]="false"
    (currentZoomFactor)="zoomChange($event)"
    [zoom]="zoomLevel"
    [(page)]="currentPage"
    (pagesLoaded)="pagesLoaded($event)"
    [(sidebarVisible)]="isSidebarVisible"
  >
  </ngx-extended-pdf-viewer>
  <ion-fab class="fab-zoom" vertical="bottom" horizontal="start" slot="fixed">
    <ion-row>
      <ion-col>
        <ion-fab-button (click)="shiftZoom(-1)">
          <ion-icon name="remove"></ion-icon>
        </ion-fab-button>
      </ion-col>
      <ion-col>
        <ion-fab-button (click)="shiftZoom(1)">
          <ion-icon name="add"></ion-icon>
        </ion-fab-button>
      </ion-col>
    </ion-row>
  </ion-fab>
  <ion-fab class="fab-paging" vertical="bottom" horizontal="end" slot="fixed" *ngIf="!isSearch">
    <ion-row>
      <ion-col>
        <ion-fab-button [disabled]="currentPage === 1" (click)="shiftPage(-1)">
          <ion-icon name="chevron-back"></ion-icon>
        </ion-fab-button>
      </ion-col>
      <ion-col>
        <ion-fab-button [disabled]="currentPage === pagesCount" (click)="shiftPage(1)">
          <ion-icon name="chevron-forward"></ion-icon>
        </ion-fab-button>
      </ion-col>
    </ion-row>
  </ion-fab>
  <ion-fab class="fab-paging" vertical="bottom" horizontal="end" slot="fixed" *ngIf="isSearch">
    <ion-row>
      <ion-col>
        <ion-fab-button [disabled]="currentPage === 1" (click)="searchNext()">
          <ion-icon name="caret-back-outline"></ion-icon>
        </ion-fab-button>
      </ion-col>
      <ion-col>
        <ion-fab-button [disabled]="currentPage === pagesCount" (click)="searchPrev()">
          <ion-icon name="caret-forward-outline"></ion-icon>
        </ion-fab-button>
      </ion-col>
    </ion-row>
  </ion-fab>
</ion-content>
