<ion-header [translucent]="true">
  <ion-toolbar color="primary">
    <ion-title>{{ (knowledge$ | async)?.title || '...' }}</ion-title>
    <ion-buttons *ngIf="(features$ | async) as features" slot="start">
      <ion-button *ngIf="!(features?.knowledgeShortcutId !== null && features?.knowledgeShortcutId !== undefined)" (click)="back()">
        <ion-icon slot="start" name="arrow-back-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <app-knowledge-detail
    [knowledge$]="knowledge$"
  ></app-knowledge-detail>
</ion-content>
