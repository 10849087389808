import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule, NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {DateHelperService} from "@shared/services/date-helper.service";

@Component({
  selector: 'app-input-time-of-day',
  templateUrl: './input-time-of-day.component.html',
  styleUrls: ['./input-time-of-day.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule,
    ReactiveFormsModule, TranslateModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputTimeOfDayComponent
    }
  ]
})
export class InputTimeOfDayComponent implements OnChanges, ControlValueAccessor {

  @Input() label: string = '';
  @Input() isRequired: boolean;
  @Output() valueChange = new EventEmitter<string>();
  @Input() formControl: FormControl;
  @Input() idTimeOfDay: number;
  @Input() minMinutes!: number;
  @Input() maxMinutes!: number;

  value: string = '';
  currentDate: string;
  min?: string;
  max?: string;

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  constructor(
    private _dateHelperService: DateHelperService
  ) {
    this.formControl = new FormControl();
    this.currentDate = new Date().toISOString();
    this.value = this.currentDate;
    this.isRequired = false;
    this.idTimeOfDay = NaN;
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onIonChange(event: any) {
    if (event.detail.value) {
      this.formControl.setValue(event.detail.value);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['formControl']) {
      this._setValues();
    }

  }

  private _setValues() {
    this.formControl.setValue(this._dateHelperService.convertMinutesToISO(this.formControl.value as number));
    this.min = this._dateHelperService.convertMinutesToISO(this.minMinutes);
    this.max = this._dateHelperService.convertMinutesToISO(this.maxMinutes);
  }
}
