<ng-container [ngSwitch]="inputProperty.type">
  <app-read-input-text
    [title]="inputProperty.title"
    [value]="inputProperty.value"
    *ngSwitchCase="CustomPropertyType.Text">
  </app-read-input-text>

  <app-read-input-text
    [title]="inputProperty.title"
    [value]="inputProperty.value"
    *ngSwitchCase="CustomPropertyType.Multiline">
  </app-read-input-text>

  <app-read-input-text
    [title]="inputProperty.title"
    [value]="inputProperty.value"
    *ngSwitchCase="CustomPropertyType.Email">
  </app-read-input-text>

  <app-read-input-text
    [title]="inputProperty.title"
    [value]="inputProperty.value"
    *ngSwitchCase="CustomPropertyType.Phone">
  </app-read-input-text>

  <app-read-input-numeric
    [title]="inputProperty.title"
    [value]="inputProperty.value"
    *ngSwitchCase="CustomPropertyType.Numeric">
  </app-read-input-numeric>

  <app-read-input-date
    [title]="inputProperty.title"
    [value]="inputProperty.value"
    *ngSwitchCase="CustomPropertyType.Date">
  </app-read-input-date>

  <app-read-input-time-of-day
    [title]="inputProperty.title"
    [value]="inputProperty.value"
    *ngSwitchCase="CustomPropertyType.TimeOfDay">
  </app-read-input-time-of-day>

  <app-read-input-info
    [title]="inputProperty.title"
    [infoData]="{
      kind: inputProperty.kind!,
      userContent: inputProperty.value!
    }"
    *ngSwitchCase="CustomPropertyType.Info">
  </app-read-input-info>

  <app-read-input-text
    [title]="inputProperty.title"
    [value]="inputProperty.value"
    *ngSwitchCase="CustomPropertyType.Dropdown">
  </app-read-input-text>

  <app-read-input-checkbox
    [title]="inputProperty.title"
    [value]="inputProperty.value"
    *ngSwitchCase="CustomPropertyType.Checkbox">
  </app-read-input-checkbox>

  <app-read-input-picture
    [title]="inputProperty.title"
    [value]="inputProperty.fileValue!"
    *ngSwitchCase="CustomPropertyType.Picture">
  </app-read-input-picture>

  <app-read-input-signature
    [title]="inputProperty.title"
    [value]="inputProperty.fileValue!"
    *ngSwitchCase="CustomPropertyType.Signature">
  </app-read-input-signature>
</ng-container>
