/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum TimePatterns {
    None = '0',
    Single = 1,
    Daily = 2,
    Weekly = 3,
    MonthlyDay = 4,
    MonthlyWeekDay = 5,
}
