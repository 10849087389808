/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum MissionType {
    Base = '0',
    Ticket = 1,
    Task = 2,
    Manual = 3,
    Inspection = 4,
}
