import { Injectable } from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ChildAppService {

  constructor(
    private router: Router
  )
  {
  }

  public close = async () => await this.router.navigate([this.getCurrentApp()]);
  public isChildApp = (app: string) => this.getCurrentApp() !== app;

  private getCurrentApp = () => this.getRouteSegments()[0];
  private getRouteSegments = () => this.router.url.split('/').slice(1);
}
