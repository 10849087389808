import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {ResourceProfileDashboardDto, ResourceProfileHintDto,} from "@server-models";
import {map} from "rxjs/operators";
import {LinkHttpClientBaseApiService} from "@link/services/link-http-client-base-api/link-http-client-base-api.service";

@Injectable({
  providedIn: 'root',
})
export class HintService extends LinkHttpClientBaseApiService {
  getResourceDashboard(resourceId: number): Observable<ResourceProfileDashboardDto> {
    return super.get<ResourceProfileDashboardDto>(`/tech/resource/${resourceId}/profile/dashboard`);
  }
}
