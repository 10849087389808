<ion-list class="ion-no-padding">
  <ion-item class="ion-no-padding">
    <ion-input
      [label]="isRequired ? label + ' *': label"
      labelPlacement="stacked"
      type="email"
      [formControl]="formControl"
      (ionChange)="registerOnChange($event)"
    ></ion-input>
  </ion-item>
  <div class="input-error" *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
    <p *ngIf="formControl.errors['required']" >{{'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.REQUIRED' | translate}}</p>
    <p *ngIf="formControl.errors['email']"> {{'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.VALID_EMAIL' | translate}}</p>
  </div>
</ion-list>
