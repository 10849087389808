import {createReducer, on} from "@ngrx/store";
import {LinkLoginState} from "./link-login.state";
import {LinkLoginActions} from "@link/pages/login/store/link-login.actions-type";

export const initialState: LinkLoginState = <LinkLoginState>{};

export const linkLoginStore = createReducer(
  initialState,
  on(LinkLoginActions.loadStorageSuccess, (state, {
      tenantDisplayName,
      tenantSettings,
      tenantId,
      tenantLanguage,
      tokenDto,
      app,
      linkId,
      linkKey,
      profile
    }) => ({
      ...state,
      currentTenant: {
        tenantDisplayName,
        tenantSettings,
        tenantId,
        tenantLanguage,
      },
      app: tokenDto,
      currentApp: app,
      linkId,
      linkKey,
      isLogged: !!tokenDto,
      profile
    })
  ),
  on(LinkLoginActions.byResource, (state, {resourceId, key}) => ({
    ...state,
    linkId: resourceId,
    linkKey: key,
    isLoading: true
  })),
  on(LinkLoginActions.byResourceSuccess, (state, {linkId}) => ({
    ...state,
    isByResource: true,
    linkId: linkId,
    isLoading: true
  })),
  on(LinkLoginActions.byResourceFail, (state) => ({
    ...state,
    isLoading: false,
    isByResource: false
  })),
  on(LinkLoginActions.linkSuccess, (state, {linkId}) => ({
    ...state,
    linkId: linkId
  })),
  on(LinkLoginActions.initSuccess, (state, {tokenDto, app, currentTenant}) => ({
      ...state,
      currentApp: app,
      app: tokenDto,
      currentTenant,
      isLoading: true
    })
  ),
  on(LinkLoginActions.initFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(LinkLoginActions.loginRefresh, (state) => ({
    ...state,
    isLoading: true
  })),
  on(LinkLoginActions.loginRefreshSuccess, (state, {tokenDto}) => ({
    ...state,
    app: tokenDto,
    isLoading: true
  })),
  on(LinkLoginActions.loginRefreshFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(LinkLoginActions.successLink, (state, {app, token, currentTenant}) => ({
    ...state,
    currentApp: app,
    app: token,
    currentTenant,
    isLoading: true
  })),
  on(LinkLoginActions.fetchTenantSettingsSuccess, (state, {currentTenant}) => ({
      ...state,
      currentTenant,
      isLoading: true
    })
  ),
  on(LinkLoginActions.isLoginByResourceNavigationDone, (state) => ({
      ...state,
      isLoading: false,
      isByResource: false
    })
  ),
  on(LinkLoginActions.isLoginByResourceNavigationCanceled, (state) => ({
      ...state,
      isLoading: false,
      isByResource: false
    })
  ),
  on(LinkLoginActions.getProfile, (state) => ({
    ...state,
    isLoading: true
  })),
  on(LinkLoginActions.getProfileSuccess, (state, {profile}) => ({
    ...state,
    profile: profile,
    isLoading: true
  })),
  on(LinkLoginActions.checkFeatures, (state) => ({
    ...state,
    isLoading: true
  })),
  on(LinkLoginActions.checkFeaturesSuccess, (state, {tabNavigation}) => ({
    ...state,
    tabNavigation: tabNavigation,
    isLoading: true
  })),
  on(LinkLoginActions.checkFeaturesFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(LinkLoginActions.navigationDependsFeature, (state) => ({
    ...state,
    isLoading: true
  })),
  on(LinkLoginActions.navigationDependsFeatureDone, (state) => ({
    ...state,
    isLoading: false
  })),
);

