import {createFeatureSelector, createSelector} from "@ngrx/store";
import {LinkState} from "./link.state";

export const selectLink = createFeatureSelector<LinkState>('link');

export const selectIsLoading = createSelector(
  selectLink,
  (state: LinkState) => state.isLoading
);
export const selectKnowledgeIsLoading = createSelector(
  selectLink,
  (state: LinkState) => state.knowledge?.isLoading
);

export const selectKnowledge = createSelector(
  selectLink,
  (state: LinkState) => state.knowledge?.list ?? []
);

export const selectOccurredHints = createSelector(
  selectLink,
  (state: LinkState) => state.hint?.list ?? []
);
export const selectOccurredErrors = createSelector(
  selectLink,
  (state: LinkState) => state.error?.list ?? []
);

export const selectOccurredHintsLoading = createSelector(
  selectLink,
  (state: LinkState) => state.hint?.isLoading ?? true
);

export const selectKnowledgeDetail = createSelector(
  selectLink,
  (state: LinkState) => state.knowledge.detail
);
