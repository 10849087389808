/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ConditionTypes {
    Base = '0',
    Percentage = 1,
    Time = 2,
    ChangedStatus = 3,
}
