import {Component, OnInit} from '@angular/core';
import {IonicModule, ViewWillEnter} from "@ionic/angular";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {PaginationParams} from "@shared/interfaces/pagination-params.interface";
import {IssueListDto, ResourceProfileFeatureDto, type ResourceProfileIssueTemplateShortCutDto} from "@server-models";
import {filter, Observable, of, Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {tap} from "rxjs/operators";
import {Pagination} from "@shared/interfaces/pagination.interface";
import {LinkIssuesSelectors} from "@link/pages/issues/store/link-issues.selector-type";
import {LinkIssuesActions} from "@link/pages/issues/store/link-issues.actions-type";
import {BaseIssueCardComponent} from "@shared/components/issues/components/base-issue-card/base-issue-card.component";
import {LinkLoginSelectors} from "@link/pages/login/store/link-login.selector-type";

@Component({
  selector: 'app-issues',
  templateUrl: './issues.page.html',
  styleUrls: ['./issues.page.scss'],
  imports: [
    IonicModule,
    AsyncPipe,
    BaseIssueCardComponent,
    NgForOf,
    NgIf,
    TranslateModule,
    BaseIssueCardComponent
  ],
  standalone: true
})
export class IssuesPage implements OnInit, ViewWillEnter {

  paginationParams: PaginationParams;
  ownLoad: boolean;
  infinityLoading: boolean;

  issuesData$: Observable<IssueListDto[]>;
  pagingData$: Observable<Pagination>;
  isLoading$: Observable<boolean>;

  openSubscriptionList$: Subscription[];
  features$: Observable<ResourceProfileFeatureDto>;

  constructor(
    private _store: Store
  ) {
    this.issuesData$ = this._store.pipe(select(LinkIssuesSelectors.selectPageList));
    this.pagingData$ = this._store.pipe(select(LinkIssuesSelectors.selectPagePagination));
    this.isLoading$ = this._store.pipe(select(LinkIssuesSelectors.selectPageLoading));

    this.openSubscriptionList$ = [];
    this.ownLoad = false;
    this.infinityLoading = false;
    this.paginationParams = {
      pageNumber: 1,
      pageSize: 25,
      cols: 'Content',
      sortField: 'DateTime',
      sort: 1
    };
    this.features$ = of()

  }

  ngOnInit() {
    this.features$ = this._store.pipe(select(LinkLoginSelectors.selectFeatures));
  }

  ionViewWillEnter(): void {
    this.loadIssuesByPagination(true);
  }

  /**
   * @name loadIssuesByPagination
   * @description
   * dispatch issues request
   * @memberof IssuesPage
   * @param refresh
   */
  loadIssuesByPagination(refresh: boolean = false): void {
    this._store.dispatch((LinkIssuesActions.getItemsPaginated({
      params: this.paginationParams,
      refresh
    })));
  }

  /**
   * @name loadMoreIssues
   * @description
   * load more issues on infinity loading event
   * @memberof IssuesPage
   * @param event
   */
  loadMoreIssues(event: any): void {
    this.paginationParams.pageNumber++;
    this.infinityLoading = true;
    this.loadIssuesByPagination();
    this.isLoading$.pipe(
      filter((loader: boolean) => !loader),
      tap(() => {
        event.target.complete();
        this.infinityLoading = false;
      })
    ).subscribe();
  }

  /**
   * @name handleRefresh
   * @description
   * handle the refresh page on drag event
   * @memberof IssuesPage
   * @param event
   */
  handleRefresh(event: any): void {
    if (this.ownLoad) {
      if (event && event.target) {
        event.target.complete();
      }
      return;
    }

    this.ownLoad = true;
    if (event) {
      event.target.disabled = true;
    }

    this.paginationParams.pageNumber = 1;
    this.loadIssuesByPagination(true);

    this.isLoading$.pipe(
      filter((loader: boolean) => !loader),
      tap(() => {
        if (event && event.target) {
          event.target.disabled = false;
          event.target.complete();
        }
        this.ownLoad = false;
      })
    ).subscribe();
  }

  /**
   * @name gotoAdd
   * @description
   * navigate to add issue
   * @memberof IssuesPage
   */
  gotoAdd(uniqueIssueTemplate?: ResourceProfileIssueTemplateShortCutDto): void {
    this.paginationParams.pageNumber = 1;
    if (uniqueIssueTemplate) {
      this._store.dispatch(LinkIssuesActions.getItemsTemplatePreviewByIssueType({id: uniqueIssueTemplate.issueTemplateId!, issueType: uniqueIssueTemplate.contentType!}))
    } else {
      this._store.dispatch(LinkIssuesActions.navigateToNewIssue());
    }
  }

  onNavigateToIssue() {
    this.paginationParams.pageNumber = 1;
  }
}
