import {Injectable} from "@angular/core";
import * as dayjs from 'dayjs';
import * as dayjsUtc from 'dayjs/plugin/utc';
import * as dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat';
import * as dayjsRelativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/de';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ClockService {

  constructor(private trans: TranslateService) {
    dayjs.extend(dayjsUtc);
    dayjs.extend(dayjsCustomParseFormat);
    dayjs.extend(dayjsRelativeTime)
    dayjs.locale('de')
  }

  public parse(datetime: string | null) {
    if (!datetime)
      return null;

    return dayjs(datetime)
      .local()
      .toDate();
  }

  public to(at: Date | null) {
    if (!at)
      return null;

    return dayjs().to(at);
  }

  format(date: Date | null, withTime = true) {
    if (withTime) {
      const format = this.trans.currentLang == 'de-DE' ? 'DD.MM.YYYY HH:mm' : 'DD/MM/YYYY HH:mm';
      return dayjs(date).format(format);
    } else {
      const format = this.trans.currentLang == 'de-DE' ? 'DD.MM.YYYY' : 'DD/MM/YYYY';
      return dayjs(date).format(format);
    }
  }

  formatCustom(date: Date | null, format: string) {
    return dayjs(date).format(format);
  }
}
