<ion-list class="ion-no-padding">
  <ion-item class="ion-no-padding">
    <ion-input
      [label]="isRequired ? label + ' *': label"
      labelPlacement="stacked"
      [pattern]="pattern!"
      [maxlength]="maxLength!"
      [minlength]="minLength!"
      type="text"
      [formControl]="formControl"
    ></ion-input>
  </ion-item>
  <ng-content></ng-content>
  <div class="input-error" *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
    <p *ngIf="formControl.errors['required']" >{{'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.REQUIRED' | translate}}</p>
    <p *ngIf="formControl.errors['minlength']"> {{'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.MIN_LENGTH' | translate}} {{minLength}}</p>
    <p *ngIf="formControl.errors['maxlength']"> {{'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.MAX_LENGTH' | translate}} {{maxLength}}</p>
    <p *ngIf="formControl.errors['pattern']"> {{'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.PATTERNS.PIN' | translate}} </p>
  </div>
</ion-list>
