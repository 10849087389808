/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum BillingType {
    None = '0',
    PerLocation = 1,
    PerUser = 2,
    PerGdprRule = 3,
    PerNotificationRule = 4,
    PerSubscription = 5,
}
