/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum HintLevel {
    None = '0',
    Warning = 1,
    Error = 2,
    Information = 3,
}
