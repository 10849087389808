/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum TaskTypes {
    Task = '0',
    Confirmation = 1,
    Document = 2,
    Collection = 3,
}
