import {mapToCanActivate, Route, RouterStateSnapshot} from "@angular/router";
import {HomePage} from "./pages/home/home.page";
import {KnowledgePage} from "./pages/knowledge/knowledge.page";
import {LinkPage} from "./pages/link.page";
import {PropertiesPage} from "./pages/properties/properties.page";
import {KnowledgeArticlePage} from "./pages/knowledge-article/knowledge-article.page";
import {LinkLoginPage} from "@link/pages/login/login.page";
import {inject} from "@angular/core";
import {AppType} from "@shared/models/AppType";
import {LinkAppLoginGuard} from "@link/guards/app-login.guard";
import {LinkIssuesGuard} from "@link/guards/link-issues.guard";
import {LinkIssuesTemplateGuard} from "@link/guards/link-issues-teamplate.guard";
import {LinkHomeGuard} from "@link/guards/link-home.guard";
import {LinkKnowledgeGuard} from "@link/guards/link-knowledge.guard";
import {LinkPropertiesGuard} from "@link/guards/link-properties.guard";
import {IssuesPage} from "@link/pages/issues/issues.page";
import {linkIssueTemplatePreviewResolver} from "@link/pages/issues/store/link-issue-template-preview.resolver";
import {LinkIssueReadGuard} from "@link/pages/issues/components/issue-read/guards/link-issue-read.guards";
import {BaseIssueReadComponent} from "@shared/components/issues/components/base-issue-read/base-issue-read.component";
import {
  BaseIssuesTemplatePreviewComponent
} from "@shared/components/issues/components/base-issue-template-preview/base-issues-template-preview.component";
import {LinkErrorPage} from "@link/pages/error/error.page";
import {linkIssueNewTemplateResolver} from "@link/pages/issues/store/link-issue-new-template.resolver";
import {linkKnowledgeResolver} from "@link/pages/knowledge/resolvers/link-knowledge.resolver";
import {
  BaseIssueSuccessComponent
} from "@shared/components/issues/components/base-issue-success/base-issue-success.component";
import {
  BaseIssueTemplateComponent
} from "@shared/components/issues/components/base-issue-template/base-issue-template.component";

export default [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'link'
  },
  {
    path: 'link',
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LinkLoginPage
      },
      {
        path: 'error',
        component: LinkErrorPage
      },
      {
        path: ':id',
        component: LinkPage,
        canActivate: [
          (_: any, state: RouterStateSnapshot) => inject(LinkAppLoginGuard).canActivate((AppType.Link), state)
        ],
        children: [
          {
            path: 'home',
            component: HomePage,
            canActivate: mapToCanActivate([LinkHomeGuard])
          },
          {
            path: 'knowledge',
            component: KnowledgePage,
            canActivate: mapToCanActivate([LinkKnowledgeGuard]),
            resolve: {knowledge: linkKnowledgeResolver},
            data: {isBackButtonEnable: true}
          },
          {path: 'knowledge/:id', component: KnowledgeArticlePage},
          {
            path: 'properties',
            component: PropertiesPage,
            canActivate: mapToCanActivate([LinkPropertiesGuard])
          },
          {
            path: 'issues',
            component: IssuesPage,
            canActivate: mapToCanActivate([LinkIssuesGuard])
          },
          {
            path: 'issues/new',
            component: BaseIssueTemplateComponent,
            canActivate: mapToCanActivate([LinkIssuesTemplateGuard]),
            resolve: {newTemplate: linkIssueNewTemplateResolver},
            data: {isBackButtonEnable: true}
          },
          {
            path: 'issues/new/:id',
            component: BaseIssuesTemplatePreviewComponent,
            resolve: {templatePreview: linkIssueTemplatePreviewResolver},
            pathMatch: 'full',
            data: {isBackButtonEnable: true}
          },
          {
            path: 'issues/success',
            component: BaseIssueSuccessComponent,
            pathMatch: 'full'
          },
          {
            path: 'issues/:id',
            component: BaseIssueReadComponent,
            canActivate: mapToCanActivate([LinkIssueReadGuard]),
            pathMatch: 'full',
          },
        ],
      }
    ]
  },
] as Route[];
