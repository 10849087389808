import {Component, Input, OnInit} from '@angular/core';
import {IonicModule} from "@ionic/angular";
import {NgOptimizedImage} from "@angular/common";
import {ModalControllerService} from "@shared/services/modal-controller.service";
import {SafeUrl} from "@angular/platform-browser";
import {SafePipe} from "@shared/pipes/safe.pipe";

@Component({
  selector: 'app-viewer-image',
  templateUrl: './viewer-image.component.html',
  styleUrls: ['./viewer-image.component.scss'],
  imports: [
    IonicModule,
    NgOptimizedImage,
    SafePipe
  ],
  standalone: true
})
export class ViewerImageComponent implements OnInit {

  @Input() file!: Blob;
  @Input() name!: string;

  image!: SafeUrl;

  constructor(
      private _modalCtrlService: ModalControllerService,
  ) { }

  ngOnInit() {
    this._transformBlobToImage();
  }

  /**
   * @name _transformBlobToImage
   * @description
   * Transform the blob to a valid url image
   * @memberof ViewerImageComponent
   * @private
   */
  private _transformBlobToImage(): void {
    this.image = URL.createObjectURL(this.file);
  }

  /**
   * @name exit
   * @description
   * call modal controller service to close the modal
   * @memberof ViewerImageComponent
   */
  exit(): void {
    this._modalCtrlService.closeModal();
  }

}
