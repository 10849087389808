/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SqlDatePart {
    Year = 1,
    Quarter = 2,
    Month = 3,
    DayOfYear = 4,
    Day = 5,
    Week = 6,
    WeekDay = 7,
    Hour = 8,
    Minute = 9,
    Second = 10,
    Millisecond = 11,
    Microsecond = 12,
    Nanosecond = 13,
    TZoffset = 14,
    ISO_WEEK = 15,
}
