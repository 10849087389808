/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum RefreshTokenTypes {
    Base = '0',
    User = 1,
    Device = 3,
    QuickAccess = 4,
}
