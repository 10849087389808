import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {KnowledgeListComponent} from '@features/knowledge/knowledge-list/knowledge-list.component';
import {ChildAppService} from '@core/child-app/child-app.service';
import {Store} from "@ngrx/store";
import {selectKnowledge, selectKnowledgeIsLoading} from "../../store/link.selector";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {LinkActions} from "@link/store/link.actions-type";

@Component({
  selector: 'app-knowledge',
  templateUrl: './knowledge.page.html',
  styleUrls: ['./knowledge.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, KnowledgeListComponent, TranslateModule]
})
export class KnowledgePage implements OnInit {

  public isChildApp: boolean;
  public knowledge$ = this._store.select(selectKnowledge);
  public isLoading$ = this._store.select(selectKnowledgeIsLoading);
  isBackButtonEnable: boolean;


  constructor(
    private childAppService: ChildAppService,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private _store: Store
  ) {
    this.isChildApp = this.childAppService.isChildApp('link');
    this.isBackButtonEnable = true;
  }

  ngOnInit() {
    const activatedRouterData = this._activatedRoute?.snapshot?.data['knowledge'];
    if (activatedRouterData && 'isBackButtonEnable' in activatedRouterData) {
      this.isBackButtonEnable = activatedRouterData?.isBackButtonEnable;
    }
    this._store.dispatch(LinkActions.getKnowledge());
  }

  close = async () => await this.childAppService.close();

  async openDetail(id: number) {
    await this.router.navigate([id], {relativeTo: this._activatedRoute});
  }
}
