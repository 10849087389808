/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum NotificationTargetType {
    Base = '0',
    Email = 1,
    Contact = 2,
    Function = 3,
}
