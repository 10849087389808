import {Injectable} from "@angular/core";
import {Environment} from "@shared/services/interfaces/environment.interface";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  languages_allowed: string[];

  constructor(
    private _translate: TranslateService
  ) {
    this.languages_allowed = ["de-DE","en-US"];
  }

  /**
   * @name setLanguage
   * @description
   * set the default lang and usage language
   * @memberof LanguageService
   * @param language
   *
   */
  setLanguage(language: string): void {
    this._translate.setDefaultLang(language);
    // this lane is to avoid errors to set the default language in case that is not english
    this._translate.use(language);
  }

  /**
   * @name setDefaultLanguageByEnvironment
   * @description
   * set the default language by environment specification
   * if the browserLang is one of the allowed languages it will set browserLang instead of environment
   * (this is used to the beginning of the application where there is still no token yet)
   * @memberof LanguageService
   * @param environmentInc
   */
  setDefaultLanguageByEnvironment(environmentInc: Environment): void {
    const browserLang = this.getBrowserLang()!;
    const defaultLang = this.languages_allowed.includes(browserLang) ? browserLang : (environmentInc?.lang?.defaultLanguage ?? 'en-US');

    this.setLanguage(defaultLang);
  }

  /**
   * @name setLanguageByPriority
   * @description
   * set the language of the app taking in account a priority
   * @memberof LanguageService
   * @param parameterLang
   * @param userLang
   * @param environment
   */
  setLanguageByPriority(parameterLang: string | undefined, userLang: string | undefined, environment: Environment): void {
    const defaultLang: string = environment.lang.defaultLanguage ?? 'en-US';
    const browserLang = this.getBrowserLang();

    const validLang = parameterLang || userLang || (browserLang && this.languages_allowed.includes(browserLang) ? browserLang : defaultLang);

    this.setLanguage(validLang);
  }

  /**
   * @name getCurrentLanguage
   * @description
   * return the default language set in the _translate
   * @memberof LanguageService
   */
  getCurrentLanguage(): string {
    return this._translate.getDefaultLang();
  }

  /**
   * @name getBrowserLang
   * @description
   * return the browserLang
   * @memberof LanguageService
   */
  getBrowserLang(): string | undefined {
    return this._translate.getBrowserLang();
  }

}
