/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ChatSourceType {
    Base = '0',
    Mail = 1,
    Form = 2,
    Process = 3,
}
