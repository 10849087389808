import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {SafePipe} from "@shared/pipes/safe.pipe";
import {UserHtmlComponent} from "@features/user-html/user-html.component";
import {AttachmentsComponent} from "@shared/components/attachments/attachments.component";
import {RouterLink} from "@angular/router";
import {CustomPropertyComponent} from "@shared/components/custom-property/custom-property.component";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {InputTextComponent} from "@shared/components/custom-property/inputs/text/input-text.component";
import { PrioritySimpleDto } from "@server-models";

@Component({
  selector: 'app-issue-custom-form-priority',
  templateUrl: './issue-custom-priority-form.component.html',
  styleUrls: ['./issue-custom-priority-form.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    SafePipe,
    UserHtmlComponent,
    AttachmentsComponent,
    RouterLink,
    InputTextComponent,
    ReactiveFormsModule,
    CustomPropertyComponent
  ]
})
export class IssueCustomPriorityFormComponent {
  @Input() priorityList: PrioritySimpleDto[];
  @Output() inputChange = new EventEmitter<FormGroup>();

  priorityForm: FormGroup;

  constructor(
    private _translate: TranslateService,
    private _fb: FormBuilder
  ) {
    this.priorityList = [];
    this.priorityForm = this._fb.group({
      Priority: ['', Validators.required]
    });
  }

  getControl(): FormControl {
    return this.priorityForm.get('Priority') as FormControl
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['priorityList']) {
      this.formModified();
    }
  }

  formModified(): void {
    this.inputChange.emit(this.priorityForm);
  }

  onPriorityChange(event: any) {
    const selectedValue = event.detail.value;
  }
}
