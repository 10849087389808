/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DataTableUsage {
    DataTable = 1,
    Selector = 2,
    Restriction = 3,
    Calendar = 4,
}
