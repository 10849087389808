import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from "@ngx-translate/core";
import {FileDto} from "@server-models";
import {SafePipe} from "@shared/pipes/safe.pipe";

@Component({
  selector: 'app-read-input-signature',
  templateUrl: './read-input-signature.component.html',
  styleUrls: ['./read-input-signature.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, SafePipe]
})
export class ReadInputSignatureComponent implements OnInit {

  @Input() title: string;
  @Input() value: FileDto;

  constructor() {
    this.title = "";
    this.value = {};
  }
  ngOnInit() {
  }
}
