import {AppState} from "./app.state";
import {createReducer, on} from "@ngrx/store";
import {appInit} from "./app.actions";

export const initialState: AppState = <AppState>{};

export const appStore = createReducer(
    initialState,
    on(appInit, (state) => ({
        isLoaded: true
    }))
);
