<form [formGroup]="parentForm">
  <ng-container *ngIf="ticketTemplatePreview">
    <app-issue-custom-form-priority
      (inputChange)="formInnerModified($event)"
      [priorityList]="ticketTemplatePreview.priorities!">
    </app-issue-custom-form-priority>
    <app-issue-custom-form-property
      (inputChange)="formInnerModified($event)"
      [customPropertySets]="ticketTemplatePreview.issueStereotype?.customPropertySets!">
    </app-issue-custom-form-property>
  </ng-container>
</form>
