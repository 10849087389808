<ion-card>
  <ion-card-header>
    <ion-card-title class="ion-text-uppercase">
      {{ 'LOGIN.LABELS.LINK.TITLE' | translate }}
    </ion-card-title>
    <ion-card-subtitle>
      {{ 'LOGIN.LABELS.LINK.SUBTITLE' | translate }}
    </ion-card-subtitle>
  </ion-card-header>
  <ion-card-content>
    {{ 'LOGIN.LABELS.LINK.CONTEXT' | translate }}
  </ion-card-content>
</ion-card>
