/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ResourceTaskScheduleType {
    Base = '0',
    Relative = 1,
    Fixed = 2,
    Consumption = 3,
    Error = 5,
}
