import {formatDate} from '@angular/common';
import {Inject, Injectable, LOCALE_ID} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class DateHelperService {
  constructor(
    @Inject(LOCALE_ID) public locale: string
  ) {
  }

  convertMinutesToISO(minutes: number): string | undefined {
    const milliseconds = minutes * 60 * 1000;
    if (milliseconds) {
      const date: Date = new Date(milliseconds);

      return formatDate(date, "yyyy-MM-ddTHH:mm:ss", this.locale);
    }
    return undefined;
  }

  convertISOToMinutes(isoString: string): number {
    const date = new Date(isoString);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    return hours * 60 + minutes;
  }
}
