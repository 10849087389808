import {Injectable} from "@angular/core";
import {IssuesBaseService} from "@shared/components/issues/services/issues-base.service";
import {
  IssueType,
  ResourceProfileDto,
  SubmitWinterhalterServiceCallIssueDto,
  WinterhalterServiceCallIssueDto, WinterhalterServiceCallIssuePreviewDto,
} from "@server-models";
import {IssueFormattedDetailData} from "@shared/components/issues/interfaces/issue-formatted-detail-data.interface";
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root',
})
export class IssuesBaseWinterhalterServiceCallService extends IssuesBaseService {
  override formatReadDetailData(issueDto: WinterhalterServiceCallIssueDto, issueType: IssueType): IssueFormattedDetailData {
    const baseResult = <IssueFormattedDetailData>{
      issueId: issueDto.issueId,
      headerTitle: issueDto.title,
      issueType: issueType
    }

    return {
      ...baseResult,
      ...this.getAdditionalFormattedData(issueDto)
    };
  }

  override prepareFormIssueDto(form: FormGroup, templatePreviewDto: WinterhalterServiceCallIssuePreviewDto, resourceDto?: ResourceProfileDto, tenantId?: number): SubmitWinterhalterServiceCallIssueDto {
    const formValues = form.value;
    return {
      title: templatePreviewDto.name,
      tenantId,
      problem: formValues.problem,
      category: formValues.category,
      message: formValues.message,
      contactFirstName: formValues.contactFirstName,
      contactLastName: formValues.contactLastName,
      resource: resourceDto
    };
  }

  override getAdditionalFormattedData(issueDto: WinterhalterServiceCallIssueDto): Partial<IssueFormattedDetailData> {
    return {
      winterhalter: {
        serviceCall: {
          resource: issueDto.resource,
          orderId: issueDto.orderId,
          formData: {
            category: issueDto.category,
            problem: issueDto.problem,
            message: issueDto.message,
            contactFirstName: issueDto.contactFirstName,
            contactLastName: issueDto.contactLastName,
            contactEmailAddress: issueDto.contactEmailAddress
          }
        }
      }
    };
  }
}
