/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DataTableFilterType {
    Base = '0',
    ByCustomProperty = 1,
    ByTransfer = 2,
    ByGroup = 3,
}
