import {createFeatureSelector, createSelector} from "@ngrx/store";
import {AttachmentsState} from "@shared/components/attachments/store/attachments.state";

export const selectAttachmentsStore = createFeatureSelector<AttachmentsState>('attachments');

export const selectAttachments = createSelector(
  selectAttachmentsStore,
  (state: AttachmentsState) => state
);

export const selectAttachmentsIsLoading = createSelector(
  selectAttachmentsStore,
  (state: AttachmentsState) => state.isLoading
);

export const selectAttachmentsIsLoadingId = createSelector(
  selectAttachmentsStore,
  (state: AttachmentsState) => state.isLoadingId
);
