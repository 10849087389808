/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CategorizedType {
    Info = 1,
    KnowledgeArticle = 2,
    Task = 4,
    KnowledgePath = 8,
}
