import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {
  Clients, GeneralSettingDto,
  LoginByQuickAccessDto,
  RefreshTokenV3Dto, ResourceProfileOverviewDto, SettingType,
  TokenDto
} from "@server-models";
import {LinkErrorHandlerLoginService} from "@link/services/error-handler-base/error-handler-login.service";
import {LinkHttpClientBaseApiService} from "@link/services/link-http-client-base-api/link-http-client-base-api.service";

@Injectable({
  providedIn: 'root'
})
export class LinkLoginApiService extends LinkHttpClientBaseApiService {

  loginByResource(resourceId: number, key: string): Observable<TokenDto> {
    const body: LoginByQuickAccessDto = {
      resourceId: resourceId,
      key: key
    }

    const options = {
      errorHandler: new LinkErrorHandlerLoginService(this._alertControllerService, this._translationService, this._router)
    }

    return super.post<TokenDto>(`/identity/login/LoginQuickAccess`, false, body, options);
  }

  /**
   * @name getSettings
   * @description
   * request the global settings by tenant id
   * @memberof LinkLoginApiService
   * @param tenantId
   * @returns {Observable<GeneralSettingDto>}
   */
  getSettings(tenantId: number): Observable<GeneralSettingDto> {
    // SettingType.General after settings is for general settings, is hardcode for now
    return super.get(`/orga/tenant/${tenantId}/setting/${SettingType.General}`, undefined);
  }

  /**
   * @name loginRefresh
   * @description
   * make a refresh login using refreshToken and tenantId
   * @memberof LinkLoginApiService
   * @param refreshToken
   * @param tenantId
   * @return {Observable<TokenDto>}
   */
  loginRefresh(refreshToken: string, tenantId: number ): Observable<TokenDto> {
    const body: RefreshTokenV3Dto = {
      meta: {
        type: 1
      },
      refreshToken,
      tenantId,
      client: Clients.Link
    }
    const options = {
      errorHandler: new LinkErrorHandlerLoginService(this._alertControllerService, this._translationService, this._router)
    }
    return super.post<TokenDto>(`/identity/v3/login/refresh`, false, body, options);
  }

  getProfile(resourceId: number): Observable<ResourceProfileOverviewDto> {
    return super.get<ResourceProfileOverviewDto>(`/tech/resource/${resourceId}/profile`);
  }
}
