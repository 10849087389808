/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum Owners {
    Unspecified = '0',
    Location = 1,
    Resource = 2,
    Contact = 3,
}
