/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ProductEditions {
    OrgaClassicModule = 2,
    TechClassicModule = 11,
    OrgaModule = 12,
    InspectionClassicAddon = 13,
    MissionClassicAddon = 14,
    ConnectedClassicAddon = 15,
    SafeClassicAddon = 16,
    QuickAccessClassicAddon = 17,
    TechModule = 18,
    DataClassicModule = 20,
    ClassificationClassicAddon = 21,
    SyncLocationClassicAddon = 22,
    DataModule = 23,
    InspectionAddon = 24,
    MissionAddon = 25,
    ConnectedAddon = 26,
    SafeAddon = 27,
    QuickAccessAddon = 28,
    ClassificationAddon = 29,
    SyncLocationAddon = 31,
    OrgaTechClassicModule = 32,
    OrgaTechModule = 33,
    SyncReceiptsClassicAddon = 34,
    SyncContactsClassicAddon = 35,
    SyncDriveClassicAddon = 36,
    SyncReceiptsAddon = 37,
    SyncContactsAddon = 38,
    SyncDriveAddon = 39,
    GdprClassicAddon = 40,
    GdprAddon = 41,
    NotificationClassicAddon = 42,
    NotificationAddon = 43,
    TeamModule = 44,
    MdmClassicModule = 45,
    PrimeReportAddon = 46,
    MaintenanceReportAddon = 47,
}
