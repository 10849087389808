import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {CustomPropertyCompleteDto, CustomPropertyType} from "@server-models";
import {InputTextComponent} from "@shared/components/custom-property/inputs/text/input-text.component";
import {InputNumericComponent} from "@shared/components/custom-property/inputs/number/input-numeric.component";
import {InputMultiTextComponent} from "@shared/components/custom-property/inputs/multi-text/input-multi-text.component";
import {InputEmailComponent} from "@shared/components/custom-property/inputs/email/input-email.component";
import {InputPhoneComponent} from "@shared/components/custom-property/inputs/phone/input-phone.component";
import {
  InputTimeOfDayComponent
} from "@shared/components/custom-property/inputs/time-of-day/input-time-of-day.component";
import {InputDateComponent} from "@shared/components/custom-property/inputs/date/input-date.component";
import {InputInfoComponent} from "@shared/components/custom-property/inputs/info/input-info.component";
import {InputDropdownComponent} from "@shared/components/custom-property/inputs/dropdown/input-dropdown.component";
import {InputCheckboxComponent} from "@shared/components/custom-property/inputs/checkbox/input-checkbox.component";
import {InputPictureComponent} from "@shared/components/custom-property/inputs/picture/input-picture.component";
import {InputSignatureComponent} from "@shared/components/custom-property/inputs/signature/input-signature.component";

@Component({
  selector: 'app-custom-property',
  templateUrl: './custom-property.component.html',
  styleUrls: ['./custom-property.component.scss'],
  standalone: true,
  imports: [
    IonicModule, CommonModule, FormsModule,
    ReactiveFormsModule, TranslateModule,
    InputTextComponent, InputNumericComponent,
    InputMultiTextComponent, InputEmailComponent,
    InputPhoneComponent, InputTimeOfDayComponent,
    InputDateComponent, InputInfoComponent,
    InputDropdownComponent, InputCheckboxComponent,
    InputPictureComponent, InputSignatureComponent
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CustomPropertyComponent
    }
  ]
})
export class CustomPropertyComponent implements OnInit, ControlValueAccessor {

  @Input() inputProperty!: CustomPropertyCompleteDto;
  @Input() formControl: FormControl;
  @Input() index: number;

  protected readonly CustomPropertyType = CustomPropertyType;

  disabled: boolean;
  value: string = '';

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  constructor() {
    this.formControl = new FormControl();
    this.disabled = false;
    this.index = NaN;
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }


  ngOnInit() {
  }
}
